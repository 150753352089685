import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg className="xSign" width={10} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M6.548 5 8.87 2.677a.73.73 0 0 0 0-1.032l-.516-.516a.73.73 0 0 0-1.032 0L5 3.45 2.678 1.13a.73.73 0 0 0-1.032 0l-.516.516a.73.73 0 0 0 0 1.032l2.322 2.321L1.13 7.321a.73.73 0 0 0 0 1.032l.516.516a.73.73 0 0 0 1.032 0L5 6.547 7.322 8.87a.73.73 0 0 0 1.032 0l.516-.516a.73.73 0 0 0 0-1.032L6.548 4.999Z"
      fill="#000"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const XSign = memo(ForwardRef);
export default XSign;
