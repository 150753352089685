import CenterSections from "../../../components/centerSections";
import usePlatform from "../../../hooks/usePlatform";
import {
  partnersDesktop,
  partnersMobile,
} from "../../../assets/images/equipament/EquipmentThird";
import "./EquipmentThird.scss";
import { useTranslation } from "react-i18next";

const EquipmentThird = () => {
  const { isMobile } = usePlatform();
  const { t } = useTranslation();
  return (
    <CenterSections customClass="equipmentThirdCenterSections">
      <div className="EquipmentThird">
        <div className="titlePartners">
          {t("equipament:thirdSection:title")}
        </div>
        <div className="subtitlePartners">
          {t("equipament:thirdSection:subtitle")}
        </div>
        <div className="logosContainer">
          <img
            src={isMobile ? partnersMobile : partnersDesktop}
            alt="Partners"
          />
        </div>
        <div className="backgroundImage" />
      </div>
    </CenterSections>
  );
};

export default EquipmentThird;
