import { SVGProps, Ref, forwardRef, memo } from "react"

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6 11.357A5.357 5.357 0 1 0 6 .643a5.357 5.357 0 0 0 0 10.714Z"
      fill="#fff"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m3.857 6.535 1.558 1.608C6.15 6.036 6.76 5.112 8.143 3.857"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
const EncircledCheckmark = memo(ForwardRef)
export default EncircledCheckmark