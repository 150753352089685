import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  desktopImage1,
  desktopImage1En,
  desktopImage2,
  desktopImage2En,
  desktopImage3,
  desktopImage3En,
  desktopImage4,
  desktopImage4En,
  desktopImage5,
  desktopImage5En,
  desktopImage6,
  desktopImage6En,
  desktopImage7,
  desktopImage7En,
  mobileImage1,
  mobileImage1En,
  mobileImage2,
  mobileImage2En,
  mobileImage3,
  mobileImage3En,
  mobileImage4,
  mobileImage4En,
  mobileImage5,
  mobileImage5En,
  mobileImage6,
  mobileImage6En,
  mobileImage7,
  mobileImage7En,
} from "../../../assets/images/equipament/EquipmentFirst";
import { CurvyArrowUnderlineOrange } from "../../../assets/images/home/general";
import CenterSections from "../../../components/centerSections";
import usePlatform from "../../../hooks/usePlatform";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";
import "./EquipmentFirst.scss";

const EquipmentFirst = () => {
  const { isMobile } = usePlatform();
  const [currentAnimationStep, setCurrentAnimationStep] = useState(0);
  const myLanguage = getDefaultLanguage();
  const { t } = useTranslation();
  const updateAnimationStep = () => {
    currentAnimationStep === 0 && setCurrentAnimationStep(1);
    currentAnimationStep === 1 && setCurrentAnimationStep(2);
    currentAnimationStep === 2 && setCurrentAnimationStep(3);
    currentAnimationStep === 3 && setCurrentAnimationStep(4);
    currentAnimationStep === 4 && setCurrentAnimationStep(5);
    currentAnimationStep === 5 && setCurrentAnimationStep(6);
  };

  const desktopImages = [
    desktopImage1,
    desktopImage2,
    desktopImage3,
    desktopImage4,
    desktopImage5,
    desktopImage6,
    desktopImage7,
  ];
  const desktopImagesEn = [
    desktopImage1En,
    desktopImage2En,
    desktopImage3En,
    desktopImage4En,
    desktopImage5En,
    desktopImage6En,
    desktopImage7En,
  ];
  const mobileImages = [
    mobileImage1,
    mobileImage2,
    mobileImage3,
    mobileImage4,
    mobileImage5,
    mobileImage6,
    mobileImage7,
  ];
  const mobileImagesEn = [
    mobileImage1En,
    mobileImage2En,
    mobileImage3En,
    mobileImage4En,
    mobileImage5En,
    mobileImage6En,
    mobileImage7En,
  ];
  useEffect(() => {
    const animationInterval = setInterval(updateAnimationStep, 1000);
    return () => clearInterval(animationInterval);
  });

  return (
    <CenterSections>
      <div className="equipmentFirst">
        <h1 className="componentTitle">
          {t("equipament:firstSection:blackTitle")}
          <strong>
            {t("equipament:firstSection:orangeTitle")}
            <img
              className={`curvyArrowUnderline ${
                myLanguage === "en-US" ? "extraSpace" : ""
              }`}
              src={CurvyArrowUnderlineOrange}
              alt="Subrayado flecha curva"
            />
          </strong>
        </h1>
        <div className="imageContainer">
          <img
            className="remoteWorkSetup"
            src={
              isMobile
                ? myLanguage === "en-US"
                  ? mobileImagesEn[currentAnimationStep]
                  : mobileImages[currentAnimationStep]
                : myLanguage === "en-US"
                ? desktopImagesEn[currentAnimationStep]
                : desktopImages[currentAnimationStep]
            }
            alt="Puesto de trabajo remoto"
          />
        </div>
      </div>
    </CenterSections>
  );
};

export default EquipmentFirst;
