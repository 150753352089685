import { Trans, useTranslation } from "react-i18next";
import CenterSections from "../../../components/centerSections";
import {
  doorIcon,
  laptopIcon,
  downwardRightArrowIcon,
  nodiIcon,
  blueBackgroundImage,
  desktopConnectorBackgroundImage,
  mobileConnectorBackgroundImage,
  curvyUnderlinedArrowHome2,
} from "../../../assets/images/home/HomeSecond";
import "./HomeSecond.scss";
import usePlatform from "../../../hooks/usePlatform";
import { CurvyArrowUnderlineOrange } from "../../../assets/images/home/general";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";

const HomeSecond = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = usePlatform();
  const appLanguage = getDefaultLanguage();
  const englishStyles = appLanguage === "en-US" ? "english" : "";

  const isTabletOrMobile = isMobile || isTablet;
  const underlineArrow = isTabletOrMobile
    ? curvyUnderlinedArrowHome2
    : CurvyArrowUnderlineOrange;

  const informationBoxes = [
    {
      key: "informationBox1",
      title: t("home:secondSection:informationBoxes:box1:title"),
      text: t("home:secondSection:informationBoxes:box1:text"),
      icon: doorIcon,
      altText: t("home:secondSection:informationBoxes:box1:iconAltText"),
    },
    {
      key: "informationBox2",
      title: t("home:secondSection:informationBoxes:box2:title"),
      text: t("home:secondSection:informationBoxes:box2:text"),
      icon: laptopIcon,
      altText: t("home:secondSection:informationBoxes:box2:iconAltText"),
    },
    {
      key: "informationBox3",
      title: t("home:secondSection:informationBoxes:box3:title"),
      text: t("home:secondSection:informationBoxes:box3:text"),
      icon: downwardRightArrowIcon,
      altText: t("home:secondSection:informationBoxes:box3:iconAltText"),
    },
  ];

  const informationBoxesConnerctorImage = isTabletOrMobile
    ? mobileConnectorBackgroundImage
    : desktopConnectorBackgroundImage;

  return (
    <>
      <CenterSections customClass="homeSecondCenterSections">
        <div className="homeSecond">
          <h1 className="sectionTitle">
            {t("home:secondSection:sectionTitle")}
          </h1>
          <div className="sectionSubtitle">
            <Trans
              i18nKey={t("home:secondSection:sectionSubtitle")}
              components={{ 1: <strong className="orangeText" /> }}
            />
          </div>
          <div className="informationBoxes">
            <img
              className="connectorBackgroundImage"
              src={informationBoxesConnerctorImage}
              alt={t("home:secondSection:connectorBackgroundImageAltText")}
            />
            {informationBoxes.map((box) => {
              return (
                <div key={box.key} className={`informationBox ${`${box.key}`}`}>
                  <img className="icon" src={box.icon} alt={box.altText} />
                  <div className="title">{box.title}</div>
                  <div className="text">{box.text}</div>
                </div>
              );
            })}
          </div>
          <div className="nudosIsTheAnswer">
            <div className={`title ${englishStyles}`}>
              <Trans
                i18nKey={"home:secondSection:nudosIsTheAnswer:title"}
                components={{
                  1: <strong className="orangeText" />,
                }}
              />
              <img
                className="curvyArrowUnderline"
                src={underlineArrow}
                alt="Subrayado flecha curva"
              />
            </div>
            <div className="subtitle">
              <img
                className="backgroundImage"
                src={blueBackgroundImage}
                alt={t(
                  "home:secondSection:nudosIsTheAnswer:backgroundImageAltText"
                )}
              />
              <img
                className="nodiIcon"
                src={nodiIcon}
                alt={t("home:secondSection:nudosIsTheAnswer:nodiIconAltText")}
              />
              <div className="text">
                {t("home:secondSection:nudosIsTheAnswer:subtitle")}
              </div>
            </div>
          </div>
        </div>
      </CenterSections>
    </>
  );
};
export default HomeSecond;
