import { useTranslation } from "react-i18next";
import { iMacIcon } from "../../../assets/images/home/HomeEight";
import CenterSections from "../../../components/centerSections";
import { ICountryDetail } from "../../../types/global.types";
import { DemoForm } from "./Components";
import "./HomeEight.scss";

const HomeEight = ({ countriesList }: { countriesList: ICountryDetail[] }) => {
  const { t } = useTranslation();
  return (
    <>
      <CenterSections customClass="homeEightCenterContainer">
        <section id="demo-form" className="homeEight">
          <section className="leftSection">
            <div className="titleContainer">
              {t("home:eightSection:title")}
              <img src={iMacIcon} alt="Icono iMac" className="titleIcon" />
            </div>
            <div className="subtitle">
              <p>{t("home:eightSection:subtitle")}</p>
            </div>
          </section>
          <section className="rightSection">
            <DemoForm countriesList={countriesList} />
          </section>
        </section>
      </CenterSections>
    </>
  );
};

export default HomeEight;
