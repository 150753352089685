import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={10}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={5} cy={5} r={5} fill="#F27058" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const OrangeDot = memo(ForwardRef);
export default OrangeDot;
