import create from "zustand";

const useGlobalState = create<iGlobalState>((set) => ({
  modalOptions: undefined,
  setModalOptions: (modalOptions: ImodalOptions | undefined) =>
    set({ modalOptions }),
  showModal: false,
  setShowModal: (showModal: boolean) => set({ showModal }),
}));

export interface iGlobalState {
  modalOptions?: ImodalOptions;
  setModalOptions: (modalOptions: ImodalOptions | undefined) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export interface ImodalOptions {
  modalContent: JSX.Element;
  isModalOpen: boolean;
  setIsModalOpen: (showModal: boolean) => void;
  closesOnClickOutside?: boolean;
  closeModalCallback?: () => void;
  clickOutsideCallback?: () => void;
  shouldCloseModal?: boolean;
}

export default useGlobalState;
