import { useTranslation } from "react-i18next";
import { NudosLogo } from "../../../assets/images/blog";
import {
  blueLatamMap,
  completeBlueLatamMap,
  locationIcon,
  mdmIcon,
  platformIcon,
  supportIcon,
} from "../../../assets/images/NodiPlatformView/NodiPlatformThird";
import CenterSections from "../../../components/centerSections";
import usePlatform from "../../../hooks/usePlatform";
import "./NodiPlatformThird.scss";

const NodiPlatformThird = () => {
  const { t } = useTranslation();
  const { isMobile } = usePlatform();
  const advantagesContent = [
    {
      title: t("nodiPlatform:nodiPlatformThird:advantagesContent:1:title"),
      text: t("nodiPlatform:nodiPlatformThird:advantagesContent:1:text"),
      icon: locationIcon,
      altText: "Ícono localización",
    },
    {
      title: t("nodiPlatform:nodiPlatformThird:advantagesContent:2:title"),
      text: t("nodiPlatform:nodiPlatformThird:advantagesContent:2:text"),
      icon: platformIcon,
      altText: "Ícono plataforma",
    },
    {
      title: t("nodiPlatform:nodiPlatformThird:advantagesContent:3:title"),
      text: t("nodiPlatform:nodiPlatformThird:advantagesContent:3:text"),
      icon: mdmIcon,
      altText: "Ícono línea de comando",
    },
    {
      title: t("nodiPlatform:nodiPlatformThird:advantagesContent:4:title"),
      text: t("nodiPlatform:nodiPlatformThird:advantagesContent:4:text"),
      icon: supportIcon,
      altText: "Ícono asesor",
    },
  ];
  return (
    <CenterSections customClass="nodiPlatformThirdCenterSections">
      <img
        className="blueLatamMap"
        src={isMobile ? completeBlueLatamMap : blueLatamMap}
        alt="Mapa de Latam"
      />
      <div className="nodiPlatformThird">
        <h1 className="title">
          {t("nodiPlatform:nodiPlatformThird:title")} <strong>Nudos</strong>{" "}
          <NudosLogo className="nudosLogo" />
        </h1>
        <div className="boxesContainer">
          {advantagesContent.map((advantage, i) => {
            return (
              <div
                key={`advantage${i}`}
                className={`advantageBox advantageBox${i + 1}`}
              >
                <div className="upperContent leftContent">
                  <img
                    className="icon"
                    alt={advantage.altText}
                    src={advantage.icon}
                  />
                </div>
                <div className="bottomContent rightContent">
                  <div className="boxTitle">{advantage.title}</div>
                  <div className="boxText">{advantage.text}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </CenterSections>
  );
};

export default NodiPlatformThird;
