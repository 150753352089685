import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useGlobalState from "../../state/global.state";
import PromotionModal from "../PromotionModal/PromotionModal";
import "./FloatingPromotionButton.scss";

const FloatingPromotionButton = () => {
  const { setModalOptions, setShowModal } = useGlobalState();
  const delay = 12;
  const _promotionWasShown = sessionStorage.getItem("promotionWasShown");
  const promotionWasShown =
    _promotionWasShown && JSON.parse(_promotionWasShown);
  const { t } = useTranslation();
  const handleClick = () => {
    setModalOptions({
      modalContent: <PromotionModal />,
      isModalOpen: true,
      setIsModalOpen: setShowModal,
      closesOnClickOutside: true,
    });
    setShowModal(true);
  };

  useEffect(() => {
    if (promotionWasShown) return;
    const timer = setTimeout(() => {
      handleClick();
      sessionStorage.setItem("promotionWasShown", JSON.stringify(true));
    }, delay * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="floatingPromotionButton" onClick={handleClick}>
      {t("floatingPromotionButton:text")}
    </div>
  );
};

export default FloatingPromotionButton;
