import * as React from "react";
import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={26}
    height={15}
    fill="none"
    viewBox="0 0 26 15"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.9412 6.22614C10.9412 5.52466 10.8119 4.83203 10.5606 4.18396C10.3094 3.53588 9.94113 2.94702 9.47689 2.451C9.01265 1.95499 8.46152 1.56152 7.85497 1.29308C7.24841 1.02464 6.59831 0.886475 5.94178 0.886475C5.28525 0.886475 4.63515 1.02464 4.02859 1.29308C3.42204 1.56153 2.87091 1.95499 2.40667 2.451C1.94244 2.94702 1.57418 3.53588 1.32294 4.18396C1.0717 4.83203 0.942383 5.52466 0.942383 6.22614L3.44208 6.22614C3.44208 5.8754 3.50674 5.53007 3.63236 5.20603C3.75798 4.882 3.94211 4.58757 4.17423 4.33956C4.40635 4.09155 4.68191 3.89482 4.98519 3.7606C5.28847 3.62638 5.61352 3.55729 5.94178 3.55729C6.27005 3.55729 6.5951 3.62638 6.89838 3.7606C7.20165 3.89482 7.47722 4.09155 7.70934 4.33956C7.94146 4.58757 8.12558 4.882 8.2512 5.20603C8.37682 5.53007 8.44148 5.8754 8.44148 6.22614H10.9412Z"
      fill="#F27058"
    />
    <path
      d="M8.44749 8.73627C8.44749 9.43774 8.5768 10.1304 8.82805 10.7784C9.07929 11.4265 9.44754 12.0154 9.91178 12.5114C10.376 13.0074 10.9271 13.4009 11.5337 13.6693C12.1403 13.9378 12.7904 14.0759 13.4469 14.0759C14.1034 14.0759 14.7535 13.9378 15.3601 13.6693C15.9666 13.4009 16.5178 13.0074 16.982 12.5114C17.4462 12.0154 17.8145 11.4265 18.0657 10.7784C18.317 10.1304 18.4463 9.43774 18.4463 8.73627L15.9466 8.73627C15.9466 9.087 15.8819 9.43233 15.7563 9.75637C15.6307 10.0804 15.4466 10.3748 15.2144 10.6228C14.9823 10.8709 14.7068 11.0676 14.4035 11.2018C14.1002 11.336 13.7752 11.4051 13.4469 11.4051C13.1186 11.4051 12.7936 11.336 12.4903 11.2018C12.187 11.0676 11.9115 10.8709 11.6793 10.6228C11.4472 10.3748 11.2631 10.0804 11.1375 9.75637C11.0118 9.43233 10.9472 9.087 10.9472 8.73627L8.44749 8.73627Z"
      fill="#F27058"
    />
    <path
      d="M25.9383 6.22589C25.9383 5.52442 25.8089 4.83179 25.5577 4.18371C25.3065 3.53563 24.9382 2.94678 24.474 2.45076C24.0097 1.95474 23.4586 1.56128 22.852 1.29284C22.2455 1.0244 21.5954 0.88623 20.9389 0.88623C20.2823 0.88623 19.6322 1.0244 19.0257 1.29284C18.4191 1.56128 17.868 1.95474 17.4037 2.45076C16.9395 2.94678 16.5713 3.53563 16.32 4.18371C16.0688 4.83179 15.9395 5.52442 15.9395 6.22589L18.4392 6.22589C18.4392 5.87516 18.5038 5.52983 18.6294 5.20579C18.7551 4.88175 18.9392 4.58732 19.1713 4.33931C19.4034 4.09131 19.679 3.89457 19.9823 3.76035C20.2855 3.62613 20.6106 3.55705 20.9389 3.55705C21.2671 3.55705 21.5922 3.62613 21.8954 3.76035C22.1987 3.89457 22.4743 4.09131 22.7064 4.33931C22.9385 4.58732 23.1227 4.88175 23.2483 5.20579C23.3739 5.52983 23.4386 5.87516 23.4386 6.22589H25.9383Z"
      fill="#F27058"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const LogoText = memo(ForwardRef);
export default LogoText;
