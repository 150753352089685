import { FC, useState } from "react";
import ArrowIcon from "../../assets/images/home/arrow";
import usePlatform from "../../hooks/usePlatform";
import messageIcon from "./../../assets/images/home/messageIcon.png";
import { registerEmail } from "../../services/services";
import closeIcon from "./../../assets/images/home/close.svg";
import { useTranslation } from "react-i18next";
import {
  displayErrorNotification,
  displaySuccessNotification,
} from "../../utils/displayNudosStandardNotifications";
interface IFloatButton {
  changeButton: () => void;
}

const FloatButton: FC<IFloatButton> = ({ changeButton }: IFloatButton) => {
  const { isMobile } = usePlatform();
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const hideButton = () => {
    changeButton();
  };

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const sendEmail = async () => {
    const isEmail = validateEmail(email);
    setLoading(true);
    if (email && isEmail) {
      try {
        await registerEmail(email);
        hideButton();
        setLoading(false);
        displaySuccessNotification({
          customJSXMessage: <>{t("floatButton:success")}</>,
        });
      } catch (error) {
        setLoading(false);
        displayErrorNotification({
          customJSXMessage: <>{t("floatButton:error")}</>,
        });
      }
    }
  };

  return (
    <div id="floatButton" style={{ top: isMobile ? "450px" : "" }}>
      <div className="containerButton">
        <div className="closeButton" onClick={hideButton}>
          <img alt="closeIcon" src={closeIcon} />
        </div>
        <div className="headerButton">
          <div className="iconButton">
            <img alt="message_icon" src={messageIcon} />
          </div>
          <div className="infoButton">{t("floatButton:message")}</div>
        </div>
        <div className="containerInput">
          <input
            placeholder="Email"
            className="inputEmail"
            onChange={(e) => {
              handleChangeEmail(e.target.value);
            }}
          />
          <div
            className="emailIcon"
            onClick={() => {
              if (!loading) {
                sendEmail();
              }
            }}
          >
            <ArrowIcon
              stroke={email && validateEmail(email) ? "#F27058" : ""}
              className="positionIcon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatButton;
