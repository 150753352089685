export const ecommercePageUrlByEnvironment = () => {
  if (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === "development" ||
    window.location.host.includes("dev.")
  )
    return `https://nodi.beta.nudos.co`;
  else return `https://nodi.nudos.co`;
};

export const landingPageUrlByEnvironment = () => {
  if (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === "development" ||
    window.location.host.includes("dev.")
  )
    return `https://landing.beta.nudos.co`;
  else return `https://nudos.co`;
};
