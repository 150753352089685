import { useEffect, useState } from "react";
import CenterSections from "../../../components/centerSections";
import usePlatform from "../../../hooks/usePlatform";
import SliderCompanies from "./components/SliderCompanies";
import firstBlur from "../../../assets/images/home/carouselImg/firstBlur.png";
import secondBlur from "../../../assets/images/home/carouselImg/secondBlur.png";
import LogoText from "../../../assets/images/home/logoText";
import { toDemoForm, toEcommerce } from "../../../utils/toEcommerce";
import {
  homeImage1,
  homeImage1English,
} from "../../../assets/images/home/HomeFirst";
import "./HomeFirst.scss";
import NudosButton from "../../../components/NudosComponents/NudosButton/NudosButton";
import { segmentEvent } from "../../../utils/segment";
import { Trans, useTranslation } from "react-i18next";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";

const HomeFirst = () => {
  const { isMobile } = usePlatform();
  const { t } = useTranslation();
  const appLanguage = getDefaultLanguage();
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const backgroundImage =
    appLanguage === "en-US" ? homeImage1English : homeImage1;

  const alternatingTitleWordOptions = [
    t("home:firstSection:titleWords:1"),
    t("home:firstSection:titleWords:2"),
    t("home:firstSection:titleWords:3"),
  ];

  const toSignUpAction = () => {
    toEcommerce("Landing_Nudos_SignUp_Click", "demo", { ClickOrigin: "/home" });
  };

  const requestDemoAction = () => {
    toDemoForm("Landing_Nodi_Demo_Click", { ClickOrigin: "/home" });
  };

  const mutateWord = () => {
    currentWordIndex === 0 && setCurrentWordIndex(1);
    currentWordIndex === 1 && setCurrentWordIndex(2);
    currentWordIndex === 2 && setCurrentWordIndex(0);
  };

  useEffect(() => {
    const mutateWordInterval = setInterval(mutateWord, 1200);
    return () => clearInterval(mutateWordInterval);
  });

  useEffect(() => {
    segmentEvent({ event: "Landing_Home_View" });
  }, []);

  return (
    <>
      <CenterSections customClass="homeFirstCenterSections">
        <div className="homeFirst">
          {isMobile && (
            <section className="topSection">
              <img src={backgroundImage} alt="Nodi" />
            </section>
          )}
          <section className="leftSection bottomSection">
            <div className="titleContainer">
              <h1 className="title1">
                {alternatingTitleWordOptions[currentWordIndex]}
              </h1>
              <h1 className="title2">{t("home:firstSection:employees")}</h1>
              <h1 className="title3">
                <Trans
                  i18nKey={"home:firstSection:latam"}
                  components={{ 1: <strong /> }}
                />
              </h1>
            </div>
            <div className="subtitleContainer">
              {isMobile && t("home:firstSection:mobileSubitle")}
              {!isMobile && (
                <>
                  <div className="subtitle1">
                    {t("home:firstSection:firstSubtitle")}
                  </div>
                  <div className="subtitle2">
                    {t("home:firstSection:secondSubtitle")}
                  </div>
                  <div className="subtitle3">
                    {t("home:firstSection:thirdSubtitle")}
                  </div>
                </>
              )}
            </div>
            <NudosButton
              buttonText={t("globalButtons:signUpButton")}
              componentSize="large"
              handleClick={toSignUpAction}
              colorPalette={"blackBorder"}
              customClassName="animatedBlackButton"
            />
            <NudosButton
              buttonText={t("globalButtons:DemoButton")}
              componentSize="large"
              handleClick={requestDemoAction}
              colorPalette={"blackContent"}
              customClassName="animatedBlackButton"
            />
          </section>
          {!isMobile && (
            <section className="rightSection">
              <img src={backgroundImage} alt="Nodi" />
            </section>
          )}
        </div>
      </CenterSections>
      <div id="dynamicBanners">
        <div className="titleCourusel">
          {t("home:firstSection:pymesLatAm")}
          <LogoText className="inline ml-2" />
        </div>
        <div className="generalCarousel">
          <div className="containerCarousel">
            <div className="contianerAbsolute">
              <div className="blurContainer">
                <img className="firstBlur" alt="blur" src={firstBlur} />
                <img
                  className="secondBlur"
                  alt="second-blur"
                  src={secondBlur}
                />
                <SliderCompanies />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeFirst;
