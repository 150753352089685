import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurvyArrowUnderlineOrange } from "../../../assets/images/home/general";
import {
  curvyUnderlinedArrowPlatform2,
  EncircledCheckmark,
  NodiSection2Image1,
  NodiSection2Image1En,
  NodiSection2Image2,
  NodiSection2Image2En,
  NodiSection2Image3,
  NodiSection2Image4,
  OrangeDot,
} from "../../../assets/images/NodiPlatformView/NodiPlatformSecond";
import CenterSections from "../../../components/centerSections";
import NudosButton from "../../../components/NudosComponents/NudosButton/NudosButton";
import usePlatform from "../../../hooks/usePlatform";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";
import { toDemoForm } from "../../../utils/toEcommerce";
import "./NodiPlatformSecond.scss";

const NodiPlatformSecond = () => {
  const { isMobile, isTablet } = usePlatform();
  const { t } = useTranslation();
  const [elementTop, setElementTop] = useState(800);
  const NodiPlatformSecondRef = useRef<HTMLDivElement>(null);
  const myLanguage = getDefaultLanguage();
  const getImageActiveStyle = useCallback(
    (imageNumber: number) => {
      if (isMobile && imageNumber === 1 && elementTop < 640) return "active";
      if (isMobile && imageNumber === 2 && elementTop < 260) return "active";
      if (isMobile && imageNumber === 3 && elementTop < -70) return "active";
      if (isMobile && imageNumber === 4 && elementTop < -400) return "active";
      if (!isMobile && imageNumber === 1 && elementTop < 520) return "active";
      if (!isMobile && imageNumber === 2 && elementTop < 220) return "active";
      if (!isMobile && imageNumber === 3 && elementTop < -140) return "active";
      if (!isMobile && imageNumber === 4 && elementTop < -460) return "active";
      return "";
    },
    [elementTop, isMobile]
  );

  const isTabletOrMobile = isMobile || isTablet;
  const underlineArrow = isTabletOrMobile
    ? curvyUnderlinedArrowPlatform2
    : CurvyArrowUnderlineOrange;

  const getElementTop = () => {
    const newElementTop =
      NodiPlatformSecondRef.current?.getBoundingClientRect().top;
    if (typeof newElementTop === "number" && newElementTop < elementTop)
      setElementTop(newElementTop);
  };

  const requestDemoAction = () => {
    toDemoForm("Landing_Nodi_Demo_Click", {
      ClickOrigin: "/nodi-platform?section=second",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", getElementTop);
    return () => window.removeEventListener("scroll", getElementTop);
  });

  return (
    <CenterSections>
      <div ref={NodiPlatformSecondRef} id="nodiPlatformSecond">
        {!isMobile && <div className="backgroundContainerDesktop" />}
        {isMobile && <div className="backgroundContainerMobile1" />}
        {isMobile && <div className="backgroundContainerMobile2" />}
        <h1 className="componentTitle">
          {t("nodiPlatform:nodiPlatformSecond:blackTitle")}
          <strong>
            {t("nodiPlatform:nodiPlatformSecond:orangeTitle")}
            <img
              className="curvyArrowUnderline"
              src={underlineArrow}
              alt="Subrayado flecha curva"
            />
          </strong>
        </h1>
        <section className="content-section">
          <section className="section1">
            <section className="left">
              <div className="nodiPlatformTextContainer">
                <div className="argument-container">
                  <span className="titleContainer">
                    <span className="checkmark">
                      <EncircledCheckmark />
                    </span>
                    <h2>
                      {t("nodiPlatform:nodiPlatformSecond:items:first:title")}
                    </h2>
                  </span>
                  <p>{t("nodiPlatform:nodiPlatformSecond:items:first:text")}</p>
                </div>
                <div className="argument-container">
                  <span className="titleContainer">
                    <span className="checkmark">
                      <EncircledCheckmark />
                    </span>
                    <h2>
                      {t("nodiPlatform:nodiPlatformSecond:items:second:title")}
                    </h2>
                  </span>
                  <p>
                    {t("nodiPlatform:nodiPlatformSecond:items:second:text")}{" "}
                  </p>
                </div>
              </div>
            </section>
            <section className="right">
              <div className={`imageContainer ${getImageActiveStyle(1)}`}>
                <img
                  src={
                    myLanguage === "en-US"
                      ? NodiSection2Image1En
                      : NodiSection2Image1
                  }
                  alt="Employees management on the Nodi tool"
                />
              </div>
              <div className="purple-dot-container">
                <OrangeDot />
              </div>
            </section>
          </section>
        </section>
        <section className="content-section">
          <section className="section2">
            <section className="left">
              <div className={`imageContainer ${getImageActiveStyle(2)}`}>
                <img
                  src={
                    myLanguage === "en-US"
                      ? NodiSection2Image2En
                      : NodiSection2Image2
                  }
                  alt="Tool assignation on the Nodi tool"
                />
              </div>
            </section>
            <section className="right">
              <div className="nodiPlatformTextContainer">
                <span className="titleContainer">
                  <span className="checkmark">
                    <EncircledCheckmark />
                  </span>
                  <h2>
                    {t("nodiPlatform:nodiPlatformSecond:items:third:title")}
                  </h2>
                </span>
                <p>{t("nodiPlatform:nodiPlatformSecond:items:third:text")}</p>
              </div>
            </section>
          </section>
        </section>
        <section className="content-section">
          <section className="section3">
            <section className="left">
              <div className="nodiPlatformTextContainer">
                <span className="titleContainer">
                  <span className="checkmark">
                    <EncircledCheckmark />
                  </span>
                  <h2>
                    {t("nodiPlatform:nodiPlatformSecond:items:fourth:title")}
                  </h2>
                </span>
                <p>{t("nodiPlatform:nodiPlatformSecond:items:fourth:text")}</p>
              </div>
            </section>
            <section className="right">
              <div className={`imageContainer ${getImageActiveStyle(3)}`}>
                <img
                  src={NodiSection2Image3}
                  alt="Finantial reports on the Nodi tool"
                />
              </div>
            </section>
          </section>
        </section>
        <section className="content-section">
          <section className="section4">
            <section className="left">
              <div className={`imageContainer ${getImageActiveStyle(4)}`}>
                <img
                  src={NodiSection2Image4}
                  alt="Inventory management on the Nodi tool"
                />
              </div>
            </section>
            <section className="right">
              <div className="nodiPlatformTextContainer">
                <span className="titleContainer">
                  <span className="checkmark">
                    <EncircledCheckmark />
                  </span>
                  <h2>
                    {t("nodiPlatform:nodiPlatformSecond:items:fifth:title")}
                  </h2>
                </span>
                <p>{t("nodiPlatform:nodiPlatformSecond:items:fifth:text")}</p>
                <div className="buttonContainer">
                  <NudosButton
                    customClassName="animatedOnHoverOrangeButton"
                    buttonText={t("nodiPlatform:nodiPlatformSecond:buttonText")}
                    componentSize="medium"
                    handleClick={requestDemoAction}
                  />
                </div>
              </div>
              <div className="purple-dot-container">
                <OrangeDot />
              </div>
            </section>
          </section>
        </section>
      </div>
    </CenterSections>
  );
};

export default NodiPlatformSecond;
