import { SVGProps, Ref, forwardRef, memo } from "react"

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={10}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.054 6.482 7.166.55a.77.77 0 0 1 1.067 0l.713.692a.718.718 0 0 1 0 1.035L4.105 7l4.843 4.726a.718.718 0 0 1-.002 1.035l-.712.692a.77.77 0 0 1-1.068 0l-6.11-5.934a.718.718 0 0 1 0-1.037Z"
      fill="#2C7152"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
const ReturnButton = memo(ForwardRef);
export default ReturnButton;

