const usePlatform = (): {
  isMobile: boolean;
  isTablet: boolean;
  isLessOrEqualThanCustomSize: (customSize: number) => boolean;
} => {
  const width = window.innerWidth;
  const isMobile = width < 768;
  const isTablet = width >= 768 && width < 1024;
  const isLessOrEqualThanCustomSize = (customSize?: number) => {
    return typeof customSize === "number" ? width <= customSize : false;
  };
  return { isMobile, isTablet, isLessOrEqualThanCustomSize };
};

export default usePlatform;
