import { useHistory } from "react-router-dom";
import BigNodiLogo from "../../assets/images/home/general/bigNodiLogo";
import warning from "../../assets/images/warning/warning.webp";
import { FloatingPromotionButton } from "../../components";
import { toEcommerce } from "../../utils/toEcommerce";
import "./warning.scss";

const WarninPage = () => {
  const { goBack } = useHistory();
  return (
    <div id="warningPage">
      <div className="headerContainer">
        <BigNodiLogo />
        <div className="textWarning">
          Estamos trabajando para que recibas la mejor experiencia de nuestra
          plataforma <strong>Nodi. </strong>
          <div className="mt-3">
            Si ya cuentas con una cuenta de Nudos, accede desde tu computadora
            para comenzar a equipar a tus colaboradores.
          </div>
        </div>
        <div className="containerLink">
          <div className="linkText">
            <div className="minText">Ingresa desde</div>
            <div
              className="superText"
              onClick={() => {
                toEcommerce();
              }}
            >
              somosnudos.com
            </div>
          </div>
        </div>
      </div>
      <div className="containerImg">
        <div className="boxImg">
          <img alt="mac" className="imgSize" src={warning} />{" "}
        </div>
      </div>
      <div className="containerButton">
        <button
          className="buttonWarning"
          onClick={() => {
            goBack();
          }}
        >
          Volver a Nudos
        </button>
      </div>
      <FloatingPromotionButton />
    </div>
  );
};

export default WarninPage;
