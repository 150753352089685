import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NudosPhoneInput,
  NudosTextInput,
} from "../../../../../components/NudosComponents";
import NudosButton from "../../../../../components/NudosComponents/NudosButton/NudosButton";
import { postDemoForm } from "../../../../../services/services";
import { ICountryDetail } from "../../../../../types/global.types";
import {
  isEmailValid,
  isPhoneValid,
} from "../../../../../utils/formValidations";
import { segmentEvent } from "../../../../../utils/segment";
import "./DemoForm.scss";
import {
  displayErrorNotification,
  displaySuccessNotification,
} from "../../../../../utils/displayNudosStandardNotifications";

const DemoForm = ({ countriesList }: { countriesList: ICountryDetail[] }) => {
  const { t } = useTranslation();
  const [organizationName, setOrganizationName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [phoneCountryData, setPhoneCountryData] = useState<ICountryDetail>();
  const [loading, setLoading] = useState(false);

  const validPhone = isPhoneValid(phone || "");
  const validEmail = isEmailValid(email || "");

  const isButtonDisabled =
    !email ||
    !phone ||
    !organizationName ||
    !validEmail ||
    !validPhone ||
    loading ||
    !phoneCountryData;

  const sendDemoForm = async () => {
    if (!(organizationName && email && phone && phoneCountryData?.phoneCode))
      return;
    setLoading(true);
    const body = {
      organizationName,
      countryCodePhone: `${phoneCountryData?.phoneCode}`,
      email,
      phone: phone,
    };
    try {
      segmentEvent({
        event: "Landing_Home_Demo_Request",
        data: {
          CompanyName: organizationName,
          ContactMail: email,
          ContactNumber: phone,
        },
      });
      const response = await postDemoForm(body);
      if (response) {
        setOrganizationName(undefined);
        setEmail(undefined);
        setPhone(undefined);
        setPhoneCountryData(undefined);
        displaySuccessNotification({
          customJSXMessage: <>{t("demoForm:successfulRequest")}</>,
        });
      }
    } catch (error: any) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="demoForm">
      <NudosTextInput
        customClassName="demoFormField"
        label={t("demoForm:formItems:organizationName:label")}
        placeholder={
          organizationName ||
          t("demoForm:formItems:organizationName:placeholder")
        }
        defaultValue={organizationName}
        isFilled={!!organizationName}
        handleChange={(inputText) => setOrganizationName(inputText)}
      />
      <NudosTextInput
        customClassName="demoFormField"
        label={t("demoForm:formItems:email:label")}
        defaultValue={email}
        placeholder={email || t("demoForm:formItems:email:placeholder")}
        isFilled={!!email}
        handleChange={(inputText) => setEmail(inputText)}
        errorText={
          email && !validEmail
            ? t("demoForm:formItems:email:invalidEmailError")
            : undefined
        }
      />
      <NudosPhoneInput
        personalClass={"demoPhoneField"}
        label={t("demoForm:formItems:phone:label")}
        defaultPhoneNumber={phone}
        defaultCountryData={phoneCountryData}
        placeholder={t("demoForm:formItems:phone:placeholder")}
        countriesList={countriesList}
        errorText={
          phone && phone.length > 2 && !isPhoneValid
            ? t("demoForm:formItems:phone:invalidPhoneError")
            : undefined
        }
        phoneCountryPlaceholder={t("globalButtons:countrySelectText")}
        handlePhoneChange={setPhone}
        handleCountryPhoneChange={setPhoneCountryData}
      />
      <NudosButton
        buttonText={t("demoForm:demoButton")}
        handleClick={sendDemoForm}
        isButtonDisabled={isButtonDisabled}
        isButtonLoading={loading}
      />
    </div>
  );
};

export default DemoForm;
