import { useEffect, useState } from "react";
import { getBlogListing } from "../../../services/blog";
import { noBlogPostsIcon, NudosLogo } from "../../../assets/images/blog";
import CenterSections from "../../../components/centerSections";
import { BlogListMainCard, BlogListSecondaryCard } from "../Components";
import { IblogListingEntryData } from "../../../types/blog";
import { FloatingPromotionButton } from "../../../components";
import BlogListingSkeleton from "../BlogListingSkeleton ";
import "./BlogListing.scss";
import { useTranslation } from "react-i18next";
import { segmentEvent } from "../../../utils/segment";
import { displayErrorNotification } from "../../../utils/displayNudosStandardNotifications";

const BlogListing = () => {
  const [loading, setLoading] = useState(false);
  const [mainEntry, setMainEntry] = useState<IblogListingEntryData>();
  const { t } = useTranslation();
  const [secondaryBlogEntries, setsecondaryBlogEntries] =
    useState<IblogListingEntryData[]>();

  const getBlogPosts = async () => {
    setLoading(true);
    try {
      const response = await getBlogListing();
      if (response && response.length > 0 && typeof response !== "string") {
        const mainPost = response[0];
        const secondaryPosts = response.length > 1 && response.slice(1);
        mainPost && setMainEntry(mainPost);
        secondaryPosts && setsecondaryBlogEntries(secondaryPosts);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlogPosts();
    segmentEvent({ event: "Landing_Blog_View" });
  }, []);

  return (
    <>
      <CenterSections customClass="blogListingCenterSections">
        <div className="blogListing">
          <div className="titleContainer">
            <div className="title">Blog Nudos</div>
            <div className="logoContainer">
              <NudosLogo />
            </div>
          </div>
          {loading && (
            <div className="skeletonListingContainer">
              <BlogListingSkeleton />
            </div>
          )}
          {!loading && mainEntry && (
            <div className="mainEntryContainer">
              <BlogListMainCard mainEntryListingData={mainEntry} />
            </div>
          )}
          {!loading &&
            secondaryBlogEntries &&
            secondaryBlogEntries.length > 0 && (
              <div className="blogListContainer">
                {secondaryBlogEntries.map((entry, i) => {
                  return (
                    <BlogListSecondaryCard
                      key={`post-${i}-${entry.postId}`}
                      blogEntryData={entry}
                    />
                  );
                })}
              </div>
            )}
          {!loading && !mainEntry && !secondaryBlogEntries && (
            <div className="noBlogEntries">
              <div className="icon">
                <img src={noBlogPostsIcon} alt="Icono blogs apilados" />
              </div>
              <div className="text">{t("blog:emptyBlogs")}</div>
            </div>
          )}
        </div>
      </CenterSections>
      <FloatingPromotionButton />
    </>
  );
};

export default BlogListing;
