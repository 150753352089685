export const getDefaultLanguage = () => {
  if (localStorage.getItem("language")) {
    const defaultLanguage = JSON.parse(
      localStorage.getItem("language") || "{}"
    );
    if (defaultLanguage?.languageCode) {
      return defaultLanguage.languageCode;
    }
    return "es-MX";
  }
  return "es-MX";
};
