import { useHistory } from "react-router-dom";
import { IblogListingEntryData } from "../../../../types/blog";
import { truncateText } from "../../../../utils/truncateText";
import "./BlogListSecondaryCard.scss";

const BlogListSecondaryCard = ({
  blogEntryData,
}: {
  blogEntryData: IblogListingEntryData;
}) => {
  const { push } = useHistory();
  const handleClickCard = () =>
    push(
      blogEntryData?.postId ? `/blog/entry/${blogEntryData?.postId}` : "/blog"
    );
  return (
    <div className="blogListSecondaryCard" onClick={handleClickCard}>
      <section
        className="topSection"
        style={{ backgroundImage: `url(${blogEntryData?.thumbnail})` }}
      />
      <section className="bottomSection">
        <div className="categoryAndTime">
          <div className="category">
            {truncateText(blogEntryData?.categoryName, 30)}
          </div>
          <div className="readingTime">
            {blogEntryData?.readingTime && "- "}
            {truncateText(blogEntryData?.readingTime, 30)}
          </div>
        </div>
        <div className="postTitle">
          {truncateText(blogEntryData?.title, 86)}
        </div>
        <div className="description">
          {truncateText(blogEntryData?.shortDescription, 110)}
        </div>
      </section>
    </div>
  );
};

export default BlogListSecondaryCard;
