export const formatShortDate = (value?: string) => {
  if (value) {
    const date = new Date(value);
    const day = date.toLocaleDateString('es-MX', { day: '2-digit' });
    const month = date.toLocaleDateString('es-MX', { month: '2-digit' });
    const year = date.toLocaleDateString('es-MX', { year: 'numeric' });

    return `${day}/${month}/${year}`;
  }
  return 'no Date';
};

export const formatDateToPicker = (value?: string) => {
  if (value) {
    const date = new Date(value);
    const day = date.toLocaleDateString('es-MX', { day: '2-digit' });
    const month = date.toLocaleDateString('es-MX', { month: '2-digit' });
    const year = date.toLocaleDateString('es-MX', { year: 'numeric' });

    return `${year}-${month}-${day}`;
  }
  return 'no Date';
};
