import * as React from "react";
import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M18.7462 18.7463H15.4861V13.641C15.4861 12.4235 15.4644 10.8569 13.7905 10.8569C12.0927 10.8569 11.8325 12.1828 11.8325 13.5532V18.7463H8.57352V8.24734H11.7035V9.68168H11.7468C12.3854 8.59101 13.5715 7.93944 14.8345 7.98605C18.139 7.98605 18.7473 10.1598 18.7473 12.9862L18.7462 18.7463ZM4.89497 6.81191C3.84984 6.81191 3.00312 5.96518 3.00312 4.92006C3.00312 3.87493 3.84984 3.0282 4.89497 3.0282C5.9401 3.0282 6.78683 3.87493 6.78683 4.92006C6.78683 5.96518 5.9401 6.81191 4.89497 6.81191ZM6.52446 18.7463H3.26115V8.24734H6.52446V18.7463ZM20.3713 0.00123225H1.62298C0.737227 -0.00852517 0.0108416 0.701598 0 1.58736V20.4127C0.0108416 21.2995 0.737227 22.0096 1.62298 21.9999H20.3713C21.2592 22.0107 21.9889 21.3006 22.0008 20.4127V1.58627C21.9878 0.698345 21.2582 -0.0117777 20.3713 0.000148037"
        fill="white"
      />
    </svg>
  );
};

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
