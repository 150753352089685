import create from "zustand";

export interface iState {
  existEmail?: boolean;
  setExistEmail: (email: boolean) => void;
  isExpanded?: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

const useSignStore = create<iState>((set) => ({
  existEmail: false,
  setExistEmail: (existEmail) => set({ existEmail }),
  isExpanded: true,
  setIsExpanded: (isExpanded) => set({ isExpanded }),
}));

export default useSignStore;
