import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g stroke="#2C7152">
      <path
        className="path1"
        d="M.823 8.801a2.684 2.684 0 0 0 2.36 2.366c1.895.211 3.74.211 5.635 0a2.684 2.684 0 0 0 2.36-2.366c.097-.911.18-1.847.18-2.801 0-.955-.083-1.89-.18-2.802A2.684 2.684 0 0 0 8.817.832a25.127 25.127 0 0 0-5.636 0A2.684 2.684 0 0 0 .823 3.198C.725 4.109.643 5.045.643 6c0 .954.082 1.89.18 2.801Z"
        fill="#fff"
      />
      <path
        className="path2"
        d="m7.515 4.484-3.03 3.03M7.515 7.515l-3.03-3.03"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <path className="path3" fill="#fff" d="M0 0h12v12H0z" />
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const DeleteSquareXButton = memo(ForwardRef);
export default DeleteSquareXButton;
