import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      width={42}
      height={42}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <g filter="url(#filter0_d_2789_92426)">
        <rect x="8" y="3" width="26" height="26" rx="2" fill="white" />
      </g>
      <path
        d="M17 20L25.4853 11.5147"
        stroke="#F27058"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 12L25.4853 20.4853"
        stroke="#F27058"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_2789_92426"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.6125 0 0 0 0 0.6125 0 0 0 0 0.6125 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2789_92426"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2789_92426"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

const ForwardRef = forwardRef(SvgComponent);
const CloseMenu = memo(ForwardRef);
export default CloseMenu;
