const truncateText = (text?: string | null, limit?: number | null) => {
  if (!text || !limit) return "";
  if (text.length > limit) {
    return `${text.slice(0, limit - 2)}...`;
  } else return text;
};

const isTruncated = (text?: string | null, limit?: number | null) => {
  if (!text || !limit) return false;
  if (text.length > limit) return true;
  return false;
};

export { truncateText, isTruncated };
