import { Trans, useTranslation } from "react-i18next";
import CenterSections from "../../../components/centerSections";
import "./HomeFourth.scss";
import {
  employeesIcon,
  truckIcon,
  laptopIcon,
  headsetIcon,
  approachingArrowsIcon,
  blueButtonIcon,
} from "../../../assets/images/home/HomeFourth";
import { toDemoForm } from "../../../utils/toEcommerce";

const HomeFourth = () => {
  const { t } = useTranslation();
  const requestDemoAction = () => {
    toDemoForm("Landing_Nodi_Demo_Click", {
      ClickOrigin: "/home?section=fourth",
    });
  };

  const informationBoxes = [
    {
      key: "informationBox1",
      title: t("home:fourthSection:box1:title"),
      text: t("home:fourthSection:box1:text"),
      icon: employeesIcon,
      iconAltText: t("home:fourthSection:box1:iconAltText"),
    },
    {
      key: "informationBox2",
      title: t("home:fourthSection:box2:title"),
      text: t("home:fourthSection:box2:text"),
      icon: truckIcon,
      iconAltText: t("home:fourthSection:box2:iconAltText"),
    },
    {
      key: "informationBox3",
      title: t("home:fourthSection:box3:title"),
      text: t("home:fourthSection:box3:text"),
      icon: laptopIcon,
      iconAltText: t("home:fourthSection:box3:iconAltText"),
    },
    {
      key: "informationBox4",
      title: t("home:fourthSection:box4:title"),
      text: t("home:fourthSection:box4:text"),
      icon: headsetIcon,
      iconAltText: t("home:fourthSection:box4:iconAltText"),
    },
    {
      key: "informationBox5",
      title: t("home:fourthSection:box5:title"),
      text: t("home:fourthSection:box5:text"),
      icon: approachingArrowsIcon,
      iconAltText: t("home:fourthSection:box5:iconAltText"),
    },
  ];
  return (
    <>
      <CenterSections customClass="homeFourthCenterSections">
        <div className="homeFourth">
          <h1 className="sectionTitle">
            <Trans
              i18nKey={"home:fourthSection:sectionTitle"}
              components={{ 1: <strong className="orangeText" /> }}
            />
          </h1>
          <div className="informationBoxesContainer">
            {informationBoxes.map((box) => {
              return (
                <div key={box.key} className={`informationBox ${box.key}`}>
                  <img
                    className="boxIcon"
                    src={box.icon}
                    alt={box.iconAltText}
                  />
                  <div className="boxTitle">{box.title}</div>
                  <div className="boxText">{box.text}</div>
                </div>
              );
            })}
          </div>
          <button className="blueButton" onClick={requestDemoAction}>
            {t("home:fourthSection:discoverNodiButtonText")}
            <img
              src={blueButtonIcon}
              alt={t("home:fourthSection:discoverNodiIconAltText")}
            />
          </button>
        </div>
      </CenterSections>
    </>
  );
};
export default HomeFourth;
