import * as React from "react";
import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={14}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0.255637 7.944H5.03164C5.21164 7.944 5.33164 7.836 5.35564 7.656L5.48764 6.624C5.49964 6.444 5.40364 6.336 5.22364 6.336H1.92364L2.11564 4.848H5.16364C5.34364 4.848 5.46364 4.74 5.48764 4.56L5.60764 3.528C5.63164 3.348 5.53564 3.24 5.35564 3.24H2.30764L2.48764 1.752H5.78764C5.96764 1.752 6.08764 1.644 6.11164 1.464L6.24364 0.432C6.26764 0.252 6.17164 0.144001 5.99164 0.144001H1.21564C1.03564 0.144001 0.915637 0.252 0.891637 0.432L0.00363684 7.656C-0.0203632 7.836 0.0756368 7.944 0.255637 7.944Z"
      fill={props?.fill || "white"}
    />
    <path
      d="M9.31723 8.088C11.1892 8.088 12.5692 7.236 12.7732 5.592C12.9772 3.948 11.0092 3.444 10.4812 3.24C9.92923 3.036 8.89723 2.844 8.96923 2.304C9.00523 1.968 9.30523 1.764 9.86923 1.764C10.6252 1.764 11.2492 2.124 11.5372 2.436C11.6452 2.556 11.7652 2.592 11.9332 2.484L12.9412 1.86C13.0972 1.764 13.1812 1.632 13.0972 1.476C12.7612 0.732001 11.6692 0 10.3012 0C8.74123 0 7.14523 0.66 6.92923 2.388C6.71323 4.14 8.68123 4.548 9.22123 4.752C9.74923 4.956 10.8412 5.16 10.7812 5.676C10.7452 6.024 10.5412 6.324 9.73723 6.324C8.86123 6.324 8.12923 5.76 7.81723 5.364C7.72123 5.22 7.58923 5.196 7.43323 5.304L6.37723 6C6.22123 6.096 6.14923 6.24 6.22123 6.384C6.58123 7.248 7.62523 8.088 9.31723 8.088Z"
      fill={props?.fill || "white"}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const EsIcon = memo(ForwardRef);
export default EsIcon;
