import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  const isMobile = props.version === "true";
  if (isMobile) {
    return (
      <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          d="M8.56463 4.83859C8.56463 4.20295 8.45387 3.57531 8.23866 2.98805C8.02345 2.40079 7.70802 1.86719 7.31037 1.41772C6.91272 0.968244 6.44064 0.611704 5.92109 0.368452C5.40153 0.1252 4.84468 -2.77851e-08 4.28231 0C3.71995 2.77851e-08 3.1631 0.1252 2.64354 0.368452C2.12399 0.611704 1.65191 0.968245 1.25426 1.41772C0.856611 1.86719 0.541178 2.40079 0.325972 2.98805C0.110765 3.57531 -4.91632e-08 4.20295 0 4.83859L2.14116 4.83859C2.14116 4.52077 2.19654 4.20785 2.30414 3.91422C2.41175 3.62059 2.56946 3.35379 2.76829 3.12905C2.96711 2.90431 3.20315 2.72604 3.46293 2.60442C3.72271 2.48279 4.00113 2.42019 4.28232 2.42019C4.5635 2.42019 4.84192 2.48279 5.1017 2.60442C5.36148 2.72604 5.59752 2.90431 5.79634 3.12905C5.99517 3.35379 6.15288 3.62059 6.26049 3.91422C6.36809 4.20785 6.42347 4.52077 6.42347 4.83859H8.56463Z"
          fill="#F27058"
        />
        <path
          d="M6.42853 7.11355C6.42853 7.7492 6.5393 8.37684 6.75451 8.9641C6.96971 9.55136 7.28514 10.085 7.68279 10.5344C8.08044 10.9839 8.55252 11.3404 9.07208 11.5837C9.59163 11.8269 10.1485 11.9521 10.7108 11.9521C11.2732 11.9521 11.8301 11.8269 12.3496 11.5837C12.8692 11.3404 13.3413 10.9839 13.7389 10.5344C14.1366 10.085 14.452 9.55136 14.6672 8.9641C14.8824 8.37684 14.9932 7.7492 14.9932 7.11355L12.852 7.11355C12.852 7.43138 12.7966 7.7443 12.689 8.03793C12.5814 8.33156 12.4237 8.59836 12.2249 8.8231C12.0261 9.04783 11.79 9.2261 11.5302 9.34773C11.2705 9.46936 10.992 9.53196 10.7108 9.53196C10.4297 9.53196 10.1512 9.46936 9.89146 9.34773C9.63169 9.2261 9.39565 9.04783 9.19682 8.8231C8.998 8.59836 8.84028 8.33156 8.73268 8.03793C8.62507 7.7443 8.56969 7.43138 8.56969 7.11355L6.42853 7.11355Z"
          fill="#F27058"
        />
        <path
          d="M21.4103 4.83859C21.4103 4.20295 21.2996 3.57531 21.0844 2.98805C20.8692 2.40079 20.5537 1.86719 20.1561 1.41772C19.7584 0.968244 19.2863 0.611704 18.7668 0.368452C18.2472 0.1252 17.6904 -2.77851e-08 17.128 0C16.5657 2.77851e-08 16.0088 0.1252 15.4892 0.368452C14.9697 0.611704 14.4976 0.968245 14.1 1.41772C13.7023 1.86719 13.3869 2.40079 13.1717 2.98805C12.9565 3.57531 12.8457 4.20295 12.8457 4.83859L14.9869 4.83859C14.9869 4.52077 15.0422 4.20785 15.1498 3.91422C15.2574 3.62059 15.4152 3.35379 15.614 3.12905C15.8128 2.90431 16.0489 2.72604 16.3086 2.60442C16.5684 2.48279 16.8468 2.42019 17.128 2.42019C17.4092 2.42019 17.6876 2.48279 17.9474 2.60442C18.2072 2.72604 18.4432 2.90431 18.642 3.12905C18.8409 3.35379 18.9986 3.62059 19.1062 3.91422C19.2138 4.20785 19.2692 4.52077 19.2692 4.83859H21.4103Z"
          fill="#F27058"
        />
        <path
          d="M42.115 7.82411C42.115 8.99772 41.726 9.98957 40.948 10.7997C40.18 11.5994 39.2324 11.9992 38.1053 11.9992C36.9882 11.9992 36.0407 11.5942 35.2627 10.7841C34.4847 9.97399 34.0957 8.98733 34.0957 7.82411V3.66455H36.1155L36.1304 7.82411C36.1304 8.39534 36.32 8.88347 36.699 9.28852C37.088 9.69358 37.5568 9.8961 38.1053 9.8961C38.6539 9.8961 39.1227 9.69358 39.5117 9.28852C39.9007 8.88347 40.0952 8.39534 40.0952 7.82411V3.66455H42.115V7.82411Z"
          fill="#F27058"
        />
        <path
          d="M24.7913 7.84044C24.7913 6.66683 25.1803 5.67498 25.9583 4.86488C26.7263 4.06516 27.6738 3.6653 28.8009 3.6653C29.918 3.6653 30.8656 4.07035 31.6436 4.88046C32.4216 5.69056 32.8105 6.67722 32.8105 7.84044L32.8105 12L30.7908 12L30.7758 7.84044C30.7758 7.26922 30.5863 6.78108 30.2073 6.37603C29.8183 5.97098 29.3495 5.76845 28.8009 5.76845C28.2523 5.76845 27.7835 5.97098 27.3945 6.37603C27.0055 6.78108 26.8111 7.26922 26.8111 7.84044L26.8111 12L24.7913 12L24.7913 7.84044Z"
          fill="#F27058"
        />
        <path
          d="M60.7185 7.82411C60.7185 8.98733 60.3288 9.974 59.5493 10.7841C58.7799 11.5942 57.8356 11.9992 56.7164 11.9992C55.5872 11.9992 54.6328 11.5994 53.8534 10.7997C53.0839 9.98957 52.6992 8.99772 52.6992 7.82411C52.6992 6.66089 53.0889 5.67942 53.8684 4.8797C54.6478 4.0696 55.5972 3.66455 56.7164 3.66455C57.8356 3.66455 58.7799 4.0696 59.5493 4.8797C60.3288 5.67942 60.7185 6.66089 60.7185 7.82411ZM58.6949 7.82411C58.6949 7.26327 58.5001 6.78033 58.1104 6.37527C57.7206 5.97022 57.256 5.7677 56.7164 5.7677C56.1667 5.7677 55.6971 5.97022 55.3074 6.37527C54.9176 6.76994 54.7228 7.25288 54.7228 7.82411C54.7228 8.39534 54.9176 8.88347 55.3074 9.28852C55.6971 9.69358 56.1667 9.8961 56.7164 9.8961C57.266 9.8961 57.7306 9.69358 58.1104 9.28852C58.5001 8.88347 58.6949 8.39534 58.6949 7.82411Z"
          fill="#F27058"
        />
        <path
          d="M67.6125 9.44105C67.6125 9.94603 67.4654 10.4037 67.1712 10.814C66.5827 11.6345 65.746 12.0291 64.661 11.9975C64.2288 11.987 63.7553 11.8765 63.2403 11.6661C62.7346 11.4557 62.3208 11.1927 61.999 10.8771L63.0335 9.34637C63.53 9.88291 64.0587 10.1512 64.6196 10.1512H64.661C64.8908 10.1512 65.0977 10.1038 65.2816 10.0091C65.5207 9.88291 65.6402 9.70406 65.6402 9.47261V9.40949C65.6126 9.16752 65.4701 8.99394 65.2127 8.88873C65.1115 8.86769 64.8954 8.82035 64.5644 8.74671C64.1506 8.65203 63.8012 8.5363 63.5162 8.39954C62.6794 7.98924 62.2611 7.2686 62.2611 6.23761C62.2611 5.24869 62.6886 4.50701 63.5438 4.01255C63.9208 3.79163 64.3299 3.6759 64.7713 3.66538C65.231 3.65486 65.7092 3.74428 66.2057 3.93365C66.7758 4.15458 67.1804 4.44389 67.4194 4.80158L66.1781 6.0798C65.8563 5.71159 65.5069 5.52748 65.1299 5.52748C64.5414 5.52748 64.2472 5.74841 64.2472 6.19026V6.22183C64.2472 6.43223 64.4863 6.61108 64.9644 6.75836C65.0012 6.76888 65.3046 6.83727 65.8747 6.96351C67.0332 7.216 67.6125 8.02606 67.6125 9.39371V9.44105Z"
          fill="#F27058"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.4172 0H49.3322V4.1517C48.7638 3.8269 48.1252 3.66451 47.4165 3.66451C46.2973 3.66451 45.348 4.06956 44.5686 4.87966C43.7891 5.67937 43.3994 6.66084 43.3994 7.82407C43.3994 8.99767 43.7841 9.98953 44.5536 10.7996C45.333 11.5993 46.2874 11.9992 47.4165 11.9992C48.5357 11.9992 49.4801 11.5942 50.2495 10.7841C51.029 9.97395 51.4187 8.98729 51.4187 7.82407C51.4187 7.78295 51.4182 7.74205 51.4172 7.70139V0ZM48.8106 6.37523C49.2003 6.78028 49.3951 7.26323 49.3951 7.82407C49.3951 8.39529 49.2003 8.88343 48.8106 9.28848C48.4308 9.69353 47.9662 9.89606 47.4165 9.89606C46.8669 9.89606 46.3973 9.69353 46.0076 9.28848C45.6178 8.88343 45.423 8.39529 45.423 7.82407C45.423 7.25284 45.6178 6.76989 46.0076 6.37523C46.3973 5.97018 46.8669 5.76765 47.4165 5.76765C47.9562 5.76765 48.4208 5.97018 48.8106 6.37523Z"
          fill="#F27058"
        />
      </svg>
    );
  }
  return (
    <svg
      width={70}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M8.56463 4.83859C8.56463 4.20295 8.45387 3.57531 8.23866 2.98805C8.02345 2.40079 7.70802 1.86719 7.31037 1.41772C6.91272 0.968244 6.44064 0.611704 5.92109 0.368452C5.40153 0.1252 4.84468 -2.77851e-08 4.28231 0C3.71995 2.77851e-08 3.1631 0.1252 2.64354 0.368452C2.12399 0.611704 1.65191 0.968245 1.25426 1.41772C0.856611 1.86719 0.541178 2.40079 0.325972 2.98805C0.110765 3.57531 -4.91632e-08 4.20295 0 4.83859L2.14116 4.83859C2.14116 4.52077 2.19654 4.20785 2.30414 3.91422C2.41175 3.62059 2.56946 3.35379 2.76829 3.12905C2.96711 2.90431 3.20315 2.72604 3.46293 2.60442C3.72271 2.48279 4.00113 2.42019 4.28232 2.42019C4.5635 2.42019 4.84192 2.48279 5.1017 2.60442C5.36148 2.72604 5.59752 2.90431 5.79634 3.12905C5.99517 3.35379 6.15288 3.62059 6.26049 3.91422C6.36809 4.20785 6.42347 4.52077 6.42347 4.83859H8.56463Z"
        fill="#F27058"
      />
      <path
        d="M6.42853 7.11355C6.42853 7.7492 6.5393 8.37684 6.75451 8.9641C6.96971 9.55136 7.28514 10.085 7.68279 10.5344C8.08044 10.9839 8.55252 11.3404 9.07208 11.5837C9.59163 11.8269 10.1485 11.9521 10.7108 11.9521C11.2732 11.9521 11.8301 11.8269 12.3496 11.5837C12.8692 11.3404 13.3413 10.9839 13.7389 10.5344C14.1366 10.085 14.452 9.55136 14.6672 8.9641C14.8824 8.37684 14.9932 7.7492 14.9932 7.11355L12.852 7.11355C12.852 7.43138 12.7966 7.7443 12.689 8.03793C12.5814 8.33156 12.4237 8.59836 12.2249 8.8231C12.0261 9.04783 11.79 9.2261 11.5302 9.34773C11.2705 9.46936 10.992 9.53196 10.7108 9.53196C10.4297 9.53196 10.1512 9.46936 9.89146 9.34773C9.63169 9.2261 9.39565 9.04783 9.19682 8.8231C8.998 8.59836 8.84028 8.33156 8.73268 8.03793C8.62507 7.7443 8.56969 7.43138 8.56969 7.11355L6.42853 7.11355Z"
        fill="#F27058"
      />
      <path
        d="M21.4103 4.83859C21.4103 4.20295 21.2996 3.57531 21.0844 2.98805C20.8692 2.40079 20.5537 1.86719 20.1561 1.41772C19.7584 0.968244 19.2863 0.611704 18.7668 0.368452C18.2472 0.1252 17.6904 -2.77851e-08 17.128 0C16.5657 2.77851e-08 16.0088 0.1252 15.4892 0.368452C14.9697 0.611704 14.4976 0.968245 14.1 1.41772C13.7023 1.86719 13.3869 2.40079 13.1717 2.98805C12.9565 3.57531 12.8457 4.20295 12.8457 4.83859L14.9869 4.83859C14.9869 4.52077 15.0422 4.20785 15.1498 3.91422C15.2574 3.62059 15.4152 3.35379 15.614 3.12905C15.8128 2.90431 16.0489 2.72604 16.3086 2.60442C16.5684 2.48279 16.8468 2.42019 17.128 2.42019C17.4092 2.42019 17.6876 2.48279 17.9474 2.60442C18.2072 2.72604 18.4432 2.90431 18.642 3.12905C18.8409 3.35379 18.9986 3.62059 19.1062 3.91422C19.2138 4.20785 19.2692 4.52077 19.2692 4.83859H21.4103Z"
        fill="#F27058"
      />
      <path
        d="M42.115 7.82411C42.115 8.99772 41.726 9.98957 40.948 10.7997C40.18 11.5994 39.2324 11.9992 38.1053 11.9992C36.9882 11.9992 36.0407 11.5942 35.2627 10.7841C34.4847 9.97399 34.0957 8.98733 34.0957 7.82411V3.66455H36.1155L36.1304 7.82411C36.1304 8.39534 36.32 8.88347 36.699 9.28852C37.088 9.69358 37.5568 9.8961 38.1053 9.8961C38.6539 9.8961 39.1227 9.69358 39.5117 9.28852C39.9007 8.88347 40.0952 8.39534 40.0952 7.82411V3.66455H42.115V7.82411Z"
        fill="#F27058"
      />
      <path
        d="M24.7913 7.84044C24.7913 6.66683 25.1803 5.67498 25.9583 4.86488C26.7263 4.06516 27.6738 3.6653 28.8009 3.6653C29.918 3.6653 30.8656 4.07035 31.6436 4.88046C32.4216 5.69056 32.8105 6.67722 32.8105 7.84044L32.8105 12L30.7908 12L30.7758 7.84044C30.7758 7.26922 30.5863 6.78108 30.2073 6.37603C29.8183 5.97098 29.3495 5.76845 28.8009 5.76845C28.2523 5.76845 27.7835 5.97098 27.3945 6.37603C27.0055 6.78108 26.8111 7.26922 26.8111 7.84044L26.8111 12L24.7913 12L24.7913 7.84044Z"
        fill="#F27058"
      />
      <path
        d="M60.7185 7.82411C60.7185 8.98733 60.3288 9.974 59.5493 10.7841C58.7799 11.5942 57.8356 11.9992 56.7164 11.9992C55.5872 11.9992 54.6328 11.5994 53.8534 10.7997C53.0839 9.98957 52.6992 8.99772 52.6992 7.82411C52.6992 6.66089 53.0889 5.67942 53.8684 4.8797C54.6478 4.0696 55.5972 3.66455 56.7164 3.66455C57.8356 3.66455 58.7799 4.0696 59.5493 4.8797C60.3288 5.67942 60.7185 6.66089 60.7185 7.82411ZM58.6949 7.82411C58.6949 7.26327 58.5001 6.78033 58.1104 6.37527C57.7206 5.97022 57.256 5.7677 56.7164 5.7677C56.1667 5.7677 55.6971 5.97022 55.3074 6.37527C54.9176 6.76994 54.7228 7.25288 54.7228 7.82411C54.7228 8.39534 54.9176 8.88347 55.3074 9.28852C55.6971 9.69358 56.1667 9.8961 56.7164 9.8961C57.266 9.8961 57.7306 9.69358 58.1104 9.28852C58.5001 8.88347 58.6949 8.39534 58.6949 7.82411Z"
        fill="#F27058"
      />
      <path
        d="M67.6125 9.44105C67.6125 9.94603 67.4654 10.4037 67.1712 10.814C66.5827 11.6345 65.746 12.0291 64.661 11.9975C64.2288 11.987 63.7553 11.8765 63.2403 11.6661C62.7346 11.4557 62.3208 11.1927 61.999 10.8771L63.0335 9.34637C63.53 9.88291 64.0587 10.1512 64.6196 10.1512H64.661C64.8908 10.1512 65.0977 10.1038 65.2816 10.0091C65.5207 9.88291 65.6402 9.70406 65.6402 9.47261V9.40949C65.6126 9.16752 65.4701 8.99394 65.2127 8.88873C65.1115 8.86769 64.8954 8.82035 64.5644 8.74671C64.1506 8.65203 63.8012 8.5363 63.5162 8.39954C62.6794 7.98924 62.2611 7.2686 62.2611 6.23761C62.2611 5.24869 62.6886 4.50701 63.5438 4.01255C63.9208 3.79163 64.3299 3.6759 64.7713 3.66538C65.231 3.65486 65.7092 3.74428 66.2057 3.93365C66.7758 4.15458 67.1804 4.44389 67.4194 4.80158L66.1781 6.0798C65.8563 5.71159 65.5069 5.52748 65.1299 5.52748C64.5414 5.52748 64.2472 5.74841 64.2472 6.19026V6.22183C64.2472 6.43223 64.4863 6.61108 64.9644 6.75836C65.0012 6.76888 65.3046 6.83727 65.8747 6.96351C67.0332 7.216 67.6125 8.02606 67.6125 9.39371V9.44105Z"
        fill="#F27058"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.4172 0H49.3322V4.1517C48.7638 3.8269 48.1252 3.66451 47.4165 3.66451C46.2973 3.66451 45.348 4.06956 44.5686 4.87966C43.7891 5.67937 43.3994 6.66084 43.3994 7.82407C43.3994 8.99767 43.7841 9.98953 44.5536 10.7996C45.333 11.5993 46.2874 11.9992 47.4165 11.9992C48.5357 11.9992 49.4801 11.5942 50.2495 10.7841C51.029 9.97395 51.4187 8.98729 51.4187 7.82407C51.4187 7.78295 51.4182 7.74205 51.4172 7.70139V0ZM48.8106 6.37523C49.2003 6.78028 49.3951 7.26323 49.3951 7.82407C49.3951 8.39529 49.2003 8.88343 48.8106 9.28848C48.4308 9.69353 47.9662 9.89606 47.4165 9.89606C46.8669 9.89606 46.3973 9.69353 46.0076 9.28848C45.6178 8.88343 45.423 8.39529 45.423 7.82407C45.423 7.25284 45.6178 6.76989 46.0076 6.37523C46.3973 5.97018 46.8669 5.76765 47.4165 5.76765C47.9562 5.76765 48.4208 5.97018 48.8106 6.37523Z"
        fill="#F27058"
      />
    </svg>
  );
};

const ForwardRef = forwardRef(SvgComponent);
const SimpleLogoNudos = memo(ForwardRef);
export default SimpleLogoNudos;
