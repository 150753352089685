import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_610_1570)">
      <path
        d="M0.936086 7.03421C0.94499 10.486 3.74767 13.277 7.19608 13.268C10.6445 13.2591 13.4327 10.4537 13.4238 7.00193C13.4149 3.55016 10.6122 0.759178 7.16384 0.768091C3.71542 0.777004 0.927182 3.58244 0.936086 7.03421Z"
        fill="white"
        stroke={props.stroke || "#C4C4C4"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.36782 7.02528L9.99219 7.01074"
        stroke={props.stroke || "#C4C4C4"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.97932 9.37114C8.88069 8.65325 9.29976 8.16479 9.84762 7.43705C10.0415 7.17955 10.0406 6.84239 9.84542 6.58588C9.29382 5.86098 8.87223 5.37469 7.96716 4.66147"
        stroke={props.stroke || "#C4C4C4"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_610_1570">
        <rect
          width="14"
          height="13.9863"
          fill="white"
          transform="matrix(0.00257958 0.999997 -0.999997 0.00258462 14.1553 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const ArrowIcon = memo(ForwardRef);
export default ArrowIcon;
