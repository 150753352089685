import {
  NewNodiLogo,
  NodiSection1Image1,
  NodiSection1Image1En,
} from "../../../assets/images/NodiPlatformView/NodiPlatformFirst";
import { toDemoForm, toEcommerce } from "../../../utils/toEcommerce";
import CenterSections from "../../../components/centerSections";
import "./NodiPlatformFirst.scss";
import NudosButton from "../../../components/NudosComponents/NudosButton/NudosButton";
import { Trans, useTranslation } from "react-i18next";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";

const NodiPlatformFirst = () => {
  const { t } = useTranslation();
  const appLanguage = getDefaultLanguage();
  const englishStyles = appLanguage === "en-US" ? "english" : "";

  const toSignUpAction = () => {
    toEcommerce("Landing_Nodi_SignUp_Click", "demo", {
      ClickOrigin: "/nodi-platform",
    });
  };

  const requestDemoAction = () => {
    toDemoForm("Landing_Nodi_Demo_Click", { ClickOrigin: "/nodi-platform" });
  };
  return (
    <>
      <CenterSections customClass="nodiPlatformFirstCenterSections">
        <div id="nodiPlatformFirst">
          <section className="contentSection">
            <div className="leftDiv">
              <div className="nodiLogoContainer">
                <NewNodiLogo />
              </div>
              <div className={`nodiExplanationText ${englishStyles}`}>
                <Trans
                  i18nKey={"nodiPlatform:nodiPlatformFirst:title"}
                  components={{ 1: <strong /> }}
                />
              </div>
              <div className="buttonsContainer">
                <NudosButton
                  buttonText={t("globalButtons:signUpButton")}
                  componentSize="large"
                  handleClick={toSignUpAction}
                  colorPalette={"blackBorder"}
                  customClassName="animatedBlackButton"
                />
                <NudosButton
                  buttonText={t("globalButtons:DemoButton")}
                  componentSize="large"
                  handleClick={requestDemoAction}
                  colorPalette={"blackContent"}
                  customClassName="animatedBlackButton"
                />
              </div>
            </div>
            <div className="rightDiv">
              <div className="imageContainer">
                <img
                  src={
                    appLanguage === "en-US"
                      ? NodiSection1Image1En
                      : NodiSection1Image1
                  }
                  alt="screenshots showing nodi various functions"
                />
              </div>
            </div>
          </section>
        </div>
      </CenterSections>
    </>
  );
};

export default NodiPlatformFirst;
