import { IdataObject } from "../types/global.types";
import { segmentEvent } from "./segment";

export const toEcommerce = (
  eventName?: string,
  specificPath?: string,
  eventData?: IdataObject
) => {
  if (eventName) {
    segmentEvent({ event: eventName, data: { ...eventData } });
  }
  const sendTo =
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === "development" ||
    window.location.host.includes("dev.")
      ? `https://nodi.beta.nudos.co/${specificPath ? specificPath : ""}`
      : `https://nodi.nudos.co/${specificPath ? specificPath : ""}`;
  window.open(sendTo);
};

export const toDemoForm = (eventName?: string, eventData?: IdataObject) => {
  if (eventName) {
    segmentEvent({ event: eventName, data: { ...eventData } });
  }
  window.location.href = "/home#demo-form";
};
