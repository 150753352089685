import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M11.001 1.98183C13.938 1.98183 14.2863 1.99283 15.4459 2.046C16.6449 2.101 17.8796 2.37417 18.7532 3.24775C19.6351 4.12958 19.9 5.35242 19.955 6.55508C20.0081 7.71467 20.0191 8.063 20.0191 11C20.0191 13.937 20.0081 14.2853 19.955 15.4449C19.9009 16.6375 19.6213 17.8842 18.7532 18.7523C17.8714 19.6341 16.6495 19.899 15.4459 19.954C14.2863 20.0072 13.938 20.0182 11.001 20.0182C8.06398 20.0182 7.71564 20.0072 6.55606 19.954C5.37264 19.8999 4.10948 19.6139 3.24873 18.7523C2.37148 17.875 2.10198 16.6403 2.04698 15.4449C1.99381 14.2853 1.98281 13.937 1.98281 11C1.98281 8.063 1.99381 7.71467 2.04698 6.55508C2.10106 5.36708 2.38339 4.11308 3.24873 3.24775C4.12873 2.36775 5.35614 2.101 6.55606 2.046C7.71564 1.99283 8.06398 1.98183 11.001 1.98183ZM11.001 0C8.01356 0 7.63864 0.0128333 6.46531 0.066C4.76489 0.143917 3.07639 0.616917 1.84714 1.84617C0.61331 3.08 0.144893 4.76483 0.0669766 6.46433C0.0138099 7.63767 0.000976562 8.01258 0.000976562 11C0.000976562 13.9874 0.0138099 14.3623 0.0669766 15.5357C0.144893 17.2343 0.619727 18.9273 1.84714 20.1538C3.08006 21.3868 4.76764 21.8561 6.46531 21.934C7.63864 21.9872 8.01356 22 11.001 22C13.9884 22 14.3633 21.9872 15.5366 21.934C17.2361 21.8561 18.9265 21.3822 20.1548 20.1538C21.3896 18.9191 21.8571 17.2352 21.935 15.5357C21.9881 14.3623 22.001 13.9874 22.001 11C22.001 8.01258 21.9881 7.63767 21.935 6.46433C21.8571 4.76392 21.3831 3.0745 20.1548 1.84617C18.9237 0.615083 17.2316 0.143 15.5366 0.066C14.3633 0.0128333 13.9884 0 11.001 0Z"
        fill="white"
      />
      <path
        d="M11.001 5.35156C7.88162 5.35156 5.35254 7.88065 5.35254 11.0001C5.35254 14.1195 7.88162 16.6486 11.001 16.6486C14.1205 16.6486 16.6495 14.1195 16.6495 11.0001C16.6495 7.88065 14.1205 5.35156 11.001 5.35156ZM11.001 14.6667C8.97612 14.6667 7.33437 13.025 7.33437 11.0001C7.33437 8.97515 8.97612 7.3334 11.001 7.3334C13.026 7.3334 14.6677 8.97515 14.6677 11.0001C14.6677 13.025 13.026 14.6667 11.001 14.6667Z"
        fill="white"
      />
      <path
        d="M16.8727 6.44859C17.6018 6.44859 18.1927 5.85761 18.1927 5.12859C18.1927 4.39958 17.6018 3.80859 16.8727 3.80859C16.1437 3.80859 15.5527 4.39958 15.5527 5.12859C15.5527 5.85761 16.1437 6.44859 16.8727 6.44859Z"
        fill="white"
      />
    </svg>
  );
};

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
