import {
  GreyTruck,
  orangeTruck,
  trajectoryDesktop,
  trajectoryMobile,
} from "../../../../assets/images/home/general/TruckAnimation";
import usePlatform from "../../../../hooks/usePlatform";
import "./TruckAnimation.scss";

const TruckAnimation = () => {
  const { isMobile } = usePlatform();
  if (!isMobile)
    return (
      <div className="truckAnimationDesktop">
        <img className="trajectory" src={trajectoryDesktop} alt="trayectoria" />
        <img className="greyTruck1" src={GreyTruck} alt="Ícono camión gris" />
        <img className="greyTruck2" src={GreyTruck} alt="Ícono camión gris" />
        <img
          className="orangeTruck1"
          src={orangeTruck}
          alt="Ícono camión naranja"
        />
        <img
          className="orangeTruck2"
          src={orangeTruck}
          alt="Ícono camión naranja"
        />
      </div>
    );
  else
    return (
      <div className="truckAnimationMobile">
        <img className="trajectory" src={trajectoryMobile} alt="trayectoria" />
        <img className="greyTruck1" src={GreyTruck} alt="Ícono camión gris" />
        <img
          className="orangeTruck1"
          src={orangeTruck}
          alt="Ícono camión naranja"
        />
      </div>
    );
};

export default TruckAnimation;
