import * as React from "react";
import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={14}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0.255637 7.8H5.03164C5.21164 7.8 5.33164 7.692 5.35564 7.512L5.48764 6.48C5.49964 6.3 5.40364 6.192 5.22364 6.192H1.92364L2.11564 4.704H5.16364C5.34364 4.704 5.46364 4.596 5.48764 4.416L5.60764 3.384C5.63164 3.204 5.53564 3.096 5.35564 3.096H2.30764L2.48764 1.608H5.78764C5.96764 1.608 6.08764 1.5 6.11164 1.32L6.24364 0.288C6.26764 0.108 6.17164 0 5.99164 0H1.21564C1.03564 0 0.915637 0.108 0.891637 0.288L0.00363684 7.512C-0.0203632 7.692 0.0756368 7.8 0.255637 7.8Z"
      fill={props?.fill || "#F27058"}
    />
    <path
      d="M6.57814 7.8H7.76614C7.94614 7.8 8.06614 7.692 8.09014 7.512L8.63014 3.132L10.9221 7.608C10.9941 7.74 11.0901 7.8 11.2461 7.8H12.4461C12.6261 7.8 12.7461 7.692 12.7701 7.512L13.6581 0.288C13.6821 0.108 13.5861 0 13.4061 0H12.2181C12.0381 0 11.9181 0.108 11.8941 0.288L11.3541 4.68L9.05014 0.192C8.97814 0.0599999 8.88214 0 8.72614 0H7.53814C7.35814 0 7.23814 0.108 7.21414 0.288L6.32614 7.512C6.30214 7.692 6.39814 7.8 6.57814 7.8Z"
      fill={props?.fill || "#F27058"}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const EnIcon = memo(ForwardRef);
export default EnIcon;
