import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookGreyIcon,
  LinkedInGreyIcon,
  TwitterGreyIcon,
  WhatsAppGreyIcon,
} from "../../assets/images/blog";
import "./NudosShareOnSocialMedia.scss";

/**
 *
 * @property {string} shareUrl - The url to be shared on the social media
 * @property {number} iconSize - OPTIONAL: the size of each social media icon, in pixels (just the number)
 */
const NudosShareOnSocialMedia = ({
  shareUrl,
  iconSize,
}: {
  shareUrl: string;
  iconSize?: number;
}) => {
  const imgStyles = { width: iconSize ?? 16, height: iconSize ?? 16 };
  return (
    <div className="nudosShareOnSocialMedia">
      <FacebookShareButton url={shareUrl}>
        <img src={FacebookGreyIcon} alt="Logo Facebook" style={imgStyles} />
      </FacebookShareButton>
      <LinkedinShareButton url={shareUrl}>
        <img src={LinkedInGreyIcon} alt="Logo LinkedIn" style={imgStyles} />
      </LinkedinShareButton>
      <TwitterShareButton url={shareUrl}>
        <img src={TwitterGreyIcon} alt="Logo Twitter" style={imgStyles} />
      </TwitterShareButton>
      <WhatsappShareButton url={shareUrl}>
        <img src={WhatsAppGreyIcon} alt="Logo WhatsApp" style={imgStyles} />
      </WhatsappShareButton>
    </div>
  );
};

export default NudosShareOnSocialMedia;
