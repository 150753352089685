import { FC } from "react";
import { useTranslation } from "react-i18next";
import usePlatform from "../../../../hooks/usePlatform";

export interface IBanner {
  item?: IItem;
}

export interface IItem {
  info: IInfo;
}

export interface IInfo {
  logo?: string;
  background?: string;
  backgroundMobile?: string;
  color?: string;
  max?: string;
  title?: string;
  subtitle?: string;
}

const Banner: FC<IBanner> = ({ item }: IBanner) => {
  const { t } = useTranslation();
  const { isMobile } = usePlatform();
  return (
    <div
      className={`banner ${item?.info?.color || "bg-black"}`}
      style={{
        backgroundImage: ` url(${
          isMobile ? item?.info?.backgroundMobile : item?.info?.background
        })`,
      }}
    >
      <div className="containerImg">
        <div className="logoBox">
          {item?.info?.logo ? (
            <img
              style={{ maxWidth: item?.info?.max || "134px" }}
              alt="companyLogo"
              src={item?.info?.logo}
            />
          ) : (
            <div>
              {item?.info?.title && (
                <div className="titleBanner">{t(item?.info?.title)}</div>
              )}
              <div>{item?.info?.subtitle}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Banner;
