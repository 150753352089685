import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import CartIcon from "../../assets/images/equipament/EquipmentSecond/cartIcon";
import ClosingXButton from "../../assets/images/equipament/EquipmentSecond/ClosingXButton";
import { EncircledCheckmark } from "../../assets/images/NodiPlatformView/NodiPlatformSecond";
import { IproductInformation } from "../../sections/equipmentLanding/EquipmentSecond/components/Card";
import { getDefaultLanguage } from "../../utils/getDefaultLanguage";
import { segmentEvent } from "../../utils/segment";
import { toEcommerce } from "../../utils/toEcommerce";
import "./ProductInternalCard.scss";

const ProductInternalCard = ({
  item,
  setIsModalOpen,
}: {
  item: IproductInformation;
  setIsModalOpen: (showModal: boolean) => void;
}) => {
  const { t } = useTranslation();
  const myLanguage = getDefaultLanguage();
  return (
    <div id="productInternalCard">
      <section className="leftSection">
        <div
          className={`brandIconContainer ${
            item.brandName ? item.brandName : ""
          }`}
        >
          <img src={item.brandLogo} alt="Brand logo" />
        </div>
        <div className="productImgContainer">
          <img src={item.img} alt="Product" />
        </div>
        <div className="productName">
          {myLanguage === "en-US" && item?.nameEn ? item?.nameEn : item.name}
        </div>
        <div className="pricesAndButtons">
          <div className="fromText">
            {t("equipament:secondSection:card:range")}
          </div>
          <div>
            <button
              className="whiteButton"
              onClick={() => {
                segmentEvent({
                  event: "Landing_Equipment_Product_Click",
                  data: { ProductName: item?.name },
                });
                toEcommerce();
              }}
            >
              {item?.sale}{" "}
              <div className="containerIcon">
                <CartIcon className="styleIconsButtons" />
              </div>
            </button>
          </div>
        </div>
      </section>
      <section className="rightSection">
        <ProductDetails
          details={
            myLanguage === "en-US" && item?.detailsEn
              ? item?.detailsEn || item.details
              : item.details
          }
        />
        <div className="descriptionLine">
          <div className="icon">
            <EncircledCheckmark />
          </div>
          <div className="text">
            {myLanguage === "en-US"
              ? item?.descriptionLineEn || item?.descriptionLine
              : item.descriptionLine}
          </div>
        </div>
        <div className="colorsContainer">
          <h2 className="title">{t("equipament:secondSection:card:colors")}</h2>
          {item?.colorsEn &&
            myLanguage === "en-US" &&
            Object.entries(item.colors).length > 0 && (
              <div className="colorOptionsWrapper">
                {Object.entries(item.colorsEn).map(([colorName, colorRgb]) => {
                  return (
                    <div key={uuid()} className="colorOption">
                      <div
                        className="colorCircle"
                        style={{ backgroundColor: colorRgb }}
                      ></div>
                      <div className="colorName">{colorName}</div>
                    </div>
                  );
                })}
              </div>
            )}
          {item.colors &&
            myLanguage === "es-MX" &&
            Object.entries(item.colors).length > 0 && (
              <div className="colorOptionsWrapper">
                {Object.entries(item.colors).map(([colorName, colorRgb]) => {
                  return (
                    <div key={uuid()} className="colorOption">
                      <div
                        className="colorCircle"
                        style={{ backgroundColor: colorRgb }}
                      ></div>
                      <div className="colorName">{colorName}</div>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
        <div className="closingXButton" onClick={() => setIsModalOpen(false)}>
          <ClosingXButton />
        </div>
      </section>
    </div>
  );
};

const ProductDetails = ({ details }: { details: IproductDetails }) => {
  const { t } = useTranslation();

  const keyLanguage = (key: string) => {
    switch (key) {
      case "Procesador":
        return t("equipament:secondSection:features:processor");
      case "Modelo":
        return t("equipament:secondSection:features:model");
      case "Memoria":
        return t("equipament:secondSection:features:memory");
      case "Almacenamiento":
        return t("equipament:secondSection:features:storage");
      case "Pantalla":
        return t("equipament:secondSection:features:display");
      case "Ancho":
        return t("equipament:secondSection:features:width");
      case "Altura":
        return t("equipament:secondSection:features:height");
      case "Material":
        return t("equipament:secondSection:features:material");
      default:
        return key;
    }
  };

  return (
    <div className="productDetailsContainer">
      {Object.entries(details).map(([detailKey, detailValues]) => {
        return (
          <div key={uuid()} className="productDetail">
            <div className="detailName">{keyLanguage(detailKey)}</div>
            <div className="detailOptionsContainer">
              {detailValues.map((option: string) => {
                return (
                  <div key={uuid()} className="optionContainer">
                    <p>{option}</p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export interface IproductDetails {
  Modelo?: string[];
  Procesador?: string[];
  Memoria?: string[];
  Almacenamiento?: string[];
  Pantalla?: string[];
  Ancho?: string[];
  Altura?: string[];
  Material?: string[];
}

export default ProductInternalCard;
