import { useState } from "react";
import arrow from "../../../assets/images/questions/first/arrow_drop.svg";
import { segmentEvent } from "../../../utils/segment";

interface IItem {
  ask: string;
  response: string;
}

const Items = ({ ask, response }: IItem) => {
  const [stateQuestion, setStateQuestion] = useState<boolean>(false);

  const changeState = () => {
    if (response) {
      if (!stateQuestion) {
        segmentEvent({ event: "FAQs_Question_Click" });
      }
      setStateQuestion(!stateQuestion);
    }
  };
  return (
    <div className="itemContainer">
      <div className="pricipalTitle">
        <div className="askContainer">
          <div className="titleAskContainer">{ask}</div>
        </div>
        <div className="iconContainer" onClick={changeState}>
          <img
            className={stateQuestion ? "rotateImg" : ""}
            alt="arrow"
            src={arrow}
          />
        </div>
      </div>
      {stateQuestion && <div className="resptText">{response}</div>}
    </div>
  );
};
export default Items;
