import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={45}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M18 10.454c0-1.277-.233-2.537-.685-3.716a9.802 9.802 0 0 0-1.95-3.152 8.988 8.988 0 0 0-2.92-2.107A8.427 8.427 0 0 0 9 .739a8.427 8.427 0 0 0-3.444.74 8.988 8.988 0 0 0-2.92 2.107 9.803 9.803 0 0 0-1.95 3.152A10.375 10.375 0 0 0 0 10.453h4.5c0-.638.116-1.266.343-1.855.226-.59.557-1.125.975-1.577.418-.45.914-.809 1.46-1.053A4.213 4.213 0 0 1 9 5.598c.591 0 1.176.126 1.722.37a4.495 4.495 0 0 1 1.46 1.053c.418.452.75.987.976 1.577.226.59.342 1.217.342 1.855H18ZM13.51 15.025c0 1.276.232 2.536.685 3.715a9.804 9.804 0 0 0 1.95 3.153A8.988 8.988 0 0 0 19.066 24a8.426 8.426 0 0 0 3.445.74 8.426 8.426 0 0 0 3.444-.74 8.987 8.987 0 0 0 2.92-2.107 9.804 9.804 0 0 0 1.95-3.153c.453-1.179.686-2.439.686-3.715h-4.5c0 .638-.117 1.266-.343 1.856a4.9 4.9 0 0 1-.975 1.576c-.418.451-.914.81-1.46 1.053a4.213 4.213 0 0 1-1.722.37c-.591 0-1.177-.125-1.723-.37a4.494 4.494 0 0 1-1.46-1.053 4.9 4.9 0 0 1-.975-1.576 5.177 5.177 0 0 1-.343-1.856h-4.5ZM45 10.454c0-1.277-.233-2.537-.685-3.716a9.802 9.802 0 0 0-1.95-3.152 8.988 8.988 0 0 0-2.92-2.107A8.427 8.427 0 0 0 36 .739a8.427 8.427 0 0 0-3.444.74 8.988 8.988 0 0 0-2.92 2.107 9.802 9.802 0 0 0-1.95 3.152A10.375 10.375 0 0 0 27 10.453h4.5c0-.638.116-1.266.343-1.855.226-.59.557-1.125.975-1.577.418-.45.914-.809 1.46-1.053A4.214 4.214 0 0 1 36 5.598c.591 0 1.176.126 1.722.37a4.495 4.495 0 0 1 1.46 1.053c.418.452.75.987.976 1.577.226.59.342 1.217.342 1.855H45Z"
      fill="#F27058"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const NudosLogo = memo(ForwardRef);
export default NudosLogo;
