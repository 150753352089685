const Source = "Landing";

interface IEvent {
  event: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

const getScreenSize = () =>
  `width ${
    window && window.innerWidth !== undefined && window.innerWidth
  }px, height ${
    window && window.innerHeight !== undefined && window.innerHeight
  }px`;

const generateUuid = () =>
  `${
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  }-${Date.now()}`;

const getId = () => {
  const uniqSessionIdLS = localStorage.getItem("uniqSessionId");

  if (uniqSessionIdLS) {
    const gItem = localStorage.getItem("uniqSessionId");
    return gItem ? JSON.parse(gItem).id : null;
  }

  const uniqId = generateUuid();
  localStorage.setItem("uniqSessionId", JSON.stringify({ id: uniqId }));
  return uniqId;
};

const getSessionId = () => {
  const jsonId = getId();

  if (jsonId) {
    return jsonId;
  }

  const uniqId = `${
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  }-${Date.now()}`;
  localStorage.setItem("uniqSessionId", JSON.stringify({ id: uniqId }));
  return uniqId;
};

export const segmentEvent = ({ event, data }: IEvent) => {
  if (event) {
    const dataToSend = {
      ScreenSize: getScreenSize(),
      Source,
      SessionId: getSessionId(),
      ...data,
    };

    window.analytics.track(event, dataToSend);
  }
};
