import * as React from "react";
import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={100}
    height={34}
    fill="none"
    viewBox="0 0 100 34"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g filter="url(#filter0_d_6121_140052)">
      <path
        d="M20.043 13.372C20.043 12.6663 19.9131 11.9695 19.6608 11.3175C19.4084 10.6655 19.0385 10.073 18.5722 9.57401C18.1059 9.07499 17.5524 8.67914 16.9431 8.40907C16.3339 8.139 15.6809 8 15.0215 8C14.3621 8 13.7091 8.139 13.0999 8.40907C12.4906 8.67914 11.9371 9.07499 11.4708 9.57401C11.0045 10.073 10.6346 10.6655 10.3822 11.3175C10.1299 11.9695 10 12.6663 10 13.372L12.5107 13.372C12.5107 13.0192 12.5757 12.6717 12.7019 12.3457C12.828 12.0197 13.013 11.7235 13.2461 11.474C13.4793 11.2245 13.7561 11.0266 14.0607 10.8915C14.3653 10.7565 14.6918 10.687 15.0215 10.687C15.3512 10.687 15.6777 10.7565 15.9823 10.8915C16.2869 11.0266 16.5637 11.2245 16.7969 11.474C17.03 11.7235 17.215 12.0197 17.3411 12.3457C17.4673 12.6717 17.5322 13.0192 17.5322 13.372H20.043Z"
        fill="#F27058"
      />
      <path
        d="M17.539 15.8975C17.539 16.6032 17.6689 17.3001 17.9213 17.9521C18.1736 18.6041 18.5435 19.1965 19.0098 19.6955C19.4761 20.1945 20.0297 20.5904 20.6389 20.8605C21.2481 21.1305 21.9011 21.2695 22.5605 21.2695C23.22 21.2695 23.8729 21.1305 24.4822 20.8605C25.0914 20.5904 25.645 20.1945 26.1113 19.6955C26.5776 19.1965 26.9474 18.6041 27.1998 17.9521C27.4521 17.3001 27.582 16.6032 27.582 15.8975L25.0713 15.8975C25.0713 16.2504 25.0063 16.5978 24.8802 16.9238C24.754 17.2498 24.569 17.546 24.3359 17.7955C24.1028 18.045 23.826 18.243 23.5214 18.378C23.2167 18.513 22.8902 18.5825 22.5605 18.5825C22.2308 18.5825 21.9043 18.513 21.5997 18.378C21.2951 18.243 21.0183 18.045 20.7852 17.7955C20.552 17.546 20.3671 17.2498 20.2409 16.9238C20.1147 16.5978 20.0498 16.2504 20.0498 15.8975L17.539 15.8975Z"
        fill="#F27058"
      />
      <path
        d="M35.1055 13.372C35.1055 12.6663 34.9756 11.9695 34.7233 11.3175C34.4709 10.6655 34.101 10.073 33.6347 9.57401C33.1684 9.07499 32.6149 8.67914 32.0056 8.40907C31.3964 8.139 30.7434 8 30.084 8C29.4246 8 28.7716 8.139 28.1624 8.40907C27.5531 8.67914 26.9996 9.07499 26.5333 9.57401C26.067 10.073 25.6971 10.6655 25.4447 11.3175C25.1924 11.9695 25.0625 12.6663 25.0625 13.372L27.5732 13.372C27.5732 13.0192 27.6382 12.6717 27.7644 12.3457C27.8905 12.0197 28.0755 11.7235 28.3086 11.474C28.5418 11.2245 28.8186 11.0266 29.1232 10.8915C29.4278 10.7565 29.7543 10.687 30.084 10.687C30.4137 10.687 30.7402 10.7565 31.0448 10.8915C31.3494 11.0266 31.6262 11.2245 31.8594 11.474C32.0925 11.7235 32.2775 12.0197 32.4036 12.3457C32.5298 12.6717 32.5947 13.0192 32.5947 13.372H35.1055Z"
        fill="#F27058"
      />
      <path
        d="M60.8959 16.6505C60.8959 17.9483 60.4519 19.0451 59.5639 19.941C58.6872 20.8253 57.6056 21.2675 56.3191 21.2675C55.0439 21.2675 53.9623 20.8196 53.0743 19.9237C52.1862 19.0279 51.7422 17.9368 51.7422 16.6505V12.0508H54.0477L54.0648 16.6505C54.0648 17.2822 54.2811 17.822 54.7137 18.2699C55.1578 18.7178 55.6929 18.9418 56.3191 18.9418C56.9453 18.9418 57.4804 18.7178 57.9244 18.2699C58.3684 17.822 58.5904 17.2822 58.5904 16.6505V12.0508H60.8959V16.6505Z"
        fill="#F27058"
      />
      <path
        d="M41.1197 16.6698C41.1197 15.372 41.5637 14.2752 42.4518 13.3794C43.3284 12.495 44.41 12.0528 45.6966 12.0528C46.9717 12.0528 48.0533 12.5007 48.9414 13.3966C49.8294 14.2924 50.2734 15.3835 50.2734 16.6698L50.2734 21.2695L47.9679 21.2695L47.9508 16.6698C47.9508 16.0381 47.7345 15.4983 47.3019 15.0504C46.8579 14.6025 46.3227 14.3785 45.6966 14.3785C45.0704 14.3785 44.5353 14.6025 44.0912 15.0504C43.6472 15.4983 43.4252 16.0381 43.4252 16.6698L43.4252 21.2695L41.1197 21.2695L41.1197 16.6698Z"
        fill="#F27058"
      />
      <path
        d="M82.1303 16.6505C82.1303 17.9368 81.6855 19.0279 80.7958 19.9237C79.9175 20.8196 78.8395 21.2675 77.562 21.2675C76.2731 21.2675 75.1837 20.8253 74.294 19.941C73.4157 19.0451 72.9766 17.9483 72.9766 16.6505C72.9766 15.3642 73.4214 14.2789 74.3111 13.3945C75.2008 12.4987 76.2845 12.0508 77.562 12.0508C78.8395 12.0508 79.9175 12.4987 80.7958 13.3945C81.6855 14.2789 82.1303 15.3642 82.1303 16.6505ZM79.8205 16.6505C79.8205 16.0303 79.5981 15.4963 79.1532 15.0484C78.7084 14.6004 78.178 14.3765 77.562 14.3765C76.9346 14.3765 76.3985 14.6004 75.9537 15.0484C75.5088 15.4848 75.2864 16.0188 75.2864 16.6505C75.2864 17.2822 75.5088 17.822 75.9537 18.2699C76.3985 18.7178 76.9346 18.9418 77.562 18.9418C78.1894 18.9418 78.7198 18.7178 79.1532 18.2699C79.5981 17.822 79.8205 17.2822 79.8205 16.6505Z"
        fill="#F27058"
      />
      <path
        d="M90.0014 18.4386C90.0014 18.997 89.8335 19.503 89.4976 19.9568C88.8259 20.8642 87.8708 21.3004 86.6323 21.2655C86.139 21.2539 85.5984 21.1318 85.0107 20.8991C84.4334 20.6664 83.9611 20.3756 83.5938 20.0266L84.7745 18.3339C85.3413 18.9272 85.9448 19.2238 86.585 19.2238H86.6323C86.8947 19.2238 87.1308 19.1715 87.3407 19.0668C87.6136 18.9272 87.7501 18.7294 87.7501 18.4735V18.4037C87.7186 18.1361 87.5559 17.9441 87.262 17.8278C87.1466 17.8045 86.8999 17.7522 86.5221 17.6707C86.0498 17.566 85.6509 17.4381 85.3255 17.2868C84.3704 16.8331 83.8929 16.0362 83.8929 14.8961C83.8929 13.8026 84.3809 12.9824 85.357 12.4356C85.7874 12.1913 86.2544 12.0633 86.7582 12.0517C87.283 12.0401 87.8288 12.139 88.3955 12.3484C89.0463 12.5927 89.5081 12.9126 89.781 13.3081L88.3641 14.7216C87.9967 14.3144 87.5979 14.1109 87.1675 14.1109C86.4958 14.1109 86.16 14.3552 86.16 14.8438V14.8787C86.16 15.1113 86.4328 15.3091 86.9786 15.472C87.0206 15.4836 87.367 15.5592 88.0177 15.6988C89.3402 15.9781 90.0014 16.8738 90.0014 18.3862V18.4386Z"
        fill="#F27058"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.5167 8H69.1367V12.5903C68.4875 12.2306 67.7582 12.0508 66.9487 12.0508C65.6712 12.0508 64.5876 12.4987 63.6978 13.3945C62.8081 14.2789 62.3633 15.3642 62.3633 16.6505C62.3633 17.9483 62.8024 19.0451 63.6807 19.941C64.5705 20.8253 65.6598 21.2675 66.9487 21.2675C68.2263 21.2675 69.3042 20.8196 70.1825 19.9237C71.0722 19.0279 71.517 17.9368 71.517 16.6505C71.517 16.6301 71.5169 16.6098 71.5167 16.5895V8ZM68.5399 15.0484C68.9848 15.4963 69.2072 16.0303 69.2072 16.6505C69.2072 17.2822 68.9848 17.822 68.5399 18.2699C68.1065 18.7178 67.5761 18.9418 66.9487 18.9418C66.3214 18.9418 65.7852 18.7178 65.3404 18.2699C64.8955 17.822 64.6731 17.2822 64.6731 16.6505C64.6731 16.0188 64.8955 15.4848 65.3404 15.0484C65.7852 14.6004 66.3214 14.3765 66.9487 14.3765C67.5647 14.3765 68.0951 14.6004 68.5399 15.0484Z"
        fill="#F27058"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6121_140052"
        x="0.194264"
        y="0.645698"
        width="99.6129"
        height="32.881"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.45143" />
        <feGaussianBlur stdDeviation="4.90287" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.701961 0 0 0 0 0.701961 0 0 0 0 0.701961 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6121_140052"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6121_140052"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const LogoHeaderD = memo(ForwardRef);
export default LogoHeaderD;
