import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { IblogEntryDetailedData, IblogListingEntryData } from "../types/blog";

const { REACT_APP_NUDOS_API } = process.env;
const axiosApiInstance = axios.create();

const getBlogListing = async (): Promise<IblogListingEntryData[] | any> => {
  return axiosApiInstance
    .get<AxiosRequestConfig, IblogListingEntryData[] | any>(
      `${REACT_APP_NUDOS_API}/post/landing`
    )
    .then((response) =>
      Promise.resolve(
        response.data
          ? (response.data as IblogListingEntryData[])
          : (response as IblogListingEntryData[])
      )
    )
    .catch((error: AxiosError) => Promise.reject(error));
};

const getBlogEntry = async (
  postId: number | string
): Promise<IblogEntryDetailedData | any> => {
  return axiosApiInstance
    .get<AxiosRequestConfig, IblogEntryDetailedData | any>(
      `${REACT_APP_NUDOS_API}/post/${postId} `
    )
    .then((response) =>
      Promise.resolve(
        response?.data
          ? (response.data as IblogEntryDetailedData)
          : (response as IblogEntryDetailedData)
      )
    )
    .catch((error: AxiosError) => Promise.reject(error));
};

export { getBlogListing, getBlogEntry };
