import { Trans, useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import {
  CurvyArrowUnderline,
  LatamMap,
  WorldGlobeIcon,
  curvyUnderlinedArrow1Home5,
  curvyUnderlinedArrow2Home5,
} from "../../../assets/images/home/HomeFifth";
import CenterSections from "../../../components/centerSections";
import { TruckAnimation } from "../Components";
import "./HomeFifth.scss";
import {
  flagArgentina,
  flagBolivia,
  flagBrasil,
  flagChile,
  flagColombia,
  flagCostaRica,
  flagEcuador,
  flagElSalvador,
  flagGuatemala,
  flagHonduras,
  flagMexico,
  flagPanama,
  flagParaguay,
  flagPeru,
  flagRepublicaDominicana,
  flagUruguay,
  flagVenezuela,
} from "../../../assets/images/flags";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";
import usePlatform from "../../../hooks/usePlatform";

const HomeFifth = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = usePlatform();
  const appLanguage = getDefaultLanguage();

  const englishStyles = appLanguage === "en-US" ? "english" : "";
  const isTabletOrMobile = isMobile || isTablet;
  const underlineArrow1 = isTabletOrMobile
    ? curvyUnderlinedArrow1Home5
    : CurvyArrowUnderline;
  const underlineArrow2 = isTabletOrMobile
    ? curvyUnderlinedArrow2Home5
    : CurvyArrowUnderline;

  return (
    <CenterSections customClass="homeFifthCenterSections">
      <div className="homeFifth">
        <section className="orangeContainer leftSection">
          <h2 className="title">
            {t("home:fifthSection:nudosBlackAccess")}
            <span className="nudosText">
              {t("home:fifthSection:nudosWhiteAccess")}
              <img
                className="curvyArrowUnderline"
                src={underlineArrow1}
                alt="Subrayado flecha curva"
              />
            </span>
          </h2>
          <div className="mapAndBlackbox">
            <div className="blackBox">
              <Trans i18nKey={"home:fifthSection:subtitleNudosAccess"} />
            </div>
            <img
              className="worldGlobeIcon"
              src={WorldGlobeIcon}
              alt="Globo terráqueo"
            />
          </div>
        </section>
        <section className="orangeContainer rightSection">
          <div className={`rightTitle ${englishStyles}`}>
            <div className="blackText1">
              {t("home:fifthSection:purchaseBlack1")}
            </div>
            <div className="nodi">
              Nodi
              <img
                className="curvyArrowUnderline"
                src={underlineArrow2}
                alt="Subrayado flecha curva"
              />
            </div>
            <div className="blackText2">
              {t("home:fifthSection:purchaseBlack2")}
            </div>
          </div>
          <div className="flagsContainer">
            {flags.map((flag) => {
              return (
                <div
                  key={uuid()}
                  className="flag"
                  style={{ backgroundImage: `url(${flag})` }}
                />
              );
            })}
          </div>
          <img
            className="mapIcon"
            src={LatamMap}
            alt="Mapa Latam con banderas"
          />
        </section>
      </div>
      <TruckAnimation />
    </CenterSections>
  );
};

export default HomeFifth;

const flags = [
  flagArgentina,
  flagBolivia,
  flagBrasil,
  flagChile,
  flagColombia,
  flagCostaRica,
  flagEcuador,
  flagElSalvador,
  flagGuatemala,
  flagHonduras,
  flagMexico,
  flagPanama,
  flagParaguay,
  flagPeru,
  flagRepublicaDominicana,
  flagUruguay,
  flagVenezuela,
];
