import { SVGProps, Ref, forwardRef, memo } from "react"

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={10}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m6.77 5 2.653-2.653a.834.834 0 0 0 0-1.18l-.59-.589a.834.834 0 0 0-1.18 0L5 3.232 2.347.578a.834.834 0 0 0-1.18 0l-.59.59a.834.834 0 0 0 0 1.18L3.232 5 .578 7.654a.834.834 0 0 0 0 1.18l.59.59a.834.834 0 0 0 1.179 0L5 6.77l2.654 2.653a.834.834 0 0 0 1.179 0l.59-.59a.834.834 0 0 0 0-1.179L6.769 5.001Z"
      fill="#999"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
const ClosingXButton = memo(ForwardRef)
export default ClosingXButton
