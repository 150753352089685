import NodiPlatformFirst from "./NodiPlatformFirst";
import NodiPlatformSecond from "./NodiPlatformSecond";
import { FloatingPromotionButton } from "../../components";
import { useEffect } from "react";
import { segmentEvent } from "../../utils/segment";
import NodiPlatformThird from "./NodiPlatformThird";

const NodiPlatformView = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    segmentEvent({ event: "Landing_Nodi_View" });
  }, []);

  return (
    <>
      <NodiPlatformFirst />
      <NodiPlatformSecond />
      <NodiPlatformThird />
      <FloatingPromotionButton />
    </>
  );
};

export default NodiPlatformView;
