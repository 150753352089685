import { FC, ReactNode } from "react";

interface ICenterSection {
  children: ReactNode;
  customClass?: string;
}

const CenterSections: FC<ICenterSection> = ({
  children,
  customClass,
}: ICenterSection) => {
  return (
    <div className={`containerSections ${customClass || ""}`}>
      <div className="centerSections">{children}</div>
    </div>
  );
};

export default CenterSections;
