import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2 2.62042H11.3333L10 7.62042H3.33333L2 2.62042Z"
      stroke={props.stroke || "#F27058"}
      strokeLinejoin="round"
    />
    <path
      d="M2 2.62044L1.66667 1.28711H0"
      stroke={props.stroke || "#F27058"}
      strokeLinejoin="round"
    />
    <circle
      cx="3.66699"
      cy="10.2871"
      r="1"
      stroke={props.stroke || "#F27058"}
      strokeLinejoin="round"
    />
    <circle
      cx="9.66699"
      cy="10.2871"
      r="1"
      stroke={props.stroke || "#F27058"}
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CartIcon = memo(ForwardRef);
export default CartIcon;
