import * as React from "react";
import { SVGProps, Ref, forwardRef, memo } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={141}
    height={41}
    viewBox="0 0 141 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g filter="url(#filter0_d_178_3139)">
      <path
        d="M25.1366 16.0966C25.1366 15.033 24.9409 13.9827 24.5605 13C24.1802 12.0173 23.6227 11.1244 22.9199 10.3723C22.2171 9.6202 21.3828 9.02359 20.4646 8.61655C19.5464 8.2095 18.5622 8 17.5683 8C16.5744 8 15.5903 8.2095 14.672 8.61655C13.7538 9.02359 12.9195 9.6202 12.2167 10.3723C11.5139 11.1244 10.9564 12.0173 10.5761 13C10.1958 13.9827 10 15.033 10 16.0966L13.7842 16.0966C13.7842 15.5648 13.882 15.0412 14.0722 14.5498C14.2624 14.0585 14.5411 13.612 14.8925 13.236C15.2439 12.8599 15.6611 12.5616 16.1202 12.3581C16.5793 12.1546 17.0714 12.0498 17.5683 12.0498C18.0653 12.0498 18.5573 12.1546 19.0164 12.3581C19.4756 12.5616 19.8927 12.8599 20.2441 13.236C20.5955 13.612 20.8742 14.0585 21.0644 14.5498C21.2546 15.0412 21.3525 15.5648 21.3525 16.0966H25.1366Z"
        fill="white"
      />
      <path
        d="M21.3609 19.9029C21.3609 20.9666 21.5567 22.0168 21.937 22.9995C22.3174 23.9822 22.8749 24.8751 23.5776 25.6272C24.2804 26.3793 25.1147 26.9759 26.033 27.383C26.9512 27.79 27.9354 27.9995 28.9292 27.9995C29.9231 27.9995 30.9073 27.79 31.8255 27.383C32.7437 26.9759 33.5781 26.3793 34.2809 25.6272C34.9836 24.8751 35.5411 23.9822 35.9215 22.9995C36.3018 22.0168 36.4976 20.9666 36.4976 19.9029L32.7134 19.9029C32.7134 20.4347 32.6155 20.9584 32.4254 21.4497C32.2352 21.941 31.9564 22.3875 31.605 22.7636C31.2537 23.1396 30.8365 23.4379 30.3774 23.6414C29.9183 23.845 29.4262 23.9497 28.9292 23.9497C28.4323 23.9497 27.9402 23.845 27.4811 23.6414C27.022 23.4379 26.6048 23.1396 26.2534 22.7636C25.902 22.3875 25.6233 21.941 25.4331 21.4497C25.243 20.9584 25.1451 20.4347 25.1451 19.9029L21.3609 19.9029Z"
        fill="white"
      />
      <path
        d="M47.8398 16.0966C47.8398 15.033 47.644 13.9827 47.2637 13C46.8833 12.0173 46.3258 11.1244 45.623 10.3723C44.9203 9.6202 44.0859 9.02359 43.1677 8.61655C42.2495 8.2095 41.2653 8 40.2714 8C39.2776 8 38.2934 8.2095 37.3752 8.61655C36.4569 9.02359 35.6226 9.6202 34.9198 10.3723C34.2171 11.1244 33.6596 12.0173 33.2792 13C32.8989 13.9827 32.7031 15.033 32.7031 16.0966L36.4873 16.0966C36.4873 15.5648 36.5852 15.0412 36.7753 14.5498C36.9655 14.0585 37.2442 13.612 37.5956 13.236C37.947 12.8599 38.3642 12.5616 38.8233 12.3581C39.2824 12.1546 39.7745 12.0498 40.2714 12.0498C40.7684 12.0498 41.2605 12.1546 41.7196 12.3581C42.1787 12.5616 42.5959 12.8599 42.9472 13.236C43.2986 13.612 43.5774 14.0585 43.7675 14.5498C43.9577 15.0412 44.0556 15.5648 44.0556 16.0966H47.8398Z"
        fill="white"
      />
      <path
        d="M86.7085 21.0401C86.7085 22.9961 86.0393 24.6492 84.7008 25.9994C83.3795 27.3322 81.7493 27.9987 79.8103 27.9987C77.8884 27.9987 76.2582 27.3236 74.9198 25.9734C73.5813 24.6232 72.9121 22.9788 72.9121 21.0401V14.1074H76.3869L76.4127 21.0401C76.4127 21.9921 76.7387 22.8057 77.3908 23.4808C78.06 24.1559 78.8665 24.4934 79.8103 24.4934C80.7541 24.4934 81.5606 24.1559 82.2298 23.4808C82.899 22.8057 83.2337 21.9921 83.2337 21.0401V14.1074H86.7085V21.0401Z"
        fill="white"
      />
      <path
        d="M56.9048 21.0669C56.9048 19.1108 57.574 17.4577 58.9125 16.1076C60.2338 14.7747 61.8639 14.1083 63.803 14.1083C65.7249 14.1083 67.355 14.7834 68.6935 16.1335C70.0319 17.4837 70.7012 19.1282 70.7012 21.0669L70.7012 27.9995L67.2263 27.9995L67.2006 21.0669C67.2006 20.1148 66.8746 19.3013 66.2225 18.6262C65.5533 17.9511 64.7468 17.6135 63.803 17.6135C62.8592 17.6135 62.0527 17.9511 61.3835 18.6262C60.7142 19.3013 60.3796 20.1148 60.3796 21.0669L60.3796 27.9995L56.9048 27.9995L56.9048 21.0669Z"
        fill="white"
      />
      <path
        d="M118.715 21.0401C118.715 22.9788 118.045 24.6232 116.704 25.9734C115.38 27.3236 113.756 27.9987 111.83 27.9987C109.887 27.9987 108.246 27.3322 106.905 25.9994C105.581 24.6492 104.919 22.9961 104.919 21.0401C104.919 19.1013 105.589 17.4656 106.93 16.1327C108.271 14.7825 109.905 14.1074 111.83 14.1074C113.756 14.1074 115.38 14.7825 116.704 16.1327C118.045 17.4656 118.715 19.1013 118.715 21.0401ZM115.234 21.0401C115.234 20.1053 114.899 19.3004 114.228 18.6253C113.558 17.9502 112.758 17.6127 111.83 17.6127C110.884 17.6127 110.076 17.9502 109.406 18.6253C108.736 19.2831 108.4 20.088 108.4 21.0401C108.4 21.9921 108.736 22.8057 109.406 23.4808C110.076 24.1559 110.884 24.4934 111.83 24.4934C112.776 24.4934 113.575 24.1559 114.228 23.4808C114.899 22.8057 115.234 21.9921 115.234 21.0401Z"
        fill="white"
      />
      <path
        d="M130.574 23.735C130.574 24.5766 130.321 25.3393 129.815 26.0232C128.803 27.3908 127.363 28.0483 125.497 27.9957C124.753 27.9782 123.938 27.7941 123.053 27.4434C122.183 27.0927 121.471 26.6544 120.917 26.1284L122.697 23.5772C123.551 24.4714 124.46 24.9185 125.425 24.9185H125.497C125.892 24.9185 126.248 24.8396 126.564 24.6818C126.976 24.4714 127.181 24.1733 127.181 23.7876V23.6824C127.134 23.2791 126.889 22.9898 126.446 22.8144C126.272 22.7794 125.9 22.7005 125.33 22.5777C124.619 22.4199 124.018 22.2271 123.527 21.9991C122.088 21.3153 121.368 20.1142 121.368 18.3959C121.368 16.7477 122.103 15.5115 123.575 14.6874C124.223 14.3192 124.927 14.1263 125.686 14.1088C126.477 14.0913 127.3 14.2403 128.154 14.5559C129.135 14.9241 129.831 15.4063 130.242 16.0025L128.107 18.1329C127.553 17.5192 126.952 17.2123 126.303 17.2123C125.291 17.2123 124.785 17.5805 124.785 18.317V18.3696C124.785 18.7203 125.196 19.0183 126.019 19.2638C126.082 19.2813 126.604 19.3953 127.585 19.6057C129.578 20.0265 130.574 21.3767 130.574 23.6561V23.735Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.712 8H99.1248V14.9195C98.1468 14.3781 97.0482 14.1075 95.8291 14.1075C93.9036 14.1075 92.2704 14.7826 90.9294 16.1327C89.5884 17.4656 88.918 19.1014 88.918 21.0401C88.918 22.9961 89.5799 24.6492 90.9036 25.9994C92.2446 27.3323 93.8864 27.9987 95.8291 27.9987C97.7545 27.9987 99.3792 27.3236 100.703 25.9735C102.044 24.6233 102.714 22.9788 102.714 21.0401C102.714 20.9712 102.714 20.9027 102.712 20.8346V8ZM98.2273 18.6254C98.8978 19.3005 99.233 20.1054 99.233 21.0401C99.233 21.9922 98.8978 22.8057 98.2273 23.4808C97.574 24.1559 96.7746 24.4935 95.8291 24.4935C94.8835 24.4935 94.0755 24.1559 93.405 23.4808C92.7345 22.8057 92.3993 21.9922 92.3993 21.0401C92.3993 20.0881 92.7345 19.2832 93.405 18.6254C94.0755 17.9503 94.8835 17.6127 95.8291 17.6127C96.7574 17.6127 97.5568 17.9503 98.2273 18.6254Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_178_3139"
        x="0.194264"
        y="0.645698"
        width="140.186"
        height="39.611"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.45143" />
        <feGaussianBlur stdDeviation="4.90287" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.701961 0 0 0 0 0.701961 0 0 0 0 0.701961 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_178_3139"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_178_3139"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
