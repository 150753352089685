import { useCallback, useEffect, useRef } from "react";
import { ImodalOptions } from "../../state/global.state";

const NudosModal = ({
  modalContent,
  isModalOpen,
  setIsModalOpen,
  closesOnClickOutside,
  closeModalCallback,
  clickOutsideCallback,
  shouldCloseModal,
}: ImodalOptions) => {
  const modalContentRef = useRef<HTMLDivElement>(null);
  const onHandleClickAway = useCallback(
    (e: any) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(e.target)
      ) {
        clickOutsideCallback && clickOutsideCallback();
        closesOnClickOutside && setIsModalOpen(false);
      }
    },
    [
      modalContentRef,
      clickOutsideCallback,
      closesOnClickOutside,
      setIsModalOpen,
    ]
  );
  useEffect(() => {
    window.addEventListener("mousedown", onHandleClickAway);
    return () => {
      window.removeEventListener("mousedown", onHandleClickAway);
    };
  }, [onHandleClickAway]);
  useEffect(() => {
    if (shouldCloseModal) {
      closeModalCallback && closeModalCallback();
      setIsModalOpen(false);
    }
  }, [shouldCloseModal, closeModalCallback, setIsModalOpen]);

  return (
    <>
      {isModalOpen && (
        <div id="nudosModal" className={`modal ${isModalOpen ? "" : "closed"}`}>
          <div ref={modalContentRef} className="modal-content">
            {modalContent}
          </div>
        </div>
      )}
    </>
  );
};

export default NudosModal;
