import { createContext } from 'react';

type tSide = 'landing' | 'store';

declare global { interface Window { analytics: any; } }


export const SideContext = createContext({
  side: 'store',
  setSide: (side: tSide) => {},
});
