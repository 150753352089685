import "./BlogListingSkeleton.scss";

const BlogListingSkeleton = () => {
  return (
    <div className="blogListingSkeleton">
      <div className="mainEntryContainer">
        <BlogListMainSkeletonCard />
      </div>
      {
        <div className="blogListContainer">
          {[0, 1, 2, 3, 4, 5].map((i) => {
            return <BlogListSecondarySkeletonCard key={`skeleton-card-${i}`} />;
          })}
        </div>
      }
    </div>
  );
};

export default BlogListingSkeleton;

const BlogListMainSkeletonCard = () => {
  return (
    <div className="blogListMainCard">
      <section className="leftSection" />
      <section className="rightSection">
        <div className="category" />
        <div className="postTitle" />
        <div className="description" />
        <div className="author">
          <div className="authorPhoto" />
          <div className="authorNameAndRole">
            <div className="authorName" />
            <div className="authorRole" />
          </div>
        </div>
      </section>
    </div>
  );
};

const BlogListSecondarySkeletonCard = () => {
  return (
    <div className="blogListSecondaryCard">
      <section className="topSection" />
      <section className="bottomSection">
        <div className="categoryAndTime">
          <div className="category" />
          <div className="readingTime" />
        </div>
        <div className="postTitle" />
        <div className="description" />
      </section>
    </div>
  );
};
