import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LogoHeaderD from "../../assets/images/header/LogoHeaderD";
import SimpleLogoNudos from "../../assets/images/header/simpleLogoNudos";
import usePlatform from "../../hooks/usePlatform";
import useSignStore from "../../sections/customFooter/state";
import { segmentEvent } from "../../utils/segment";
import { toEcommerce } from "../../utils/toEcommerce";
import FloatButton from "../floatButton";
import "./menu.scss";
import IconMenu from "../../assets/images/header/iconMenu";
import CloseMenu from "../../assets/images/header/closeMenu";
import EsIcon from "../../assets/images/header/esIcon";
import EnIcon from "../../assets/images/header/enIcon";
import { useTranslation } from "react-i18next";
import { getDefaultLanguage } from "../../utils/getDefaultLanguage";

const CustomHeader = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { isMobile, isTablet } = usePlatform();
  const { existEmail } = useSignStore();
  const myLanguage = getDefaultLanguage();
  const isTabletOrMobile = isMobile || isTablet;

  const myLocalLanguage = getDefaultLanguage();
  const [showFloat, setShowFloat] = useState<boolean>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const sendEvent = (name: string) => {
    segmentEvent({ event: name });
  };

  const goEcommerce = () => {
    sendEvent("Landing_Header_Login_Click");
    toEcommerce();
  };

  const navigationLanding = (route: string) => {
    push(route);
  };

  const homeClick = () => {
    navigationLanding("/home");
    sendEvent("Header_Nudos_Click");
  };

  const changeStatusButton = () => {
    setShowFloat(!showFloat);
  };

  const verifyState = () => {
    if (sessionStorage.getItem("existEmail")) {
      setShowFloat(false);
    }
  };

  const enLanguage = () => {
    localStorage.setItem("language", JSON.stringify({ languageCode: "en-US" }));
    window.location.reload();
  };

  const esLanguage = () => {
    localStorage.setItem("language", JSON.stringify({ languageCode: "es-MX" }));
    window.location.reload();
  };

  useEffect(() => {
    verifyState();
  }, []);

  return (
    <>
      {isTabletOrMobile ? (
        <div id="customHeaderMobile">
          {openMenu && (
            <div id="menu">
              <div className="headerMenu">
                <div
                  className="floatCloseMenu"
                  onClick={() => {
                    window.location.href = "/home";
                  }}
                />
                <CloseMenu
                  onClick={() => {
                    setOpenMenu(!openMenu);
                  }}
                />
              </div>
              <div className="bodyMenu">
                <div className="itemMenu">
                  <div
                    className={`boxMenu ${
                      pathname === "/nodi-platform" ? "selectBoxMenu" : ""
                    } `}
                    onClick={() => {
                      sendEvent("Landing_Header_Platform_Click");
                      window.location.href = "/nodi-platform";
                    }}
                  >
                    {t("header:menu:platform")}
                  </div>
                </div>
                <div
                  className="itemMenu"
                  onClick={() => {
                    sendEvent("Landing_Header_Equipment_Click");
                    window.location.href = "/equipament";
                  }}
                >
                  <div
                    className={`boxMenu ${
                      pathname === "/equipament" ? "selectBoxMenu" : ""
                    } `}
                  >
                    {t("header:menu:equipament")}
                  </div>
                </div>
                {myLanguage === "es-MX" && (
                  <div
                    className="itemMenu"
                    onClick={() => {
                      sendEvent("Landing_Header_Blog_Click");
                      window.location.href = "/blog";
                    }}
                  >
                    <div
                      className={`boxMenu ${
                        pathname === "/blog" ? "selectBoxMenu" : ""
                      } `}
                    >
                      {t("header:menu:blog")}
                    </div>
                  </div>
                )}
                <div className="containerMenuButtons">
                  <button
                    className="buttonNodi"
                    onClick={() => {
                      sendEvent("Landing_Header_Login_Click");
                      setOpenMenu(false);
                      push("/warning-page");
                    }}
                  >
                    {myLanguage === "en-US" ? (
                      <strong>{t("header:menu:nodi")}&nbsp;</strong>
                    ) : (
                      t("header:menu:entry")
                    )}
                    {myLanguage === "en-US" ? (
                      t("header:menu:entry")
                    ) : (
                      <strong> {t("header:menu:nodi")}</strong>
                    )}
                  </button>
                  <div className="containerButtosLanguage">
                    <div
                      className={
                        myLanguage === "en-US"
                          ? "alertativeEsButton"
                          : "espButton"
                      }
                      onClick={() => {
                        esLanguage();
                      }}
                    >
                      <EsIcon
                        fill={myLocalLanguage !== "es-MX" ? "#E46D57" : ""}
                      />
                    </div>
                    <div
                      className={
                        myLanguage === "es-MX"
                          ? "engButton"
                          : "alernativeEnButton"
                      }
                      onClick={() => {
                        enLanguage();
                      }}
                    >
                      <EnIcon
                        fill={myLocalLanguage === "en-US" ? "white" : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="spaceHeader" />
          <div className="floadMobileHeader">
            <div className="containerMenu">
              {pathname === "/home" && (
                <>
                  {showFloat && !existEmail && (
                    <div className="containerFloatButton">
                      <FloatButton changeButton={changeStatusButton} />
                    </div>
                  )}
                </>
              )}
              <div className="containerIcon">
                <SimpleLogoNudos
                  onClick={() => {
                    homeClick();
                  }}
                />
              </div>
              <div className="containerItemsMenu">
                {!openMenu && (
                  <IconMenu
                    onClick={() => {
                      setOpenMenu(!openMenu);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="customHeader">
          <div className="spaceHeader" />
          <div className="floatHeader">
            <div className="containerHeader">
              <div className="centerHeader">
                {pathname === "/home" && (
                  <>
                    {showFloat && !existEmail && (
                      <div className="containerFloatButton">
                        <FloatButton changeButton={changeStatusButton} />
                      </div>
                    )}
                  </>
                )}
                <div className="iconHeader">
                  <LogoHeaderD
                    onClick={() => {
                      homeClick();
                    }}
                  />
                </div>
                <div className="containerMenu">
                  <div className="containerSimpleItems">
                    <div className="boxItems">
                      <div
                        id="servicesHeader"
                        className={`simpleItem ${
                          pathname === "/nodi-platform"
                            ? "selectSimpleItem"
                            : ""
                        }`}
                        onClick={() => {
                          sendEvent("Landing_Header_Platform_Click");
                          navigationLanding("/nodi-platform");
                        }}
                      >
                        {t("header:menu:platform")}
                      </div>
                      <div
                        className={`simpleItem spaceItem ${
                          pathname === "/equipament" ? "selectSimpleItem" : ""
                        }`}
                        onClick={() => {
                          sendEvent("Landing_Header_Equipment_Click");
                          navigationLanding("/equipament");
                        }}
                      >
                        {t("header:menu:equipament")}
                      </div>
                      {myLanguage === "es-MX" && (
                        <div
                          className={`simpleItem spaceItem ${
                            pathname.startsWith("/blog")
                              ? "selectSimpleItem"
                              : ""
                          }`}
                          onClick={() => {
                            sendEvent("Landing_Header_Blog_Click");
                            navigationLanding("/blog");
                          }}
                        >
                          {t("header:menu:blog")}
                        </div>
                      )}
                    </div>
                    <div className="languageButtonContainer">
                      <div
                        className={
                          myLocalLanguage === "es-MX"
                            ? "esButton"
                            : "esButtonAlternative"
                        }
                        onClick={() => {
                          esLanguage();
                        }}
                      >
                        <EsIcon
                          fill={myLocalLanguage !== "es-MX" ? "#E46D57" : ""}
                        />
                      </div>
                      <div
                        className={"enButton"}
                        style={{
                          backgroundColor:
                            myLocalLanguage === "en-US" ? "#E46D57" : "",
                        }}
                        onClick={() => {
                          enLanguage();
                        }}
                      >
                        <EnIcon
                          fill={myLocalLanguage === "en-US" ? "white" : ""}
                        />
                      </div>
                    </div>
                    <div className="containerHeaderButton">
                      <button className="buttonHeader" onClick={goEcommerce}>
                        <div className="textButtonHeader">
                          {myLanguage === "en-US" ? (
                            <strong>{t("header:menu:nodi")}&nbsp;</strong>
                          ) : (
                            t("header:menu:entry")
                          )}
                          {myLanguage === "en-US" ? (
                            t("header:menu:entry")
                          ) : (
                            <strong> {t("header:menu:nodi")}</strong>
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomHeader;
