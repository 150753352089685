import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  FloatingPromotionButton,
  NudosShareOnSocialMedia,
} from "../../../components";
import CenterSections from "../../../components/centerSections";
import usePlatform from "../../../hooks/usePlatform";
import { getBlogEntry } from "../../../services/blog";
import { IblogEntryDetailedData } from "../../../types/blog";
import { formatShortDate } from "../../../utils/formatDate";
import { landingPageUrlByEnvironment } from "../../../utils/urlsByEnvironment";
import BlogEntrySkeleton from "../BlogEntrySkeleton";
import "./BlogEntry.scss";
import { displayErrorNotification } from "../../../utils/displayNudosStandardNotifications";

const BlogEntry = () => {
  const { t } = useTranslation();
  const { id: postId } = useParams<{ id: string }>();
  const { isMobile } = usePlatform();
  const [loading, setLoading] = useState(false);
  const [blogEntryData, setBlogEntryData] = useState<IblogEntryDetailedData>();
  const shareUrl = `${landingPageUrlByEnvironment()}/blog/entry/${postId}`;

  const getBlogPostData = async () => {
    setLoading(true);
    try {
      const response = await getBlogEntry(postId);
      if (response) {
        setBlogEntryData(response);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!postId) return;
    getBlogPostData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CenterSections customClass="blogEntryCenterSections">
        <main className="blogEntry">
          {(loading || !blogEntryData) && <BlogEntrySkeleton />}
          {blogEntryData && !loading && (
            <>
              <section className="entryHeader">
                {isMobile && (
                  <div
                    className="entryImageMobile"
                    style={{ backgroundImage: `url(${blogEntryData?.image})` }}
                  />
                )}
                <div className="categoryAndDate">
                  <div className="category">
                    {blogEntryData?.categoryName?.toUpperCase()}
                  </div>
                  <div className="date">
                    {blogEntryData?.updatedAt &&
                      formatShortDate(blogEntryData.updatedAt)}
                  </div>
                </div>
                {!isMobile && (
                  <div
                    className="entryImage"
                    style={{ backgroundImage: `url(${blogEntryData?.image})` }}
                  />
                )}
                {!isMobile && (
                  <div className="authorAndShare">
                    <div className="author">
                      <div
                        className="authorPhoto"
                        style={{
                          backgroundImage: `url(${blogEntryData?.authorImage})`,
                        }}
                      />
                      <div className="authorNameAndRole">
                        <div className="authorName">
                          {blogEntryData?.authorName}
                        </div>
                        <div className="authorRole">
                          {blogEntryData?.authorRole}
                        </div>
                      </div>
                    </div>
                    <NudosShareOnSocialMedia shareUrl={shareUrl} />
                  </div>
                )}
              </section>
              <section className="entryBody">
                <div className="entryTitle">{blogEntryData?.postTitle}</div>
                {blogEntryData?.postBody && (
                  <div
                    className="bodyContent"
                    dangerouslySetInnerHTML={{ __html: blogEntryData.postBody }}
                  />
                )}
                {isMobile && (
                  <>
                    <div className="author">
                      <div
                        className="authorPhoto"
                        style={{
                          backgroundImage: `url(${blogEntryData?.authorImage})`,
                        }}
                      />
                      <div className="authorNameAndRole">
                        <div className="authorName">
                          {blogEntryData?.authorName}
                        </div>
                        <div className="authorRole">
                          {blogEntryData?.authorRole}
                        </div>
                      </div>
                    </div>
                    <div className="shareContainer">
                      <NudosShareOnSocialMedia
                        shareUrl={shareUrl}
                        iconSize={24}
                      />
                    </div>
                  </>
                )}
              </section>
            </>
          )}
        </main>
      </CenterSections>
      <FloatingPromotionButton />
    </>
  );
};

export default BlogEntry;
