import { useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import "./NudosTextInput.scss";

/**
 * React functional component corresponding to the Nudos text input
 * @property {string} placeholder - Required
 * @property {boolean} isFilled - Required, boolean indicating if the input is filled. Should be passed to change the style from empty to filled. Its value should reflect the change in the input value. Default is false, unless it has a default value, in which case is true.
 * @property {string} label - Optional
 * @property {string} errorText - Optional, the text to be shown if theres an error with the input form. If an error is passed the style of the component will change to reflect that.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "extraSmall", "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {string} defaultValue - Optional.
 * @property {boolean} isDeactivated - Optional, boolean indicating if the input is deactivated. If a value is provided the input will behave as a read only field and styles will be accordingly.
 * @property {string} autoComplete - Optional, a valid value for the autocomplete attribute. Default is "off".
 * @property {string} registerId - Optional, an Id to register the value of the input. To be used if form employs react-hook-form.
 * @property {UseFormRegister<Tany>} register - Optional, the register function of react-hook-form
 * @property {string} customClassName - Optional, a className to customize styles
 * @property {boolean} hideErrorText - Optional, if the dropdown is part of another component that shows an error text, used this boolean to prevent from repeating the error text.
 * @property {funcion(inputText: string): void} handleChange - Optional, a callback to execute upon changing the input text
 * @property {funcion(): void} handleBlur - Optional, a callback to pass to the onBlur event for the input component
 */
const NudosTextInput = ({
  placeholder,
  isFilled,
  label,
  errorText,
  componentSize,
  isDeactivated,
  defaultValue,
  autoComplete,
  register,
  registerId,
  customClassName,
  hideErrorText,
  handleChange,
  handleBlur,
  inputHeight,
  inputPadding,
}: InudosTextInput) => {
  const [currentText, setCurrentText] = useState<string>();

  const errorStyles = errorText && errorText.length ? "error" : "";
  const filledStyles =
    (isFilled ||
      (defaultValue && defaultValue.length > 0) ||
      (currentText && currentText.length > 0)) &&
    !isDeactivated
      ? "filled"
      : "";
  const deactivatedStyles = isDeactivated ? "deactivated" : "";
  const handleInputChange = (event: React.SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setCurrentText(value);
    handleChange && handleChange(value);
  };

  useEffect(() => {
    setCurrentText(defaultValue);
  }, [defaultValue]);
  return (
    <div
      className={`nudosTextInput ${errorStyles} ${filledStyles} ${deactivatedStyles} ${
        componentSize ? componentSize : ""
      } ${customClassName ? customClassName : ""}`}
      onBlur={handleBlur ? () => handleBlur(currentText || "") : undefined}
    >
      {label && <label>{label}</label>}
      {registerId && register && (
        <input
          placeholder={placeholder}
          readOnly={isDeactivated}
          defaultValue={defaultValue}
          autoComplete={autoComplete ? autoComplete : "off"}
          {...register(registerId)}
        />
      )}
      {(!registerId || !register) && (
        <input
          style={{
            height: inputHeight ? `${inputHeight}px` : "",
            paddingRight: inputPadding ? `${inputPadding}px` : "",
            paddingLeft: inputPadding ? `${inputPadding}px` : "",
          }}
          placeholder={placeholder}
          readOnly={isDeactivated}
          autoComplete={autoComplete ? autoComplete : "off"}
          onChange={handleInputChange}
          value={currentText || ""}
        />
      )}
      {errorText && !hideErrorText && errorText.length > 0 && (
        <div className="errorText">{errorText}</div>
      )}
    </div>
  );
};

export default NudosTextInput;

export interface InudosTextInput {
  placeholder: string;
  isFilled: boolean;
  label?: string;
  errorText?: string;
  componentSize?: string;
  defaultValue?: string;
  isDeactivated?: boolean;
  autoComplete?: string;
  registerId?: string;
  register?: UseFormRegister<any>;
  customClassName?: string;
  hideErrorText?: boolean;
  handleChange?: (inputText: string) => void;
  handleBlur?: (inputText: string) => void;
  inputHeight?: number;
  inputPadding?: number;
}
