import React, { useEffect } from "react";
import { segmentEvent } from "../../utils/segment";
import { FloatingPromotionButton } from "../../components";
import EquipmentSecond from "./EquipmentSecond";
import EquipmentThird from "./EquipmentThird";
import EquipmentFirst from "./EquipmentFirst/EquipmentFirst";

const EquipamentLanding = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    segmentEvent({ event: "Landing_Equipment_View" });
  }, []);
  return (
    <div id="equipmentLanding">
      <EquipmentFirst />
      <EquipmentSecond />
      <EquipmentThird />
      <FloatingPromotionButton />
    </div>
  );
};

export default EquipamentLanding;
