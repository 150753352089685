import React from "react";
import Loading from "../../loading/Loading";
import "./NudosButton.scss";

/**
 * React functional component corresponding to Nudos primary/secondary button
 * @property {string} buttonText - Required, he text displayed on the button.
 * @property {() => void} handleClick - Optional, the callback action to execute upon clicking the button, should only be optional if the property isOfTypeSubmit is passed.
 * @property {boolean} isOfTypeSubmit - Optional, a boolean indicating if the button acts as submitter of a form.
 * @property {string} formId - Optional, the id of the corresponding form, if the button is of type submit.
 * @property {string} colorPalette - One of the defined color palettes, corresponding to one of the folowing words: "primary", "secondary", "blackContent", "blackBorder". Default value is "primary".
 * @property {boolean} isButtonDisabled - Boolean indicading if the button is disabled (true) or active (false). Default value is false.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "extraSmall", "small", "medium", "large". If no value is passed the width of the component corresponds to that of the parent component.
 * @property {string} customClassName - Optional, a custom className to personalized the component.
 * @property {JSX.Element | string} buttonIcon - Optional, the icon adjacent to the text.
 * @property {boolean} isButtonLoading - Optional, boolean indicating if the button is waiting for some action to complete. If true, button is disabled. Default value is false.
 * @property {JSX.Element} customLoader - Optional, custom loader to be displayed when button is loading
 */
const NudosButton = ({
  buttonText,
  handleClick,
  isOfTypeSubmit,
  formId,
  colorPalette,
  isButtonDisabled,
  componentSize,
  customClassName,
  buttonIcon,
  isButtonLoading,
  customLoader,
}: InudosButton): JSX.Element => {
  return (
    <button
      className={`nudosButton ${customClassName ? customClassName : ""} ${
        componentSize ? componentSize : ""
      } ${colorPalette ? colorPalette : ""}`}
      disabled={isButtonDisabled || isButtonLoading ? true : false}
      onClick={handleClick}
      type={isOfTypeSubmit ? "submit" : undefined}
      form={formId}
    >
      {isButtonLoading ? (
        customLoader ? (
          { customLoader }
        ) : (
          <Loading />
        )
      ) : (
        <>
          <div className="textContainer">{buttonText}</div>
          {buttonIcon && (
            <div className="iconContainer">
              {typeof buttonIcon === "string" && (
                <img src={buttonIcon} alt="Button icon" />
              )}
              {React.isValidElement(buttonIcon) && <>{buttonIcon}</>}
            </div>
          )}
        </>
      )}
    </button>
  );
};

export default NudosButton;

export interface InudosButton {
  buttonText: string;
  handleClick?: () => void;
  isOfTypeSubmit?: boolean;
  formId?: string;
  colorPalette?: TnudosButtonColorPalette;
  isButtonDisabled?: boolean;
  componentSize?: string;
  customClassName?: string;
  buttonIcon?: string | JSX.Element;
  isButtonLoading?: boolean;
  customLoader?: JSX.Element;
}

export type TnudosButtonColorPalette =
  | "primary"
  | "secondary"
  | "blackContent"
  | "blackBorder";
