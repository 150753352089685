import { useTranslation } from "react-i18next";
import { CurvyArrowUnderlineOrange } from "../../../assets/images/home/general";
import {
  desktopConnectingPath,
  downloadIcon,
  mobileConnectingPath,
  shoppingCartIcon,
  curvyUnderlinedArrowHome7Mobile,
} from "../../../assets/images/home/HomeSeventh";
import CenterSections from "../../../components/centerSections";
import usePlatform from "../../../hooks/usePlatform";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";
import "./HomeSeventh.scss";

const HomeSeventh = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = usePlatform();
  const appLanguage = getDefaultLanguage();

  const englishStyles = appLanguage === "en-US" ? "english" : "";
  const isTabletOrMobile = isMobile || isTablet;

  const underlineArrow = isTabletOrMobile
    ? curvyUnderlinedArrowHome7Mobile
    : CurvyArrowUnderlineOrange;

  const informationBoxes = [
    {
      key: "informationBox1",
      title: t("home:seventhSection:informationBox1:title"),
      contentText: t("home:seventhSection:informationBox1:contentText"),
      icon: shoppingCartIcon,
      altText: t("home:seventhSection:informationBox1:altText"),
    },
    {
      key: "informationBox2",
      title: t("home:seventhSection:informationBox2:title"),
      contentText: t("home:seventhSection:informationBox2:contentText"),
      icon: downloadIcon,
      altText: t("home:seventhSection:informationBox2:altText"),
    },
  ];
  return (
    <>
      <CenterSections customClass="homeSeventhCenterSections">
        <div className="homeSeventh">
          <img
            className="connectingPath"
            src={
              isTabletOrMobile ? mobileConnectingPath : desktopConnectingPath
            }
            alt="Conector"
          />
          <div className={`titleContainer ${englishStyles}`}>
            {!isMobile && (
              <h1 className="blackText blackText1">
                {t("home:seventhSection:titleBlackText1")}
              </h1>
            )}
            {!isMobile && (
              <h1 className="blackText blackText1">
                {t("home:seventhSection:titleBlackText2")}
              </h1>
            )}
            {isMobile && (
              <h1 className="blackText mobileBlackText">
                {t("home:seventhSection:mobileBlackText")}
              </h1>
            )}
            <h1 className="orangeText">
              {t("home:seventhSection:titleOrangeText")}
              <img
                className="curvyArrowUnderline"
                src={underlineArrow}
                alt="Subrayado flecha curva"
              />
            </h1>
          </div>
          <div className="informationBoxesContainer">
            {informationBoxes.map((informationBox) => {
              return (
                <div
                  key={informationBox.key}
                  className={`informationBox ${informationBox.key}`}
                >
                  <div className="iconContainer">
                    <img
                      className="icon"
                      src={informationBox.icon}
                      alt={informationBox.altText}
                    />
                  </div>
                  <div className="textContainer">
                    <h3 className="boxTitle">{informationBox.title}</h3>
                    <div className="boxText">{informationBox.contentText}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CenterSections>
    </>
  );
};
export default HomeSeventh;
