const isEmailValid = (email: string) => {
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return regex.test(email);
};

const isPasswordValid = (password: string) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.-=])([A-Za-z\d$@$!%*?&.-=]|[^ ]){8,15}$/;
  return regex.test(password);
};

const isRFCValid = (RFC: string) => {
  const regex = /^[ña-z]{3,4}[0-9]{6}[0-9a-z]{3,4}$/i;
  return regex.test(RFC);
};

const isPhoneValid = (phone: string) => {
  if (!phone) return false;
  const regex = /^[0-9]{6,13}$/;
  return regex.test(phone);
};
export { isEmailValid, isPasswordValid, isRFCValid, isPhoneValid };
