import NudosLogoDesktop from "../../assets/images/footer/nudos-logo";
import LinkedIn from "../../assets/images/footer/LinkedIn";
import Instagram from "../../assets/images/footer/Logoinstagram";
import usePlatform from "../../hooks/usePlatform";
import ArrowIcon from "../../assets/images/home/arrow";
import { useState } from "react";
import useSignStore from "./state";
import { registerEmail } from "../../services/services";
import { segmentEvent } from "../../utils/segment";
import { Trans, useTranslation } from "react-i18next";
/* @ts-ignore */
import terms from "./../../assets/documents/terms.pdf";
/* @ts-ignore */
import privacy from "./../../assets/documents/privacy.pdf";
import {
  displayErrorNotification,
  displaySuccessNotification,
} from "../../utils/displayNudosStandardNotifications";

const CustomFooter = () => {
  const { isMobile } = usePlatform();
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { setExistEmail } = useSignStore();
  const { t } = useTranslation();

  const openSocial = (url: string) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const handleEmail = (value: string) => {
    setEmail(value);
  };

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const sendEmail = async () => {
    const isEmail = validateEmail(email);
    setLoading(true);
    if (email && isEmail) {
      try {
        await registerEmail(email);
        setLoading(false);
        setExistEmail(true);
        setEmail("");
        segmentEvent({ event: "Landing_Footer_Newsletter_Click" });
        displaySuccessNotification({
          customJSXMessage: <>{t("alerts:subscription")}</>,
        });
      } catch (error) {
        setLoading(false);
        displayErrorNotification();
      }
    }
  };

  const openTerms = () => {
    window.open(terms, "_blank");
  };

  const sendPriv = () => {
    window.open(privacy, "_blank");
  };

  return (
    <>
      {isMobile ? (
        <div id="customFooterMobile">
          <div className="logo">
            <NudosLogoDesktop className="m-auto block" />
          </div>
          <div className="centerText">
            <div className="title">{t("footer:title")}</div>
            <div className="subtitle">
              <span
                className="cursor-pointer"
                onClick={() => {
                  openTerms();
                }}
              >
                {t("footer:terms")}
              </span>
              <br />
              <span
                onClick={() => {
                  sendPriv();
                }}
              >
                {" "}
                {t("footer:policies")}
              </span>
            </div>
            <br />
          </div>
          <div className="centerText mb-14">
            <a href="mailto: principal@nudos.com" className="title">
              principal@somosnudos.com
            </a>
            <div className="subtitle">+52 55 1198 1542</div>
          </div>
          <div className="centerText">
            <div className="subtitle mb-4">
              <Trans
                i18nKey={"footer:subscriptionInfo"}
                components={{ 1: <></> }}
              />
            </div>
            <div className="containerInput">
              <input
                placeholder="Email"
                className="sizeInput"
                value={email}
                onChange={(e) => {
                  handleEmail(e.target.value);
                }}
              />
              <div className="emailIcon" onClick={sendEmail}>
                <ArrowIcon
                  stroke={email && validateEmail(email) ? "#F27058" : ""}
                  className="positionIcon"
                />
              </div>
            </div>
          </div>
          <div className="socialIcons">
            <Instagram
              onClick={() => {
                openSocial(
                  "https://instagram.com/nudoslatam?igshid=YmMyMTA2M2Y="
                );
              }}
            />{" "}
            <div className="extraSpace" />
            <LinkedIn
              onClick={() => {
                openSocial("https://www.linkedin.com/company/nudosplatform");
              }}
            />{" "}
          </div>
        </div>
      ) : (
        <div className="footerGeneralContainer">
          <div id="customFooter">
            <div className="contianerFooter">
              <div className="sizeLogo">
                <NudosLogoDesktop />
              </div>
              <div className="containerInfo">
                <div className="infoBox">
                  <div className="centerItems">
                    <div>
                      <div className="boldText">{t("footer:title")}</div>
                      <div
                        className="simpleText cursor-pointer"
                        onClick={() => {
                          openTerms();
                        }}
                      >
                        {t("footer:terms")}
                      </div>
                      <div
                        className="simpleText cursor-pointer"
                        onClick={() => {
                          sendPriv();
                        }}
                      >
                        {t("footer:policies")}
                      </div>
                    </div>
                  </div>
                  <div className="centerItems">
                    <div>
                      <a
                        className="boldText"
                        href="mailto: principal@nudos.com"
                      >
                        principal@somosnudos.com
                      </a>
                      <div className="simpleText">+52 55 1198 1542</div>
                      <div className="containerIcons">
                        <LinkedIn
                          onClick={() => {
                            openSocial(
                              "https://www.linkedin.com/company/nudosplatform"
                            );
                          }}
                        />
                        <div className="linkedin">
                          <Instagram
                            onClick={() => {
                              openSocial(
                                "https://instagram.com/nudoslatam?igshid=YmMyMTA2M2Y="
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="centerItems">
                    <div className="simpleText" style={{ fontSize: "14px" }}>
                      <Trans
                        i18nKey={"footer:subscriptionInfo"}
                        components={{ 1: <br /> }}
                      />
                    </div>
                    <div className="containerEmail">
                      <div className="containerInput">
                        <input
                          value={email}
                          className="emailInput"
                          onChange={(e) => {
                            handleEmail(e.target.value);
                          }}
                          placeholder="Email"
                        />
                        <div
                          className="emailIcon"
                          onClick={() => {
                            if (!loading) {
                              sendEmail();
                            }
                          }}
                        >
                          <ArrowIcon
                            stroke={
                              email && validateEmail(email) ? "#F27058" : ""
                            }
                            className="positionIcon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomFooter;
