import Banner from "./components/Banner";
import { v4 as uuid } from "uuid";
import Card from "./components/Card";
import CenterSections from "../../../components/centerSections";
import { listProducts } from "./components/ListProducts";
import "./EquipmentSecond.scss";
import { useTranslation } from "react-i18next";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";
import { CurvyArrowUnderlineOrange } from "../../../assets/images/home/general";

const EquipmentSecond = () => {
  const { t } = useTranslation();
  const myLanguage = getDefaultLanguage();
  const generatCards = (position: number) => {
    return listProducts[position].products?.map((item) => {
      return <Card key={uuid()} item={item} />;
    });
  };
  return (
    <>
      <div className="equipmentSecond">
        <h1
          className={`componentTitle ${
            myLanguage === "en-US" ? "extraTitle" : ""
          }`}
        >
          {t("equipament:secondSection:blackTitle")}
          <strong>
            {t("equipament:secondSection:orangeTitle")}
            <img
              className={`curvyArrowUnderline ${
                myLanguage === "en-US" ? "mySpace" : ""
              }`}
              src={CurvyArrowUnderlineOrange}
              alt="Subrayado flecha curva"
            />
          </strong>
        </h1>
        <Banner item={listProducts[0]} />
        <CenterSections>
          <div className="centerCards">
            <div className="containerCards">{generatCards(0)}</div>
          </div>
        </CenterSections>
        <Banner item={listProducts[1]} />
        <CenterSections>
          <div className="centerCards">
            <div className="containerCards">{generatCards(1)}</div>
          </div>
        </CenterSections>
        <Banner item={listProducts[2]} />
        <CenterSections>
          <div className="centerCards">
            <div className="containerCards">{generatCards(2)}</div>
          </div>
        </CenterSections>
      </div>
    </>
  );
};
export default EquipmentSecond;
