import lenovoLogo from "../../../../assets/images/equipament/EquipmentSecond/lenovoLogo.webp";
import firstBanner from "../../../../assets/images/equipament/EquipmentSecond/firstBanner.webp";
import lenovo1 from "../../../../assets/images/equipament/EquipmentSecond/products/lenovo1.png";
import lenovo2 from "../../../../assets/images/equipament/EquipmentSecond/products/lenovo2.png";
import lenovo3 from "../../../../assets/images/equipament/EquipmentSecond/products/lenovo3.png";
import lenovo4 from "../../../../assets/images/equipament/EquipmentSecond/products/lenovo4.png";
import lenovo5 from "../../../../assets/images/equipament/EquipmentSecond/products/lenovo5.png";
import appleLogo from "../../../../assets/images/equipament/EquipmentSecond/appleLogo.webp";
import secondBanner from "../../../../assets/images/equipament/EquipmentSecond/secondBanner.webp";
import mac1 from "../../../../assets/images/equipament/EquipmentSecond/products/mac1.png";
import mac2 from "../../../../assets/images/equipament/EquipmentSecond/products/mac2.png";
import mac3 from "../../../../assets/images/equipament/EquipmentSecond/products/mac3.png";
import mac4 from "../../../../assets/images/equipament/EquipmentSecond/products/mac4.png";
import mac5 from "../../../../assets/images/equipament/EquipmentSecond/products/mac5.png";
import thirdBanner from "../../../../assets/images/equipament/EquipmentSecond/thirdBanner.webp";
import furniture1 from "../../../../assets/images/equipament/EquipmentSecond/products/furniture1.png";
import furniture2 from "../../../../assets/images/equipament/EquipmentSecond/products/furniture2.png";
import furniture3 from "../../../../assets/images/equipament/EquipmentSecond/products/furniture3.png";
import furniture4 from "../../../../assets/images/equipament/EquipmentSecond/products/furniture4.png";
import furniture5 from "../../../../assets/images/equipament/EquipmentSecond/products/furniture5.png";
import bannerMobile1 from "../../../../assets/images/equipament/EquipmentSecond/bannerMobile1.webp";
import bannerMobile2 from "../../../../assets/images/equipament/EquipmentSecond/bannerMobile2.webp";
import bannerMobile3 from "../../../../assets/images/equipament/EquipmentSecond/bannerMobile3.webp";
import steeleLogo from "../../../../assets/images/equipament/EquipmentSecond/steeleLogo.svg";

export const listProducts = [
  {
    info: {
      logo: appleLogo,
      background: secondBanner,
      color: "appleColor",
      max: "57px",
      backgroundMobile: bannerMobile2,
    },
    products: [
      {
        name: "MacBook Air",
        desc: "Para ultra nómadas",
        descEn: "For ultra nomads",
        sale: "$1,120 usd",
        img: mac1,
        details: {
          Procesador: ["Chip M2"],
          Almacenamiento: ["256 GB SSD", "512 GB SSD"],
          Memoria: ["8 GB RAM"],
          Pantalla: ["13.6’’"],
        },
        descriptionLine: "Para ultra nómadas",
        descriptionLineEn: "For ultra nomads",
        colors: {
          "Azul medianoche": "#32455C",
          "Gris espacial": "#7D7E80",
          "Blanco estelar": "#FDEFDC",
          Plata: "#E3E4E5",
        },
        colorsEn: {
          "Midnight blue": "#32455C",
          "Space grey": "#7D7E80",
          Starlight: "#FDEFDC",
          Silver: "#E3E4E5",
        },
        brandName: "apple",
        brandLogo: appleLogo,
      },
      {
        name: "MacBook Pro",
        desc: "La preferida por profesionales",
        descEn: "The preferred by professionals",
        sale: "$1,400 usd",
        img: mac2,
        details: {
          Procesador: ["Chip M1", "Chip M1 Pro", "Chip M1 Max"],
          Almacenamiento: ["256 GB SSD", "512 GB SSD", "1 TB SSD"],
          Memoria: ["8 GB RAM", "16 GB RAM"],
          Pantalla: ["13’’", "14’’", "16’’"],
        },
        descriptionLine: "La preferida por profesionales",
        descriptionLineEn: "The preferred by professionals",
        colors: { "Gris espacial": "#7D7E80", Plata: "#E3E4E5" },
        colorsEn: { "Space grey": "#7D7E80", Silver: "#E3E4E5" },
        brandName: "apple",
        brandLogo: appleLogo,
      },
      {
        name: "iMac",
        desc: "Lo mejor en ordenadores de escritorio",
        descEn: "The best in computers desktop",
        sale: "$1,450 usd",
        img: mac3,
        details: {
          Procesador: ["Chip M1"],
          Almacenamiento: ["256 GB SSD", "512 GB SSD"],
          Memoria: ["8 GB RAM"],
          Pantalla: ["24’’"],
        },
        descriptionLine: "Lo mejor en ordenadores de escritorio",
        descriptionLineEn: "The best in computers desktop",
        colors: {
          "Gris espacial": "#7D7E80",
          Plata: "#E3E4E5",
          Amarillo: "#FFEE95",
          Morado: "#EBDDF1",
          Azul: "#C0E0EB",
          Naranja: "#FFDFAF",
          Rosado: "#F8DEDE",
        },
        colorsEn: {
          "Midnight blue": "#32455C",
          "Space grey": "#7D7E80",
          White: "#FFFFFF",
          Silver: "##E3E4E5",
        },
        brandName: "apple",
        brandLogo: appleLogo,
      },
      {
        name: "iPad",
        desc: "Potente como un ordenador",
        descEn: "Powerful as a computer",
        sale: "$388 usd",
        img: mac4,
        details: {
          Modelo: ["Pro"],
          Almacenamiento: ["8 GB", "64 GB", "128 GB", "256 GB"],
          Conectividad: ["WiFi", "WiFi + celular"],
          Pantalla: ["13’’"],
        },
        descriptionLine: "Potente como un ordenador",
        descriptionLineEn: "Powerful as a computer",
        colors: { "Gris espacial": "#7D7E80", Plata: "#E3E4E5" },
        colorsEn: {
          "Midnight Blue": "#32455C",
          "Space grey": "#7D7E80",
          White: "#FDEFDC",
          Silver: "#E3E4E5",
        },
        brandName: "apple",
        brandLogo: appleLogo,
      },
      {
        name: "Mac mini",
        desc: "Cada vez más grande por dentro",
        descEn: "Even bigger inside",
        sale: "$388 usd",
        img: mac5,
        details: {
          Procesador: ["Chip M1"],
          Almacenamiento: ["256 GB SSD", "512 GB SSD"],
          Memoria: ["8 GB RAM"],
        },
        descriptionLine: "Cada vez más grande por dentro",
        descriptionLineEn: "Even bigger inside",
        colors: { "Gris espacial": "#7D7E80", Plata: "#E3E4E5" },
        colorsEn: {
          "Midnight Blue": "#32455C",
          "Space grey": "#7D7E80",
          White: "#FDEFDC",
          Silver: "#E3E4E5",
        },
        brandName: "apple",
        brandLogo: appleLogo,
      },
    ],
  },
  {
    info: {
      logo: lenovoLogo,
      background: firstBanner,
      color: "#1F1F1F",
      max: "134px",
      backgroundMobile: bannerMobile1,
    },
    products: [
      {
        name: "ThinkPad Serie E",
        desc: "Mayor rendimiento para Pymes",
        descEn: "Higher performance for SMEs",
        sale: "$420 usd",
        img: lenovo1,
        details: {
          Modelo: ["E 14", "E 15"],
          Procesador: ["AMD", "Intel"],
          Memoria: ["8 GB RAM", "16 GB RAM"],
          Almacenamiento: ["256 GB SSD", "512 GB SSD", "1 TB SSD"],
        },
        descriptionLine: "Mayor rendimiento para Pymes ",
        descriptionLineEn: "Higher performance for SMEs",
        colors: { Negro: "#4F4F4F" },
        colorsEn: { Black: "#4F4F4F" },
        brandName: "lenovo",
        brandLogo: lenovoLogo,
      },
      {
        name: "ThinkPad Serie L",
        desc: "Accesibilidad y productividad",
        descEn: "Accessibility & productivity",
        sale: "$580 usd",
        img: lenovo2,
        details: {
          Modelo: ["L 14", "L 15"],
          Procesador: ["AMD", "Intel"],
          Memoria: ["8 GB RAM", "16 GB RAM"],
          Almacenamiento: ["256 GB SSD", "512 GB SSD", "1 TB SSD"],
        },
        descriptionLine: "Accesibilidad y productividad",
        descriptionLineEn: "Accessibility & productivity",
        colors: { Negro: "#4F4F4F" },
        colorsEn: { Black: "#4F4F4F" },
        brandName: "lenovo",
        brandLogo: lenovoLogo,
      },
      {
        name: "ThinkPad Serie T",
        desc: "Autonomía y ligereza",
        descEn: "Autonomy  & light",
        sale: "$920 usd",
        img: lenovo3,
        details: {
          Modelo: ["T 14", "T 14s"],
          Procesador: ["AMD", "Intel"],
          Memoria: ["8 GB RAM", "16 GB RAM"],
          Almacenamiento: ["256 GB SSD", "512 GB SSD", "1 TB SSD"],
        },
        descriptionLine: "Autonomía y ligereza",
        descriptionLineEn: "Autonomy  & light",
        colors: { Negro: "#4F4F4F" },
        colorsEn: { Black: "#4F4F4F" },
        brandName: "lenovo",
        brandLogo: lenovoLogo,
      },
      {
        name: "ThinkPad Serie X1",
        desc: "Ultrapremium y ultraliviana",
        descEn: "Ultrapremium & ultralight",
        sale: "$1,600 usd",
        img: lenovo4,
        details: {
          Modelo: ["Carbón", "Yoga"],
          Procesador: ["AMD", "Intel"],
          Memoria: ["8 GB RAM", "16 GB RAM"],
          Almacenamiento: ["256 GB SSD", "512 GB SSD", "1 TB SSD"],
        },
        descriptionLine: "Ultrapremium y ultraliviana",
        descriptionLineEn: "Ultrapremium & ultralight",
        colors: { Negro: "#4F4F4F" },
        colorsEn: { Black: "#4F4F4F" },
        brandName: "lenovo",
        brandLogo: lenovoLogo,
      },
      {
        name: "ThinkPad Serie P",
        desc: "La más potente del mercado",
        descEn: "The most powerful of the martket ",
        sale: "$1,550 usd",
        img: lenovo5,
        details: {
          Modelo: ["P 15", "P 14s"],
          Procesador: ["AMD", "Intel"],
          Memoria: ["8 GB RAM", "16 GB RAM"],
          Almacenamiento: ["256 GB SSD", "512 GB SSD", "1 TB SSD"],
        },
        descriptionLine: "La más potente del mercado",
        descriptionLineEn: "The most powerful of the martket ",
        colors: { Negro: "#4F4F4F" },
        colorsEn: { Black: "#4F4F4F" },
        brandName: "lenovo",
        brandLogo: lenovoLogo,
      },
    ],
  },
  {
    info: {
      background: thirdBanner,
      color: "appleColor",
      max: "57px",
      title: "equipament:secondSection:furniture:title",
      backgroundMobile: bannerMobile3,
    },
    products: [
      {
        name: "Silla Ejecutiva ",
        nameEn: "Executive Chair",
        desc: "Combinación perfecta entre ergonomía y diseño",
        descEn: "Best combination between ergonomy & design",
        sale: "$525 usd",
        img: furniture1,
        details: {
          Ancho: ["48 cm"],
          Altura: ["109 - 124.5 cm"],
          Material: ["Malla"],
        },
        detailsEn: {
          Ancho: ["48 cm"],
          Altura: ["109 - 124.5 cm"],
          Material: ["Shade"],
        },
        descriptionLine: "Combinación perfecta entre ergonomía y diseño",
        descriptionLineEn: "Best combination between ergonomy & design",
        colors: { Blanco: "#F3F4F5" },
        colorsEn: { White: "#F3F4F5" },
        brandName: "steele",
        brandLogo: steeleLogo,
      },
      {
        name: "Silla Operativa",
        nameEn: "Operative Chair",
        desc: "Durabilidad y calidad para el bienestar operativo",
        descEn: "Durability and quality for the operative wellness",
        sale: "$250 usd",
        img: furniture2,
        details: { Ancho: ["50 cm"], Altura: ["96 cm"], Material: ["Malla"] },
        detailsEn: { Ancho: ["50 cm"], Altura: ["96 cm"], Material: ["Shade"] },
        descriptionLine: "Combinación perfecta entre ergonomía y diseño",
        descriptionLineEn: "Durability and quality for the operative wellness",
        colors: { Negro: "#242425" },
        colorsEn: { Black: "#242425" },
        brandName: "steele",
        brandLogo: steeleLogo,
      },
      {
        name: "Mesa Standard",
        nameEn: "Standard Table",
        desc: "Para todos los usos",
        descEn: "All uses",
        sale: "$200 usd",
        img: furniture3,
        details: {
          Ancho: ["120 cm"],
          Altura: ["70 - 115 cm"],
          Material: ["Laminado de plástico de alta resistencia"],
        },
        detailsEn: {
          Ancho: ["120 cm"],
          Altura: ["70 - 115 cm"],
          Material: ["Plastic and metal"],
        },
        descriptionLine: "Combinación perfecta entre ergonomía y diseño",
        descriptionLineEn: "All uses",
        colors: { Blanco: "#F3F4F5", Negro: "#242425" },
        colorsEn: { White: "#F3F4F5", Black: "#242425" },
        brandName: "steele",
        brandLogo: steeleLogo,
      },
      {
        name: "Mesa Elevato Manual",
        nameEn: "Manual Lift Desk",
        desc: "Brinda dinamismo y movilidad en el día a día del usuario",
        descEn: "Provides dynamism and mobility to the user",
        sale: "$400 usd",
        img: furniture4,
        details: {
          Ancho: ["120 cm"],
          Altura: ["70 - 115 cm"],
          Material: ["Laminado de plástico de alta resistencia"],
        },
        detailsEn: {
          Ancho: ["120 cm"],
          Altura: ["70 - 115 cm"],
          Material: ["Plastic and metal"],
        },
        descriptionLine: "Combinación perfecta entre ergonomía y diseño",
        descriptionLineEn: "Provides dynamism and mobility to the user",
        colors: { Blanco: "#F3F4F5", Negro: "#242425" },
        colorsEn: { White: "#F3F4F5", Black: "#242425" },
        brandName: "steele",
        brandLogo: steeleLogo,
      },
      {
        name: "Mesa Plegable",
        nameEn: "Adjustble table",
        desc: "Aprovecha al máximo tu espacio",
        descEn: "Optimize your space",
        sale: "$250 usd",
        img: furniture5,
        details: {
          Ancho: ["120 cm"],
          Altura: ["100 cm"],
          Material: ["Laminado de plástico"],
        },
        detailsEn: {
          Ancho: ["120 cm"],
          Altura: ["100 cm"],
          Material: ["Plastic and metal"],
        },
        descriptionLine: "Combinación perfecta entre ergonomía y diseño",
        descriptionLineEn: "Optimize your space",
        colors: { Blanco: "#F3F4F5" },
        brandName: "steele",
        brandLogo: steeleLogo,
      },
    ],
  },
];
