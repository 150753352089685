import { useEffect } from "react";
import { FloatingPromotionButton } from "../../components";
import CenterSections from "../../components/centerSections";
import { segmentEvent } from "../../utils/segment";
import First from "./first";

const QuestionsLanding = () => {
  useEffect(() => {
    segmentEvent({ event: "FAQs_View" });
    window.scrollTo(0, 0);
  }, []);
  return (
    <CenterSections>
      <div id="homeLanding">
        <First />
      </div>
      <FloatingPromotionButton />
    </CenterSections>
  );
};

export default QuestionsLanding;
