import { FC } from "react";
import { useTranslation } from "react-i18next";
import CartIcon from "../../../../assets/images/equipament/EquipmentSecond/cartIcon";
import ProductInternalCard, {
  IproductDetails,
} from "../../../../components/productInternalCard/ProductInternalCard";
import usePlatform from "../../../../hooks/usePlatform";
import useGlobalState from "../../../../state/global.state";
import { getDefaultLanguage } from "../../../../utils/getDefaultLanguage";
import { segmentEvent } from "../../../../utils/segment";
import { toEcommerce } from "../../../../utils/toEcommerce";

export interface IproductInformation {
  name: string;
  nameEn?: string;
  desc: string;
  descEn?: string;
  descriptionLineEn?: string;
  colorsEn?: Icolors;
  sale: string;
  img: string;
  details: IproductDetails;
  detailsEn?: IproductDetails;
  descriptionLine: string;
  colors: Icolors;
  brandName: string;
  brandLogo: string;
}

interface Icolors {
  [key: string]: string | undefined;
}
interface IItem {
  item: IproductInformation;
}

const Card: FC<IItem> = ({ item }: IItem) => {
  const { t } = useTranslation();
  const { setShowModal, setModalOptions } = useGlobalState();
  const { isMobile } = usePlatform();
  const myLanguage = getDefaultLanguage();
  return (
    <div className="cardProduct">
      <div
        className="headerCard"
        onClick={() => {
          setModalOptions({
            modalContent: (
              <ProductInternalCard item={item} setIsModalOpen={setShowModal} />
            ),
            isModalOpen: !isMobile,
            setIsModalOpen: setShowModal,
            closesOnClickOutside: true,
          });
          setShowModal(true);
        }}
      >
        <div className="imgProduct">
          <img className="imgProps" alt="img-Product" src={item?.img}></img>
        </div>
        <div className="bodyText">
          <div className="titleProduct">
            {myLanguage === "en-US" && item?.nameEn ? item?.nameEn : item?.name}
          </div>
          <div className="subtitleProduct">
            {myLanguage === "en-US" ? item?.descEn || item?.desc : item?.desc}
          </div>
        </div>
      </div>
      <div className="footerCard">
        <div className="minText">
          {t("equipament:secondSection:card:range")}
        </div>
        <div>
          <button
            className="whiteButton"
            onClick={() => {
              segmentEvent({
                event: "Landing_Equipment_Product_Click",
                data: { ProductName: item?.name },
              });
              toEcommerce();
            }}
          >
            {item?.sale}{" "}
            <div className="containerIcon">
              <CartIcon className="styleIconsButtons" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Card;
