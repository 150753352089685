import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CurvyArrowUnderline } from "../../../assets/images/home/HomeFifth";
import {
  ConnectingTopArrow1,
  ConnectingTopArrow2,
  ConnectingTopArrow3,
  ConnectingTopArrow4,
  ConnectingTopArrow5,
  ConnectingUnderneathArrow1,
  ConnectingUnderneathArrow2,
  ConnectingUnderneathArrow3,
  ConnectingUnderneathArrow4,
  ConnectingUnderneathArrow5,
  CursorClicking,
  EmployeeExample,
  ImacIcon,
  curvyUnderlinedArrowHome6Mobile,
} from "../../../assets/images/home/HomeSixth";
import CenterSections from "../../../components/centerSections";
import usePlatform from "../../../hooks/usePlatform";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";
import "./HomeSixth.scss";

const HomeSixth = () => {
  const appLanguage = getDefaultLanguage();
  const { isMobile, isLessOrEqualThanCustomSize } = usePlatform();
  const [currentArrowStep, setCurrentArrowStep] = useState(0);
  const { t } = useTranslation();

  const underlineArrow = isLessOrEqualThanCustomSize(1000)
    ? curvyUnderlinedArrowHome6Mobile
    : CurvyArrowUnderline;

  const connectingUnderneathArrows = [
    ConnectingUnderneathArrow1,
    ConnectingUnderneathArrow2,
    ConnectingUnderneathArrow3,
    ConnectingUnderneathArrow4,
    ConnectingUnderneathArrow5,
  ];
  const connectingTopArrows = [
    ConnectingTopArrow1,
    ConnectingTopArrow2,
    ConnectingTopArrow3,
    ConnectingTopArrow4,
    ConnectingTopArrow5,
  ];

  const nodiSteps = [
    {
      boxText: (
        <div className="boxText">
          <Trans
            i18nKey={"home:sixthSection:items:first:title"}
            components={{ 1: <strong /> }}
          />
        </div>
      ),
      absoluteIcon: CursorClicking,
      altText: "Cursor",
      outsideText: t("home:sixthSection:items:first:outsideText"),
      connectingArrow: connectingUnderneathArrows[currentArrowStep],
    },
    {
      boxText: (
        <div className="boxText">
          <Trans
            i18nKey={"home:sixthSection:items:second:title"}
            components={{ 1: <strong /> }}
          />
        </div>
      ),
      absoluteIcon: undefined,
      altText: "",
      outsideText: t("home:sixthSection:items:second:outsideText"),
      connectingArrow: connectingTopArrows[currentArrowStep],
    },
    {
      boxText: (
        <div className="boxText">
          <Trans
            i18nKey={"home:sixthSection:items:third:title"}
            components={{ 1: <strong /> }}
          />
        </div>
      ),
      absoluteIcon: EmployeeExample,
      altText: "Imagen empleado",
      outsideText: t("home:sixthSection:items:third:outsideText"),
      connectingArrow: connectingUnderneathArrows[currentArrowStep],
    },
    {
      boxText: (
        <div className="boxText">
          <Trans
            i18nKey={"home:sixthSection:items:fourth:title"}
            components={{ 1: <strong /> }}
          />
        </div>
      ),
      absoluteIcon: undefined,
      altText: "",
      outsideText: t("home:sixthSection:items:fourth:outsideText"),
      connectingArrow: connectingTopArrows[currentArrowStep],
    },
    {
      boxText: (
        <div className="boxText">
          <Trans
            i18nKey={"home:sixthSection:items:fifth:title"}
            components={{ 1: <strong /> }}
          />
        </div>
      ),
      absoluteIcon: ImacIcon,
      altText: "Imagen computador",
      outsideText: `${t("home:sixthSection:items:fifth:outsideText")}`,
      connectingArrow: undefined,
    },
  ];

  const changeArrowStep = () => {
    if (currentArrowStep === 0) setCurrentArrowStep(1);
    if (currentArrowStep === 1) setCurrentArrowStep(2);
    if (currentArrowStep === 2) setCurrentArrowStep(3);
    if (currentArrowStep === 3) setCurrentArrowStep(4);
    if (currentArrowStep === 4) setCurrentArrowStep(0);
  };

  const englishStyles = appLanguage === "en-US" ? "english" : "";

  useEffect(() => {
    const changeArrowStepInterval = setInterval(changeArrowStep, 800);
    return () => clearInterval(changeArrowStepInterval);
  });

  return (
    <CenterSections customClass="homeSixthCenterSections">
      <div className="homeSixth">
        <h1 className="title">
          {t("home:sixthSection:startTitle")}
          <span className="whiteText">
            {t("home:sixthSection:endTitle")}
            <img
              className="curvyArrowUnderline"
              src={underlineArrow}
              alt="Subrayado flecha curva"
            />
          </span>
        </h1>
        <div className="stepsContainer">
          {nodiSteps.map((step, i) => {
            return (
              <div key={`nodiStep-${i}`} className="stepBox">
                <div className="textWrapper">
                  <div className="number">{i + 1}.</div>
                  {step.boxText}
                </div>
                {step?.absoluteIcon && (
                  <img
                    src={step.absoluteIcon}
                    alt={step.altText}
                    className={`absoluteIcon absoluteIcon${i + 1}`}
                  />
                )}
                <div
                  className={`outsideText outsideText${i + 1} ${englishStyles}`}
                >
                  {step.outsideText}
                </div>
                {step?.connectingArrow && !isMobile && (
                  <img
                    src={step.connectingArrow}
                    alt="Flecha conectando pasos"
                    className={`connectingArrow connectingArrow${i + 1}`}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="quote">
          <em>{t("home:sixthSection:endText")}</em>
        </div>
      </div>
    </CenterSections>
  );
};

export default HomeSixth;
