import { useEffect, useState } from "react";
import { ICountryDetail, IExtraInfo } from "../../../types/global.types";
import NudosSelectDropdown from "../NudosSelectDropdown/NudosSelectDropdown";
import NudosTextInput from "../NudosTextInput/NudosTextInput";
import "./NudosPhoneInput.scss";

/**
 * A react functional component corresponding to the nudos phone input
 * @property {string} placeholder - Required
 * @property {string} label - Optional, a label to display on top of the phone input
 * @property {string} errorText - Optional, the text to be shown if theres an error with the input form. If an error is passed the style of the component will change to reflect that.
 * @property {boolean} isDisabled - Optional, if a value is provided the input would not allowed new searches. Styles would reflect its status as disabled.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "small", "medium" or "large". If no value is passed the default is small.
 * @property {function(phoneNumber: string): void} handlePhoneChange - Optional, a callback to execute upon changing the phoneNumber
 * @property {Tcountry} defaultCountryCode - Optional, for cases when there is a default value, corresponds to the two letter code for the country as identified by the phone code
 * @property {ICountryDetail} defaultCountryData - Optional, an object with the data of the default country
 * @property {string} defaultPhoneNumber - Optional, for cases when there is a default value. The phone number withount the country code
 * @property {funcion(): void} handleBlur - Optional, a callback to pass to the onBlur event for the input component (the text input, not the select one)
 * @property {string} phoneCountryPlaceholder - Optional, a string to show when no phone country has been selected
 */

const NudosPhoneInput = ({
  placeholder,
  label,
  errorText,
  isDisabled,
  componentSize,
  handlePhoneChange,
  defaultCountryCode,
  defaultCountryData,
  defaultPhoneNumber,
  handleBlur,
  personalClass,
  externalCountry,
  externalPhone,
  countriesList,
  handleCountryPhoneChange,
  phoneCountryPlaceholder,
}: InudosPhoneInput) => {
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const [inputPhoneNumber, setInputPhoneNumber] = useState<
    string | undefined
  >();
  const filledStyles =
    (selectedCountry && inputPhoneNumber) ||
    (defaultCountryCode && defaultPhoneNumber) ||
    (defaultCountryCode && inputPhoneNumber)
      ? "filled"
      : "";
  const errorStyles = errorText && errorText.length > 0 ? "error" : "";
  const disabledStyles = isDisabled ? "disabled" : "";
  const componentSizeStyles = componentSize ? componentSize : "";

  const handleCountrySelectOption = (country: ICountryDetail) => {
    setSelectedCountry(country);
    if (externalCountry) {
      if (country) {
        const selectCountryData = {
          countryCode: country.code,
          phoneCode: country.phoneCode,
        };
        externalCountry(selectCountryData);
      }
    }
  };

  const formOptionsServices = () => {
    if (countriesList) {
      return countriesList.map((item) => {
        return (
          <div
            key={`countryOption${item.id}`}
            className="optionContainer"
            onClick={() => {
              handleCountrySelectOption(item);
            }}
          >
            <div
              className="flag"
              style={{ backgroundImage: `url(${item.flag})` }}
            />
            <div className="countryCode">{`+${item.phoneCode}`}</div>
          </div>
        );
      });
    }
    return [];
  };

  const formPlaceholder = (countryData?: ICountryDetail) => {
    if (!countryData) return "";
    return (
      <div className="countryPlaceholderOrCurrentValue">
        <div
          className="flag"
          style={{ backgroundImage: `url(${countryData.flag})` }}
        >
          {!(defaultCountryCode || selectedCountry) && (
            <div className="flagFilter" />
          )}
        </div>
        <div className="countryCode">{`+${countryData.phoneCode}`}</div>
      </div>
    );
  };

  const defaultValue =
    defaultCountryCode && countriesList
      ? formPlaceholder(defaultCountryData)
      : undefined;

  const handleChangeWrittenPhone = (newPhone: string) => {
    setInputPhoneNumber(newPhone);
    if (externalPhone) {
      externalPhone(newPhone);
    }
  };

  useEffect(() => {
    if (!handlePhoneChange || !handleCountryPhoneChange) return;
    handlePhoneChange(inputPhoneNumber || "");
    handleCountryPhoneChange(selectedCountry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, inputPhoneNumber, countriesList]);

  useEffect(() => {
    setInputPhoneNumber(defaultPhoneNumber);
  }, [defaultPhoneNumber]);

  useEffect(() => {
    if (!defaultCountryData) return;
    setSelectedCountry(defaultCountryData);
  }, [defaultCountryData]);

  return (
    <div
      className={`nudosPhoneInput ${errorStyles} ${disabledStyles} ${componentSizeStyles} ${filledStyles}`}
    >
      {label && <label>{label}</label>}
      <div className="phoneInput">
        <NudosSelectDropdown
          selectOptions={countriesList ? formOptionsServices() : []}
          currentValueOrplaceholder={
            selectedCountry
              ? formPlaceholder(selectedCountry)
              : phoneCountryPlaceholder || defaultValue || ""
          }
          isFilled={!!selectedCountry || !!defaultCountryCode}
          errorText={errorText}
          hideErrorText={true}
          isDeactivated={isDisabled}
        />
        <NudosTextInput
          placeholder={placeholder}
          isFilled={!!inputPhoneNumber}
          errorText={errorText}
          hideErrorText={true}
          isDeactivated={isDisabled}
          handleChange={handleChangeWrittenPhone}
          defaultValue={inputPhoneNumber}
          handleBlur={handleBlur}
          customClassName={personalClass || ""}
        />
      </div>
      {errorText && <div className="errorText">{errorText}</div>}
    </div>
  );
};

export default NudosPhoneInput;

export interface InudosPhoneInput {
  placeholder: string;
  label?: string;
  errorText?: string;
  isDisabled?: boolean;
  componentSize?: string;
  handlePhoneChange: (phoneNumber: string) => void;
  handleCountryPhoneChange: (phoneCountryData?: ICountryDetail) => void;
  defaultCountryCode?: string;
  defaultCountryData?: ICountryDetail;
  defaultPhoneNumber?: string;
  handleBlur?: () => void;
  personalClass?: string;
  externalCountry?: (countryInfo: IExtraInfo) => void;
  externalPhone?: (phone: string) => void;
  countriesList?: ICountryDetail[];
  phoneCountryPlaceholder?: string;
}

export interface IcountriesInfo {
  displayName?: string;
  currency?: string;
  flag: string;
  phoneCode: string;
  taxPercentage?: number | string;
}
