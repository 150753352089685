import usePlatform from "../../hooks/usePlatform";
import {
  desktopModalImage,
  desktopModalImageEnglish,
  mobileModalImage,
} from "../../assets/images/components/floatingPromotionButton";
import "./PromotionModal.scss";
import { NudosPhoneInput, NudosTextInput } from "../NudosComponents";
import NudosButton from "../NudosComponents/NudosButton/NudosButton";
import { useEffect, useState } from "react";
import useGlobalState from "../../state/global.state";
import { NudosLogo } from "../../assets/images/blog";
import XSign from "../../assets/images/components/NudosComponents/XSign";
import { isEmailValid, isPhoneValid } from "../../utils/formValidations";
import { segmentEvent } from "../../utils/segment";
import { useTranslation } from "react-i18next";
import { CurvyArrowUnderlineOrange } from "../../assets/images/home/general";
import { getCountriesList, postPromotionsForm } from "../../services/services";
import { ICountryDetail } from "../../types/global.types";
import { getDefaultLanguage } from "../../utils/getDefaultLanguage";
import {
  displayErrorNotification,
  displaySuccessNotification,
} from "../../utils/displayNudosStandardNotifications";

const PromotionModal = () => {
  const { t } = useTranslation();
  const { isMobile } = usePlatform();
  const appLanguage = getDefaultLanguage();
  const englishStyles = appLanguage === "en-US" ? "english" : "";
  const { setModalOptions, setShowModal } = useGlobalState();

  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();
  const [loading, setLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [phoneCountryData, setPhoneCountryData] = useState<ICountryDetail>();

  const backgroundImage = isMobile
    ? mobileModalImage
    : appLanguage === "en-US"
    ? desktopModalImageEnglish
    : desktopModalImage;

  const getCountries = async () => {
    try {
      const countries = await getCountriesList();
      countries && setCountriesList(countries);
    } catch (error) {
      // Intentionally left blank to prevent notifying the user of the error loading the countries hence discouraging users to explore the rest of the landing page. COMMENTARY
    }
  };

  const isButtonDisabled =
    !organizationName ||
    !email ||
    !phone ||
    !(phone.length > 2) ||
    !isEmailValid(email) ||
    !isPhoneValid(phone) ||
    loading ||
    !phoneCountryData;

  const closeModal = () => {
    setModalOptions(undefined);
    setShowModal(false);
  };

  const postData = async () => {
    if (!(organizationName && email && phone && phoneCountryData?.phoneCode))
      return;
    setLoading(true);
    const body = {
      organizationName,
      countryCodePhone: `${phoneCountryData?.phoneCode}`,
      email,
      phone,
    };
    try {
      segmentEvent({
        event: "Landing_Home_Popup_Click",
        data: {
          CompanyName: organizationName,
          ContactMail: email,
          ContactNumber: phone,
        },
      });
      const response = await postPromotionsForm(body);
      if (response) {
        closeModal();
        displaySuccessNotification({
          customJSXMessage: <>{t("promotionModal:finishAndSuccess")}</>,
        });
      }
    } catch (error: any) {
      if (
        error === "The beneficiary already has that promotion" ||
        error?.response === "The beneficiary already has that promotion" ||
        error?.response?.data ===
          "The beneficiary already has that promotion" ||
        error?.response?.data?.message ===
          "The beneficiary already has that promotion"
      ) {
        displayErrorNotification({
          customJSXMessage: (
            <>{`${t("promotionModal:theEmail")} ${
              email || t("promotionModal:register")
            } ${t("promotionModal:otherEmail")}`}</>
          ),
        });
      } else {
        displayErrorNotification();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div className="promotionModal">
      <section
        className="firstSection"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        {isMobile && (
          <>
            <XSign onClick={closeModal} />
            <div className="nudosIcon">
              <NudosLogo />
            </div>
            <div className="discountText">
              <div className="line1">{t("promotionModal:imageText1")} </div>
              <div className={`line2 ${englishStyles}`}>
                {t("promotionModal:imageText2")}
                <img
                  className="curvyArrowUnderline"
                  src={CurvyArrowUnderlineOrange}
                  alt="Subrayado flecha curva"
                />
              </div>
            </div>
          </>
        )}
      </section>
      <section className="secondSection">
        {!isMobile && <XSign onClick={closeModal} />}
        <div className="explanation">{t("promotionModal:subtitle")}</div>
        <div className="form">
          <NudosTextInput
            label={t("promotionModal:form:organizationName:label")}
            placeholder={"Nudos"}
            isFilled={false}
            handleChange={(inputText) => setOrganizationName(inputText)}
            errorText={
              organizationName && organizationName?.length > 200
                ? t("promotionModal:form:organizationName:limits")
                : undefined
            }
          />
          <NudosTextInput
            label={t("promotionModal:form:email:label")}
            placeholder={"nombre@dominio.com"}
            isFilled={false}
            handleChange={(inputText) => setEmail(inputText)}
            errorText={
              email && (!isEmailValid(email) || email?.length > 200)
                ? t("promotionModal:form:email:limits")
                : undefined
            }
          />
          <NudosPhoneInput
            placeholder={"5551234567"}
            label={t("promotionModal:form:phone:label")}
            errorText={
              phone && phone.length > 3 && !isPhoneValid(phone)
                ? t("promotionModal:form:phone:limits")
                : undefined
            }
            countriesList={countriesList}
            handlePhoneChange={setPhone}
            phoneCountryPlaceholder={t("globalButtons:countrySelectText")}
            handleCountryPhoneChange={setPhoneCountryData}
          />
          <div className="buttonContainer">
            <NudosButton
              buttonText={t("promotionModal:form:buttonText")}
              componentSize={isMobile ? "medium" : undefined}
              isButtonDisabled={isButtonDisabled}
              handleClick={() => postData()}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PromotionModal;
