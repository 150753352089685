import { useCallback, useEffect, useRef, useState } from "react";
import { ReturnButton } from "../../../assets/images/components/NudosComponents";
import { IElement } from "../../genericSelect/GenericSelect";
import "./NudosSelectDropdown.scss";

/**
 * React functional component corresponding to Nudos select dropdown input.
 * @property {JSX.Element[]} selectOptions - Required, the options to be displayed in the dropdown select. Each option should have the changeSelectedOption as onClick callback.
 * IMPORTANT: each of the options should have the className "optionContainer" to inherit the default styles for the options.
 * @property {string | JSX.Element} currentValueOrplaceholder - Required, the current value or placeholder of the select input when the dropdown is closed, usually the selected option or the selected option string value, or a placeholder if no option has been selected.
 * *@property {boolean} isFilled - Required, boolean indicating if an option has been selected and thus the select input is filled. Should be passed to change the style from empty to filled. Its value should reflect the change in the input value. Default is false.
 * @property {string} label - Optional, a label for the select input.
 * @property {string} errorText - Optional, the text to be shown if theres an error with the select input form. If an error is passed the style of the component will change to reflect that.
 * @property {boolean} isDeactivated - Optional, boolean indicating if the select input is deactivated. If a value is provided the input will behave as a read only field and styles will be accordingly.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {boolean} closesOnClickOutside - Optional, a boolean indicating if the dropdown closes when clicked outside. If no value is pause the default is true, with the select value being updated to the default.
 * @property {boolean} closesOnChangeSelection - Optional, a boolean indicating if the dropdown closes when the selected option(s) change. If no value is pause the default is true.
 * @property {string} selectClassName - Optional, class name for the select input to be use in customizing styles.
 * @property {string} hideErrorText - Optional, if the dropdown is part of another component that shows an error text, used this boolean to prevent from repeating the error text.
 * @property {any[]} rawOptions - Optional, an array of the actual values stored by the NudosSelectDropdown
 * @property {number} currentOptionIndex - Optional, the index in the selectOptions array of the current option. Pass this value to activate selection with the up/down keys of the keyboard
 * @property {function():void} handleChangeSelectedOption - Optional, the updateState option used for storaginng the selected option
 * @returns
 */
const NudosSelectDropdown = ({
  selectOptions,
  currentValueOrplaceholder,
  isFilled,
  label,
  errorText,
  isDeactivated,
  componentSize,
  closesOnClickOutside = true,
  closesOnChangeSelection = true,
  selectClassName,
  hideErrorText,
  rawOptions,
  currentOptionIndex,
  handleChangeSelectedOption,
  customContainer,
  heightDropDown,
  paddingLeft,
  paddingRight,
}: InudosSelectDropdown) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentSelectionIndex, setCurrentSelectionIndex] = useState(0);
  const refContainer = useRef<HTMLDivElement>(null);
  const handleClickSelect = () => {
    if (isDropdownOpen) {
      !isDeactivated && closesOnChangeSelection && setIsDropdownOpen(false);
    } else {
      !isDeactivated && setIsDropdownOpen(true);
    }
  };
  const handleCloseDropDown = () => !isDeactivated && setIsDropdownOpen(false);

  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        closesOnClickOutside && handleCloseDropDown();
      }
    },
    [refContainer]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelectWithKeyboard = (e: { key: string }) => {
    const keyPressed = e.key;
    if (keyPressed === "Enter") return setIsDropdownOpen(false);
    if (!isDropdownOpen || !rawOptions || !handleChangeSelectedOption) return;
    const isLastElement = currentSelectionIndex === selectOptions.length - 1;
    const isFirstElement = currentSelectionIndex === 0;
    const nextElementIndex = isLastElement ? 0 : currentSelectionIndex + 1;
    const previousElementIndex = isFirstElement
      ? selectOptions.length - 1
      : currentSelectionIndex - 1;
    if (!(keyPressed === "ArrowDown" || keyPressed === "ArrowUp")) return;
    let newIndex: number;
    if (keyPressed === "ArrowDown") {
      setCurrentSelectionIndex(nextElementIndex);
      newIndex = nextElementIndex;
    } else {
      setCurrentSelectionIndex(previousElementIndex);
      newIndex = previousElementIndex;
    }
    const newSelection = rawOptions[newIndex];
    handleChangeSelectedOption(newSelection);
  };

  useEffect(() => {
    window.addEventListener("mousedown", onHandleClickAway);
    window.addEventListener("keydown", handleSelectWithKeyboard);
    return () => {
      window.removeEventListener("mousedown", onHandleClickAway);
      window.removeEventListener("keydown", handleSelectWithKeyboard);
    };
  }, [onHandleClickAway, handleSelectWithKeyboard]);

  useEffect(() => {
    if (
      typeof currentOptionIndex !== "number" ||
      currentOptionIndex < 0 ||
      currentOptionIndex >= selectOptions.length
    )
      return;
    setCurrentSelectionIndex(currentOptionIndex);
  }, [currentOptionIndex, selectOptions.length]);

  return (
    <div
      className={`nudosSelectDropdown ${
        selectClassName ? selectClassName : ""
      } ${isDeactivated ? "deactivated" : ""} ${
        isFilled || isDropdownOpen ? "filled" : ""
      } ${errorText && errorText.length > 0 ? "error" : ""} ${
        componentSize ? componentSize : ""
      }`}
    >
      {label && <div className="selectLabel truncate">{label}</div>}
      <div className="selectContainer" onClick={handleClickSelect}>
        <div
          className="closedSelectInput"
          style={{
            height: heightDropDown ? `${heightDropDown}px` : "",
            paddingLeft: paddingLeft ? `${paddingLeft}px` : "",
            paddingRight: paddingRight ? `${paddingRight}px` : "",
          }}
        >
          <div className="currentValueOrplaceholder">
            {currentValueOrplaceholder}
          </div>
          {!isDropdownOpen && (
            <div className="expandArrow">
              <ReturnButton />
            </div>
          )}
          {isDropdownOpen && (
            <div className="contractArrow">
              <ReturnButton />
            </div>
          )}
        </div>
        {isDropdownOpen && !isDeactivated && (
          <div
            ref={refContainer}
            className={`optionsContainer ${customContainer || ""}`}
          >
            <div className="paddingWrapper">{selectOptions}</div>
          </div>
        )}
      </div>
      {errorText && !hideErrorText && errorText.length > 0 && (
        <div className="errorText">{errorText}</div>
      )}
    </div>
  );
};

export default NudosSelectDropdown;

export interface InudosSelectDropdown {
  selectOptions: JSX.Element[];
  currentValueOrplaceholder: string | JSX.Element;
  isFilled: boolean;
  label?: string;
  errorText?: string;
  isDeactivated?: boolean;
  componentSize?: string;
  closesOnClickOutside?: boolean;
  closesOnChangeSelection?: boolean;
  selectClassName?: string;
  hideErrorText?: boolean;
  rawOptions?: any[];
  currentOptionIndex?: number;
  handleChangeSelectedOption?: (newValue: any) => void;
  customContainer?: string;
  heightDropDown?: string | number;
  paddingLeft?: number | string;
  paddingRight?: number | string;
}
