import axios, { AxiosResponse } from "axios";

const axiosInstance = axios.create();

export async function apiGet<T, U>(url: string, options?: U): Promise<T> {
  const { data } = await axiosInstance.get<T>(url, {
    ...options,
  });
  return data;
}

export async function apiPost<T, U, V>(
  url: string,
  body: U,
  options?: V
): Promise<T> {
  const { data } = await axiosInstance.post<T, AxiosResponse<T>, U>(url, body, {
    ...options,
  });
  return data;
}

export async function apiPut<T, U, V>(
  url: string,
  body: U,
  options?: V
): Promise<T> {
  const { data } = await axiosInstance.put<T, AxiosResponse<T>, U>(url, body, {
    ...options,
  });
  return data;
}
