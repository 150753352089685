import Items from "./Items";
import { v4 as uuid } from "uuid";
import flash from "../../../assets/images/questions/first/flash.webp";
import defaultList from "../first/listQuestions.json";



interface IQuestion {
  ask: string;
  response: string;
}

export type IItems = IQuestion[];

const First = () => {
  const { listQuestions } = defaultList;

  const createItems = (items: IItems) => {
    return items.map((item: IQuestion) => {
      return <Items key={uuid()} ask={item.ask} response={item.response} />;
    });
  };

  const createSectionQuestions = () => {
    return listQuestions.map((item) => {
      return (
        <div key={uuid()} className="containerCards">
          {item.title && <div className="titleAsk">{item.title}</div>}
          {createItems(item.questions)}
        </div>
      );
    });
  };
  return (
    <div id="questionsLanding" className="fullMinSizeScreen">
      <div className="containerIcon centerHorizontalItems">
        <img alt="flashIcon" src={flash} />
      </div>
      <div className="titleQuestions">¿Tienes alguna Duda?</div>
      <div className="subtitleQuestions">
        Bienvenido a nuestra sección de preguntas frecuentes
      </div>
      <div className="centerHorizontalItems">
        <div className="containerQuestions">{createSectionQuestions()}</div>
      </div>
    </div>
  );
};

export default First;
