import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
  DTOpromosAndDemoForms,
  ICountryDetail,
  IcountryListType,
} from "../types/global.types";
import { apiGet, apiPost } from "./api";

const { REACT_APP_ORGANIZATION_API } = process.env;
const hiddenURL = "https://nudos-api-pe-7uy7pjl3ja-uk.a.run.app";
const hiddenURLDev = "https://nudos-api-dev-7uy7pjl3ja-uk.a.run.app";

export interface iBody {
  name: string;
  company: string;
  email: string;
  webpage: string;
  document: string;
  comment: string;
  phone?: string;
}

export const registerEmail = async (email: string) => {
  try {
    const response = await axios.post(
      `${
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? hiddenURLDev
          : hiddenURL
      }/landing/subscribe`,
      {
        email,
      }
    );
    sessionStorage.setItem("existEmail", "true");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCountriesList = async (
  paramsList?: IcountryListType
): Promise<ICountryDetail[]> => {
  return apiGet<ICountryDetail[], AxiosRequestConfig>(
    `${REACT_APP_ORGANIZATION_API}/public/countries${
      paramsList ? `/listing?offeredService=${paramsList}` : ""
    }`
  )
    .then((response) => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const postDemoForm = async (body: DTOpromosAndDemoForms) => {
  return apiPost<any, DTOpromosAndDemoForms, AxiosRequestConfig>(
    `${REACT_APP_ORGANIZATION_API}/public/landing/demo`,
    body
  );
};

export const postPromotionsForm = async (body: DTOpromosAndDemoForms) => {
  return apiPost<any, DTOpromosAndDemoForms, AxiosRequestConfig>(
    `${REACT_APP_ORGANIZATION_API}/public/landing/popUp`,
    body
  );
};
