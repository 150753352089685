import usePlatform from "../../../hooks/usePlatform";
import "./BlogEntrySkeleton.scss";

const BlogEntrySkeleton = () => {
  const { isMobile } = usePlatform();

  return (
    <div className="blogEntrySkeleton">
      <section className="entryHeader">
        {isMobile && <div className="entryImageMobile" />}
        <div className="categoryAndDate">
          <div className="category"></div>
          <div className="date"></div>
        </div>
        {!isMobile && <div className="entryImage" />}
        {!isMobile && (
          <div className="authorAndShare">
            <div className="author">
              <div className="authorPhoto" />
              <div className="authorNameAndRole">
                <div className="authorName" />
                <div className="authorRole" />
              </div>
            </div>
            <div className="shareOnSocialMedia">
              {[0, 1, 2, 3].map((i) => {
                return <div key={`circle-${i}`} className="circle" />;
              })}
            </div>
          </div>
        )}
      </section>
      <section className="entryBody">
        <div className="entryTitle">
          <div className="line" />
          <div className="line" />
        </div>
        <div className="bodyContent">
          <div className="paragraph" />
          <div className="paragraph" />
        </div>
        {isMobile && (
          <>
            <div className="author">
              <div className="authorPhoto" />
              <div className="authorNameAndRole">
                <div className="authorName" />
                <div className="authorRole" />
              </div>
            </div>
            <div className="shareContainer">
              <div className="shareOnSocialMedia">
                {[0, 1, 2, 3].map((i) => {
                  return <div key={`circle-${i}`} className="circle" />;
                })}
              </div>
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export default BlogEntrySkeleton;
