import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HomeFirst from "./HomeFirst";
import { FloatingPromotionButton } from "../../components";
import HomeFifth from "./HomeFifth";
import HomeSixth from "./HomeSixth";
import HomeEight from "./HomeEight";
import { ICountryDetail } from "../../types/global.types";
import { getCountriesList } from "../../services/services";
import HomeSeventh from "./HomeSeventh";
import HomeFourth from "./HomeFourth";
import HomeSecond from "./HomeSecond";
import HomeThird from "./HomeThird";

const HomeLanding = () => {
  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();
  const { hash } = useLocation();
  const shouldRedirectToDemoForm = hash === "#demo-form";

  const getCountries = async () => {
    try {
      const countries = await getCountriesList();
      countries && setCountriesList(countries);
    } catch (error) {
      // Intentionally left blank to prevent notifying the user of the error loading the countries hence discouraging users to explore the rest of the landing page. COMMENTARY
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (!shouldRedirectToDemoForm) return window.scrollTo(0, 0);
    document.getElementById("demo-form")?.scrollIntoView();
  }, [shouldRedirectToDemoForm]);

  return (
    <div id="homeLanding">
      <HomeFirst />
      <HomeSecond />
      <HomeThird />
      <HomeFourth />
      <HomeFifth />
      <HomeSixth />
      <HomeSeventh />
      <HomeEight countriesList={countriesList || []} />
      <FloatingPromotionButton />
    </div>
  );
};

export default HomeLanding;
