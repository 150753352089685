import { useHistory } from "react-router-dom";
import { IblogListingEntryData } from "../../../../types/blog";
import { truncateText } from "../../../../utils/truncateText";
import "./BlogListMainCard.scss";

const BlogListMainCard = ({
  mainEntryListingData,
}: {
  mainEntryListingData: IblogListingEntryData;
}) => {
  const { push } = useHistory();
  const handleClickCard = () =>
    push(`/blog/entry/${mainEntryListingData?.postId || ""}`);
  return (
    <div className="blogListMainCard">
      <section
        className="leftSection"
        style={{ backgroundImage: `url(${mainEntryListingData?.image})` }}
        onClick={handleClickCard}
      />
      <section className="rightSection">
        <h2 className="category">
          {truncateText(mainEntryListingData?.categoryName, 55).toUpperCase() ||
            "NOVEDADES EN NUDOS"}
        </h2>
        <h1 className="postTitle" onClick={handleClickCard}>
          {truncateText(mainEntryListingData?.title, 96)}
        </h1>
        <div className="description">
          {truncateText(mainEntryListingData?.shortDescription, 210)}
        </div>
        <div className="author">
          <div
            className="authorPhoto"
            style={{
              backgroundImage: `url(${mainEntryListingData?.authorPhoto})`,
            }}
          />
          <div className="authorNameAndRole">
            <div className="authorName">{mainEntryListingData?.authorName}</div>
            <div className="authorRole">{mainEntryListingData?.authorRole}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogListMainCard;
