import { useTranslation } from "react-i18next";
import {
  closedDoorIcon,
  openDoorIcon,
} from "../../../assets/images/home/HomeThird";
import CenterSections from "../../../components/centerSections";
import { getDefaultLanguage } from "../../../utils/getDefaultLanguage";
import "./HomeThird.scss";

const HomeThird = () => {
  const { t } = useTranslation();
  const appLanguage = getDefaultLanguage();
  const englishStyles = appLanguage === "en-US" ? "english" : "";
  const informationBoxes = [
    {
      key: "informationBox1",
      title: t("home:thirdSection:informationBox1:title"),
      itemsList: [
        t("home:thirdSection:informationBox1:itemsList:item1"),
        t("home:thirdSection:informationBox1:itemsList:item2"),
        t("home:thirdSection:informationBox1:itemsList:item3"),
      ],
      icon: openDoorIcon,
      altText: t("home:thirdSection:informationBox1:iconAltText"),
    },
    {
      key: "informationBox2",
      title: t("home:thirdSection:informationBox2:title"),
      itemsList: [
        t("home:thirdSection:informationBox2:itemsList:item1"),
        t("home:thirdSection:informationBox2:itemsList:item2"),
        t("home:thirdSection:informationBox2:itemsList:item3"),
      ],
      icon: closedDoorIcon,
      altText: t("home:thirdSection:informationBox2:iconAltText"),
    },
  ];

  return (
    <>
      <CenterSections customClass="homeThirdCenterSections">
        <div className="homeThird">
          <div className={`informationBoxes ${englishStyles}`}>
            {informationBoxes.map((box) => {
              return (
                <div key={box.key} className={`informationBox ${box.key}`}>
                  <img className="boxIcon" src={box.icon} alt={box.altText} />
                  <div className="textContainer">
                    <h2 className="listTitle">{box.title}</h2>
                    <ul className="itemsList">
                      {box.itemsList.map((item, i) => {
                        return (
                          <li key={`item${i}`} className="listItem">
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CenterSections>
    </>
  );
};
export default HomeThird;
