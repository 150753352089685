import { useEffect, useState } from "react";
import { SideContext } from "./hooks/sideContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import OffLanging from "./sections/OffLanding";
import HomeLanding from "./sections/HomeLanding";
import EquipamentLanding from "./sections/equipmentLanding";
import NodiPlatformView from "./sections/NodiPlatformView";
import QuestionsLanding from "./sections/questionsLanding";
import CustomFooter from "./sections/customFooter";
import "react-toastify/dist/ReactToastify.css";
import CustomHeader from "./components/customHeader";
import NudosModal from "./components/NudosModal/NudosModal";
import WarninPage from "./sections/warningPage";
import BlogListing from "./sections/blogModule/BlogListing";
import BlogEntry from "./sections/blogModule/BlogEntry";
import useGlobalState from "./state/global.state";
import { initI18n } from "./i18n";
import { responsiveConfig } from "./utils/activateNotification";
import { Crisp } from "crisp-sdk-web";

const App = () => {
  const { modalOptions, showModal } = useGlobalState();
  const [side, setSide] = useState<"landing" | "store">("store");
  const [loadLanguage, setLoadLanguage] = useState<boolean>(false);
  const landingOn = true;

  const platformLanguage = () => {
    const language = initI18n();
    if (language) {
      setLoadLanguage(true);
    }
  };

  const initializeCrisp = () => {
    if (process.env.REACT_APP_CRISP_KEY) {
      Crisp.configure(process.env.REACT_APP_CRISP_KEY);
    }
  };

  useEffect(() => {
    initializeCrisp();
    platformLanguage();
  }, []);

  return (
    <>
      {loadLanguage && (
        <>
          <SideContext.Provider value={{ side, setSide }}>
            <ReactNotifications {...responsiveConfig} />
            <Router>
              <div className="App">
                {landingOn ? (
                  <>
                    {showModal && modalOptions && modalOptions.modalContent && (
                      <NudosModal {...modalOptions} />
                    )}
                    <CustomHeader />
                    <Switch>
                      <Route exact path={"/home"}>
                        <HomeLanding />
                      </Route>
                      <Route exact path={"/bdev"}>
                        <HomeLanding />
                      </Route>
                      <Route exact path={"/equipament"}>
                        <EquipamentLanding />
                      </Route>
                      <Route exact path={"/nodi-platform"}>
                        <NodiPlatformView />
                      </Route>
                      <Route exact path={"/blog"}>
                        <BlogListing />
                      </Route>
                      <Route exact path={"/blog/entry/:id"}>
                        <BlogEntry />
                      </Route>
                      <Route exact path={"/faqs"}>
                        <QuestionsLanding />
                      </Route>
                      <Route exact path={"/warning-page"}>
                        <WarninPage />
                      </Route>
                      <Redirect from="/*" to="/home" />
                    </Switch>
                    <CustomFooter />
                  </>
                ) : (
                  <OffLanging />
                )}
              </div>
            </Router>
          </SideContext.Provider>
        </>
      )}
    </>
  );
};

export default App;
